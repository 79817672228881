import React, { useState, useEffect }  from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'

import ServiceReportDocument from './partials/ServiceReportDocument'
import InternetRequired from '../Offline/partials/InternetRequired'

import { publicGetReviewLogbooks } from '../ReviewLogbooks/services'
import { publicGetServiceReport } from './services'
import { handleError } from '../../helpers'

export default function ServiceReportPreview(props) {
	const id = props.id ? props.id : props.match.params.id
	const { isConnected } = useSelector(state => state.offline)
	const [serviceReport, setServiceReport] = useState(null)
	const [reviewLogbooks, setReviewLogbooks] = useState(null)

	useEffect(() => {
		!serviceReport && isConnected && publicGetServiceReport(id, {
			include: 'serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company,reportEvidence,serviceReview.servicePlan.serviceInspector.user,serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.employee,serviceReview.tankSpecs,serviceReview.islandSpecs,serviceReview.reviewEquipments,serviceReview.nonCompliantReport,formatVersion',
			append: 'initial_inspection_at,retry_inspection_at,total_ncp_tasks,total_nc_tasks,total_resolved_tasks,initial_inspector_name,format_review_equipments'
		})
			.then(res => setServiceReport(res.data.data))
			.catch(error => handleError(error))
	}, [serviceReport, id, isConnected])

	useEffect(() => {
		!reviewLogbooks && isConnected && publicGetReviewLogbooks({
			'filter[related_to_service_report_id]': id,
			sort: 'order,-created_at'
		})
			.then(res => setReviewLogbooks(res.data.data))
			.catch(error => handleError(error))
	}, [reviewLogbooks, id, isConnected])

	if(!isConnected) return <InternetRequired />

	if(!serviceReport || !reviewLogbooks) return <Spinner />

	return (
		<>
			<Container>
				<ServiceReportDocument 
					serviceReport={serviceReport} 
					reviewLogbooks={reviewLogbooks}
				/>
			</Container>
		</>
	)
}

const Container = styled.div`
	padding: 0px 10px;
`