import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'

import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storeFormatVersion } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function NewFormatVersionForm({ reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const onSubmit = values => {
		storeFormatVersion(values)
			.then(res => handleSuccess(res.data.message, reload, reset))
			.catch(error => handleError(error))
	}

	if(!can('Crear versiones de formato'))
		return <PermissionIsRequired type="warning" title="No tiene permisos para crear formatos" />

	return (
		<>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Group>
					<Form.Label>Versión <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('sku', { required:true })}
						placeholder='Escribe aquí...'
					/>
					{ errors.sku && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group>
					<Form.Label>Formato <span className='text-danger'>*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('keyword')}
					>
						<option value="Solicitud de servicio (inquiry)">Solicitud de servicio</option>
						<option value="Propuesta comercial (proposal)">Propuesta comercial</option>
						<option value="Cuenta de cobro (preInvoice)">Cuenta de cobro</option>
						<option value="Asignación de Inspección (serviceInspector)">Asignación de Inspección</option>
						<option value="Notificación de Inspección (serviceNotification)">Notificación de Inspección</option>
						<option value="Plan de Inspección (servicePlan)">Plan de Inspección</option>
						<option value="Acta de Inspección  (serviceReview)">Acta de Inspección </option>
						<option value="Lista de verificación (verificationList)">Lista de verificación</option>
						<option value="Reporte de No Conformidades (nonCompliantReport)">Reporte de No Conformidades</option>
						<option value="Ratificación imparcialidad (impartialityNotice)">Ratificación imparcialidad</option>
						<option value="Informe de Inspección (serviceReport)">Informe de Inspección</option>
						<option value="Revisión y Decisión (serviceVeredict)">Revisión y Decisión</option>
						<option value="Certificado de Inspección (certificate)">Certificado de Inspección</option>
						<option value="Comunicado de decisión (decisionNotice)">Comunicado de decisión</option>
						<option value="Comunicado de inspección de cierre (retryNotice)">Comunicado de inspección de cierre</option>
						<option value="Bitacora de Inspección de Tanques (reviewLogbook_tank)">Bitacora de Inspección de Tanques</option>
						<option value="Bitacora de Inspección Tuberías (reviewLogbook_pipeline)">Bitacora de Inspección Tuberías</option>
					</Form.Control>
				</Form.Group>
				<Form.Group>
					<Form.Label>Fecha de Vigencia <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('expire_at', { required:true })}
						type="date"
					/>
					{ errors.expire_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group>
					<Form.Label>Unidad de Negocio <span className='text-danger'>*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('business_scope')}
					>
						<option value="inspeccion">Servicios de Inspección</option>
						<option value="pruebas">Pruebas de Tanques y Tuberias</option>
					</Form.Control>
					{ errors.business_scope && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Button type="submit" color="primary">Crear Versión de Formato</Button>
			</Form>
		</>
	)
}



