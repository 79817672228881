import React, { useRef, useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import * as d3 from 'd3'
import { Alert, message } from 'antd'

import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { importVerificationTasks } from '../../VerificationTasks/services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function TasksImportModal({ visible, onClose, serviceReviewId, verificationListId }) {
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState(null)
	const [results, setResults] = useState({})
	const uploadFile = useRef(null)
	
	const handleFiles = () => {
		(async () => {
			const text = await uploadFile.current.files[0].text()
			if(uploadFile.current.files[0].type !== 'text/csv')
				return message.warning('Favor ingresar únicamente archivos .csv')
				
			setData(d3.csvParse(text))
		})()
	}

	const handleSubmit = () => {
		setLoading(true)
		
		importVerificationTasks({ 
			verification_list_id: verificationListId,
			service_review_id: serviceReviewId,
			rows: JSON.stringify(data)
		})
			.then(res => handleSuccess(res.data.message, () => setResults(res.data.data)))
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
	}

	if(!can('Actualizar tareas de verificación'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar listas de verificación" onClose={onClose} />

	return (
		<Modal isOpen={visible}>
			<ModalHeader toggle={onClose}>Importar Tareas Masivamente</ModalHeader>
			<ModalBody>
				<input ref={uploadFile} onChange={handleFiles} type="file" className='block mb-10' />
				<Alert type='warning' description="Alerta: Si la tarea ya habia sido registrada se actualizará" />
				<Button 
					color="primary" 
					onClick={handleSubmit} 
					disabled={!data || loading}
					className="mt-10"
				>
					Importar Tareas{" "}
					{ loading && <Spinner size="sm" className='ml-3' /> }
				</Button>
				<div className='mt-10 p-10 bg-light'>
					<ol>
						{ results.length > 0 && (
							<>
								{ results.map((result, i) => (
									<li key={i}>
										<strong>Fila #{result.row}: </strong>{result.comment}
									</li>
								))}
							</>
						)}
					</ol>
				</div>
			</ModalBody>
		</Modal>
	)
}