import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import ProposalPreInvoicesList from './ProposalPreInvoicesList'

export default function ProposalPreInvoicesModal({ visible, onClose, proposalId, reload }) {
	return (
		<>
			<Modal isOpen={visible} size='xl'>
				<ModalHeader toggle={onClose}>Cuentas de Cobro</ModalHeader>
				<ModalBody>
					<ProposalPreInvoicesList 
						proposalId={proposalId}
						reload={reload}
					/>		
				</ModalBody>
			</Modal>
		</>
	)
}