import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'

export default function DoubleScopesBar({
    filterBusinessScope, 
    setFilterBusinessScope, 
    filterStatusScope, 
    setFilterStatusScope, 
    reload, 
    businessScopes, 
    statusScopes
}) {
    return (
        <>
            <InputGroup className='mt-0 mb-0' style={{ maxWidth: '175px', float: 'right' }}>
                <Form.Control
                    as="select"
                    value={filterStatusScope}
                    onChange={e => {
                        setFilterStatusScope(e.target.value)
                        reload()
                    }}
                >
                    <option value="">:: Todos los estados ::</option>
                    { statusScopes.map(scope => (
                        <option key={scope.value} value={scope.value}>{scope.label}</option>
                    )) }
                </Form.Control>
            </InputGroup>
            <InputGroup className='mt-0 mb-0' style={{ maxWidth: '420px', float: 'right' }}>
                <Form.Control 
                    disabled 
                    value="Tipo de Servicio"
                    style={{ maxWidth:'140px' }} 
                    className="bg-light"
                />
                <Form.Control
                    as="select"
                    value={filterBusinessScope}
                    onChange={e => {
                        setFilterBusinessScope(e.target.value)
                        reload()
                    }}
                >
                    <option value="">:: Ver todos los registros ::</option>
                    { businessScopes.map(scope => (
                        <option key={scope.value} value={scope.value}>{scope.label}</option>
                    )) }
                </Form.Control>
            </InputGroup>        
        </>
    )
}
