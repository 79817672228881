import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import { Divider, Empty, Popconfirm } from 'antd'
import styled from 'styled-components'

import NewEvidenceModal from './partials/NewEvidenceModal'
import PermissionIsRequired from '../../components/PermissionIsRequired'

import { getEvidenceTemplates } from '../EvidenceTemplates/services'
import { deleteEvidence, getEvidence } from './services'
import { can, handleError, handleSuccess } from '../../helpers'

export default function TaskEvidenceModal({ visible, onClose, verificationTask, verificationListId, editable, reload }) {
	const [evidenceTemplates, setEvidenceTemplates] = useState(null)
	const [evidence, setEvidence] = useState(null)
	const [newEvidenceModal, setNewEvidenceModal] = useState(null)

	useEffect(() => {
		!evidenceTemplates && can('Ver listado de plantillas de evidencia') && getEvidenceTemplates({
			'filter[attach_task_template_id]': verificationTask.task_template.id
		})
			.then(res => setEvidenceTemplates(res.data.data))
			.catch(error => handleError(error))
	}, [ evidenceTemplates, verificationTask ])

	useEffect(() => {
		!evidence && evidenceTemplates && can('Ver listado de evidencias') && getEvidence({
			'filter[verification_list_id]': verificationListId,
			'filter[evidence_template_id]': evidenceTemplates.map(e => e.id).join(','),
		})
			.then(res => setEvidence(res.data.data))
			.catch(error => handleError(error))
	}, [ evidence, evidenceTemplates, verificationListId ])

	const handleDelete = id => {
		deleteEvidence(id)
			.then(res => handleSuccess(res.data.message, () => setEvidence(null)))
			.catch(error => handleError(error))
	}

	if(!can('Ver listado de plantillas de evidencia'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para ver plantillas de evidencia" onClose={onClose} />
	
	if(!can('Ver listado de evidencias'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para ver evidencia" onClose={onClose} />
 
	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={() => {
					onClose()
					reload()
				}}>Evidencia Relacionada</ModalHeader>
				<ModalBody>
					{ (evidenceTemplates && evidence) ? (
						<>
							{ evidenceTemplates.length > 0 ? (
								<>
									{ evidenceTemplates.map(template => (
										<TemplateContainer key={template.id}>
											<p className='mb-5'><small>{template.type_name}</small></p>
											<p>{template.name}</p>
											{ evidence.length > 0 && (
												<>
													{ evidence.map( (singleEvidence, i) => (
														<div key={i} className="mb-10">
															<a target="_blank" href={singleEvidence.attachment_uri} rel="noreferrer">
																<Button color='primary' outline size="sm">
																	Ver Evidencia #{i + 1}
																</Button>
															</a>
															<Divider type='vertical' />
															<Popconfirm
																title="Esta acción no se puede revertir"
																okText="Eliminar"
																cancelText="Cancelar"
																okButtonProps={{ danger: true }}
																onConfirm={() => handleDelete(singleEvidence.id)}
																disabled={!editable}
															>
																<Button disabled={!editable} color="danger" outline size="sm">Eliminar</Button>
															</Popconfirm>
														</div>
													)) }
												</>
											)}
											<Button disabled={!editable} color='primary' outline size="sm" onClick={() => setNewEvidenceModal(template)}>
												Agregar Evidencia
											</Button>
										</TemplateContainer>
									))}
								</>
							) : (
								<Empty 
									description="No se solicita evidencia para esta tarea"
									imageStyle={{ height: '70px;' }}
								/>
							)}
						</>
					) : <Spinner />}
				</ModalBody>
			</Modal>
			{ newEvidenceModal && (
				<NewEvidenceModal 
					visible
					onClose={() => setNewEvidenceModal(null)}
					evidenceTemplate={newEvidenceModal}
					verificationTask={verificationTask}
					verificationListId={verificationListId}
					reload={() => setEvidence(null)}
				/>
			)}
		</>
	)
}

const TemplateContainer = styled.div`
	margin-bottom: 15px;
	border: 1px solid #e8e8e8;
	padding: 10px;
`