import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'

import { updateInquiry } from '../../services'
import { handleError, handleSuccess } from '../../../../helpers'

export default function EditInquiryModal({ visible, onClose, reload, inquiry }) {
	const { handleSubmit, register, formState: { errors } } = useForm({
        defaultValues: {
            client_requirements: inquiry.client_requirements,
            invoice_requirements: inquiry.invoice_requirements,
            legal_reference: inquiry.legal_reference,
            observations: inquiry.observations
        }
    })

    const onSubmit = values => {
        updateInquiry(inquiry.id, values)
            .then(res => handleSuccess(res.data.message, reload, onClose))
            .catch(error => handleError(error))
    }

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Actualizar Solicitud</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group>
                            <Form.Label>Requerimientos del cliente</Form.Label>
                            <Form.Control 
                                as="textarea"
                                {...register('client_requirements', { required: true })}
                                placeholder='Escriba aquí...'
                                style={{ height: '80px' }}
                            />
                            { errors.client_requirements && <Form.Text className="text-danger">Este campo es requerido</Form.Text> }
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Requerimientos de la factura</Form.Label>
                            <Form.Control 
                                as="textarea"
                                {...register('invoice_requirements', { required: true })}
                                placeholder='Escriba aquí...'
                                style={{ height: '80px' }}
                            />	
                            { errors.invoice_requirements && <Form.Text className="text-danger">Este campo es requerido</Form.Text> }
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Norma frente a las cuales se realiza el proceso de inspección.</Form.Label>
                            <Form.Control 
                                as="textarea"
                                {...register('legal_reference', { required: true })}
                                placeholder='Escriba aquí...'
                                style={{ height: '130px' }}
                            />
                            { errors.legal_reference && <Form.Text className="text-danger">Este campo es requerido</Form.Text> }
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>INFORMACIÓN ADICIONAL ESPECÍFICA ÚTIL PARA LA PRESTACIÓN DEL SERVICIO:</Form.Label>
                            <Form.Control 
                                as="textarea"
                                {...register('observations', { required: true })}
                                placeholder='Escriba aquí...'
                                style={{ height: '80px' }}
                            />
                            { errors.observations && <Form.Text className="text-danger">Este campo es requerido</Form.Text> }
                        </Form.Group>
                        <Button color='primary' type="submit">
                            Actualizar Solicitud
                        </Button>
                    </Form>
				</ModalBody>
			</Modal>
		</>
	)
}