import { Popconfirm } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { Badge, Button, Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap'
import moment from 'moment'

import CommentsButton from '../../Comments/partials/CommentsButton'

import { updateProposal } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function ProposalProfileTopBar({ proposal, reload }) {

	const handleRejectProposal = () => {
		updateProposal(proposal.id, {
			rejected_at: moment().format('YYYY-MM-DD HH:mm:ss'),
		})
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	const handlePublishProposal = () => {
		updateProposal(proposal.id, {
			published_at: moment().format('YYYY-MM-DD HH:mm:ss'),
		})
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	return (
		<>
			<Card>
				<CardBody>
					<div className='float-right pt-10'>
                        <Link to={`/inquiries/preview/${proposal.inquiry_id}`}>
                            <Button color='primary' outline className='ml-10'>
                                Solicitud de Servicio
                            </Button>
                        </Link>
						<Link to={`/proposals/preview/${proposal.id}`}>
                            <Button color='primary' outline className='ml-10'>
                                Abrir Formato
                            </Button>
                        </Link>
						{ proposal.status.label === 'En Borrador' && can('Publicar Propuestas') && (
							<Popconfirm
								title="¿Estás seguro de publicar esta propuesta?"
								onConfirm={() => handlePublishProposal()}
								okText="Publicar"
								cancelText="Cancelar"
							>
								<Button color='primary' outline className='ml-10'>
									Publicar Propuesta
								</Button>
							</Popconfirm>
						)}
						{ !proposal.fulfilled_at && !proposal.rejected_at && can('Rechazar propuestas') && (
							<Popconfirm
								title="¿Estás seguro de querer rechazar esta propuesta?"
								onConfirm={() => handleRejectProposal()}
								okText="Rechazar"
								cancelText="Cancelar"
								okButtonProps={{ danger: true }}
							>
								<Button color='danger' outline className='ml-10'>
									Rechazar Propuesta
								</Button>
							</Popconfirm>
						)}
						<CommentsButton type='proposal' id={proposal.id} />
					</div>
					<CardTitle>
						Propuesta {proposal.sku}{" "}
						<Badge color={proposal.status.color} style={{ fontSize: '11px' }}>
							{proposal.status.label}
						</Badge>
					</CardTitle>
					<CardSubtitle>
						Solicitud de Servicio {proposal.inquiry.sku}
					</CardSubtitle>
				</CardBody>
			</Card>
		</>
	)
}