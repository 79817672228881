import React from 'react'
import { Descriptions } from 'antd'

import { currency } from '../../../helpers'

const { Item } = Descriptions

export default function TripExpenseDetails({ tripExpense }) {
    return (
        <Descriptions bordered className='pt-10 mb-15' size='small' column={2}>
            <Item label="Gastos de Transporte">
                {tripExpense ? `$ ${currency(tripExpense.cost_transport)} COP` : <em className='small text-muted'>Sin especificar</em>}
            </Item>
            <Item label="Gastos de Hospedaje">
                {tripExpense ? `$ ${currency(tripExpense.cost_accommodation)} COP` : <em className='small text-muted'>Sin especificar</em>}
            </Item>
            <Item label="Gastos de Alimentación">
                {tripExpense ? `$ ${currency(tripExpense.cost_food)} COP` : <em className='small text-muted'>Sin especificar</em>}
            </Item>
            <Item label="Gastos de Tiempo Muerto">
                {tripExpense ? `$ ${currency(tripExpense.cost_dead_time)} COP` : <em className='small text-muted'>Sin especificar</em>}
            </Item>
            <Item label="Total Gastos de Viaje" span={2}>
                {tripExpense ? `$ ${currency(tripExpense.cost_total)} COP` : <em className='small text-muted'>Sin especificar</em>}
            </Item>
        </Descriptions>
    )
}