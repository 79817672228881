import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import ReviewLogbookPreview from './ReviewLogbookPreview'

export default function ReviewLogbooks() {
	return (
		<>
			<Switch>
				<Route exact path="/review_logbooks/preview/:id" component={ReviewLogbookPreview} />

				<Redirect to="/404" />
			</Switch>
		</>
	)
}