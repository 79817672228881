import React from 'react'
import { Form } from 'react-bootstrap'

import UploadPaymentReceiptAttachment from './UploadPaymentReceiptAttachment'

export default function PaymentReceiptForm({ register, errors, setAttachmentUri, proposalId }) {
	return (
		<>
			<Form.Group>
				<Form.Label>Subir Soporte de Pago</Form.Label>
				<UploadPaymentReceiptAttachment 
					setAttachmentUri={setAttachmentUri}
					proposalId={proposalId}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Método de Pago <span className="text-danger">*</span></Form.Label>
				<Form.Control 
					as="select"
					{...register('payment_method', { required: true })}
					placeholder="Escriba aquí..."
				>
					<option value="">:: Seleccione una opción ::</option>
					<option>De contado</option>
					<option>A plazo</option>
					<option>Contra facturación</option>
					<option>Requiere actas de entrega para pago</option>
				</Form.Control>
				{ errors.payment_method && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Comentarios <small>(opcional)</small></Form.Label>
				<Form.Control 
					as="textarea"
					{...register('comments', { })}
					placeholder="Escriba aquí..."
				/>
			</Form.Group>
		</>
	)
}