
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo } from '@fortawesome/free-solid-svg-icons'

import TasksTicketsTable from './TasksTicketsTable'

import { getTickets } from '../../../Tickets/services'
import { can, handleError } from '../../../../helpers'

export default function TasksTicketsModal({ visible, onClose, verificationList }) {
	const currentUser = useSelector(state => state.auth.user)
	const [tickets, setTickets] = useState(null)

	useEffect(() => {
		!tickets && visible && can('tickets.viewAll') && getTickets({
			'filter[ticketType.category_slug]': 'review_noncompliant_task',
			'filter[ticketable_type]': 'verification_list',
			'filter[ticketable_id]': verificationList.id,
			include: 'ticketNotes,ticketType,user,company',
		})
			.then(res => setTickets(res.data.data))
			.catch(error => handleError(error))
	}, [ tickets, visible, currentUser, verificationList ])

	return (
		<>
			<Modal isOpen={visible} size='xl'>
				<ModalHeader toggle={onClose}>Novedades Recibidas</ModalHeader>
				<ModalBody>
					<TasksTicketsTable 
						tickets={tickets}
						reload={() => setTickets(null)}
					/>
					<Button 
						color='primary' 
						className='mt-5'
						onClick={() => setTickets(null)}
					>
						<FontAwesomeIcon icon={faRedo} />
					</Button>
				</ModalBody>
			</Modal>
		</>
	)
}