import React from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form, Button, InputGroup } from 'react-bootstrap'

import { storeComment } from '../services'
import { handleSuccess, handleError } from '../../../helpers'

export default function NewCommentForm({ type, id, reload }) {
    const user = useSelector(state => state.auth.user)
    const { handleSubmit, register, formState: { errors }, reset } = useForm({
        defaultValues: {
            commentable_type: type,
            commentable_id: id,
            user_id: user.id,
        },
    })

    const onSubmit = values => {
        storeComment(values)
            .then(res => handleSuccess(res.data.message, reload, reset))
            .catch(error => handleError(error))
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
                <Form.Control 
                    {...register('body', { required: true })}
                    placeholder='Escribe un comentario...'
                    isInvalid={!!errors.body}
                    as='textarea'
                    style={{ height: '80px' }}
                />
                <Button color='primary' type="submit" className='mt-0'>
                    Comentar
                </Button>
            </InputGroup>
            { errors.body && <Form.Text className='text-danger'>Este campo es requerido</Form.Text> }
        </Form>
    )
}