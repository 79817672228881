import { message } from 'antd'

import { store } from './store'
import { checkConnection } from './containers/Offline/actions'

export const handleError = (error, setLoading = () => {}) => {
   let msg = error.message
   let rsp = error.response

   setLoading(false)

   if(msg === 'Network Error') {
      message.warning('No se detecto conexión a internet')
      return store.dispatch(checkConnection())
   }

   if(!rsp) return message.error('Error Fatal. Favor comunicarse con soporte@smart4.com.co. ' + msg)

   // If is unauthorize redirect to login
   if(rsp.status === 403 && rsp.config.url !== '/#/auth/login')
      return message.error('No posee permisos para esta acción.')
   
   if(rsp.status === 401) {
      window.location.href = '/#/auth/logout'
      return message.error(`Su sesión expiró. Inicie sesión de nuevo.`, 7)
   }

   // Default messages
   if( rsp.status === 404) msg = 'No se encontraron resultados (404).'
   if( rsp.status === 500) msg = 'Error en el servidor. Escriba a soporte@smart4.com.co'

   // If api response has error message set it as default
   if( rsp.data.message ) msg = rsp.data.message

   // Force messages
   if( rsp.status === 422) {      
      msg = ''
      for (let [key, value] of Object.entries(rsp.data.errors)) {
         msg += `[${key}]: ${value.join(', ')}. `
      }
   }

   // Show antd error message
   return message.error(`Atención. ${msg}`, 6)
}

export const handleSuccess = (msg, reload = () => {}, onClose = () => {}) => {
   message.success(msg)
	reload()
   onClose()
}

export const parsePagination = response => {
   let meta = response.data.meta

   return { 
      current_page: meta.current_page, 
      per_page: parseInt(meta.per_page), 
      total: meta.total 
   }
}

export const round = (num, decimals = 100) => (
	num ? Math.round((num + Number.EPSILON) * decimals) / decimals : null
)

export const currency = num => (
	num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
)

export const dewar_vars = {
   inquiry_ins_prefix: 'SSN',
   inquiry_tes_prefix: 'SSP',
   proposal_ins_prefix: 'PRN',
   proposal_tes_prefix: 'PRP',
}

export const text = (val, message) => {
   return val ? val : (message ? message : <em className='small text-muted'>Sin observación</em>)
}

export const is_positive = (val) => {
   let positiveValues = ['Sí', 'Si', 'si', 'sí', 1, true]
   return positiveValues.includes(val)
}

export const serialize = obj => {
   var str = [];
   for (var p in obj)
     if (obj.hasOwnProperty(p)) {
       str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]))
     }
   return str.join("&")
}

export const can = (permission) => {
   const state = store.getState()
   const permissions = state.auth.user.permissions

   if(state.auth.user.roles.includes('Super Administrador'))
      return true

   if(permissions && permissions.includes(permission))
      return true
   
   return false
}