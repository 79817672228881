import React, { useState } from 'react'
import { Divider, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditRoleModal from './EditRoleModal'
import EditRolePermissionsModal from './EditRolePermissionsModal'

import { deleteRole } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

export default function RolesTable({ roles, reload }) {
	const [editRole, setEditRole] = useState(null)
	const [rolePermissions, setRolePermissions] = useState(null)

	const handleDelete = id => {
		deleteRole(id)
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Nombre del Rol',
			dataIndex: 'name'
		},
		{
			title: 'Permisos Asignados',
			dataIndex: 'permissions_count',
			render: (t, r) => r.is_super_admin ? 'Acceso completo' : (
				<>
					{`${t} permisos`}
					<Divider type="vertical" />
					<Tooltip title="Editar">
						<div className='text-link inline' onClick={()=>setRolePermissions(r)}>
							Editar
						</div>
					</Tooltip>
				</>
			)
		},
		{
			title: 'Usuarios Asociados',
			dataIndex: 'users_count',
			render: (t, r) => (
				<>
					{`${t} usuarios`}
				</>
			)
		},
		{
			title: 'Fecha Creación',
			dataIndex: 'created_at'
		},
		{
			title: 'Acciones',
			dataIndex: 'is_super_admin',
			render: (t, r) =>  !t ? (
				<>
					<Tooltip title="Editar Rol">
						<div className='inline' onClick={()=>setEditRole(r)}>
							<FontAwesomeIcon className="text-link" icon={faEdit} />
						</div>
					</Tooltip>
					{ (r.users_count === 0) && (
						<>
							<Divider type="vertical" />
							<Popconfirm 
								title="¿Desea eliminar el rol?"
								okText="Eliminar"
								cancelText="Cancelar"
								onConfirm={() => handleDelete(r.id)}
								okButtonProps={{ danger:true }}
							>
								<FontAwesomeIcon className="text-link" icon={faTrash} />
							</Popconfirm>
						</>
					)}
				</>
			) : ( <em className='small'>No se puede modificar</em> )
		}
	]

	if(!can('Ver listado de roles'))
		return <PermissionIsRequired title="No tiene acceso a ver listado de roles" />

	return (
		<>
			<Table 
				dataSource={roles}
				columns={columns}
				rowKey={record => record.id}
				loading={!roles}
			/>
			{ editRole && (
				<EditRoleModal 
					visible={true}
					onClose={() => setEditRole(null)}
					role={editRole}
					reload={reload}
				/>
			)}
			{ rolePermissions && (
				<EditRolePermissionsModal
					visible={true}
					onClose={() => setRolePermissions(null)}
					role={rolePermissions}
					reload={reload}
				/>
			)}
		</>
	)
}