import React from 'react'

import ProposalPreInvoicesList from '../../../PreInvoices/ProposalPreInvoicesList'

export default function ProposalPreInvoicesTab({ proposal, reload }) {
    return (
        <>
            <p className='bold mt-20'>Cuentas de Cobro</p>
            <ProposalPreInvoicesList
                proposalId={proposal.id}
                reload={reload}
            />	
        </>
    )
}