import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import TankSpecsTable from './partials/TankSpecsTable'
import NewTankSpecModal from './partials/NewTankSpecModal'
import IslandSpecsTable from './partials/IslandSpecsTable'
import NewIslandSpecModal from './partials/NewIslandSpecModal'

import { getReviewSpecs } from './services'
import { can, handleError } from '../../helpers'
import PermissionIsRequired from '../../components/PermissionIsRequired'

export default function ServiceReviewSpecsList({ service, serviceReviewId }) {
	const { isConnected } = useSelector(state => state.offline)
	const [tankSpecs, setTankSpecs] = useState(null)
	const [islandSpecs, setIslandSpecs] = useState(null)
	const [showNewTankModal, setShowNewTankModal] = useState(false)
	const [showNewIslandModal, setShowNewIslandModal] = useState(false)

	useEffect(() => {
		!tankSpecs && isConnected && getReviewSpecs({
			'filter[only_tanks]': 1,
			'filter[service_review_id]': serviceReviewId,
		})
			.then(res => setTankSpecs(res.data.data))
			.catch(error => handleError(error))
	}, [ tankSpecs, serviceReviewId, isConnected ])

	useEffect(() => {
		!islandSpecs && isConnected && getReviewSpecs({
			'filter[only_islands]': 1,
			'filter[service_review_id]': serviceReviewId,
		})
			.then(res => setIslandSpecs(res.data.data))
			.catch(error => handleError(error))
	}, [ islandSpecs, serviceReviewId, isConnected ])

	useEffect(() => {
		if(!isConnected && !islandSpecs)
			setIslandSpecs(service.service_inspector.service_plan.service_review.island_specs)
		
		if(!isConnected && !tankSpecs)
			setTankSpecs(service.service_inspector.service_plan.service_review.tank_specs)
		
	}, [isConnected, service, tankSpecs, islandSpecs])

	const commonProps = {
		editable: service.is_editable,
		serviceId: service.id,
		serviceReview: service.service_inspector.service_plan.service_review
	}

	if(!can('Ver listado de Características técnicas de la instalación')) 
		return <PermissionIsRequired title="No tiene permiso para ver características" />

	return (
		<>
			<TankSpecsTable 
				tankSpecs={tankSpecs} 
				reload={()=>setTankSpecs(null)} 
				{...commonProps}
			/>
			{ service.is_editable && (
				<div className='text-right mt-15'>
					<p className='text-link inline' onClick={() => setShowNewTankModal(true)}>
						Agregar características de tanque
					</p>
				</div>
			)}
			<div className="mt-15" />
			<IslandSpecsTable 
				islandSpecs={islandSpecs} 
				reload={()=>setIslandSpecs(null)}
				{...commonProps}
			/>
			{ service.is_editable && (
				<div className='text-right mt-15'>
					<p className='text-link inline' onClick={() => setShowNewIslandModal(true)}>
						Agregar características
					</p>
				</div>
			)}
			<div className="mt-15" />
			{ showNewTankModal && (
				<NewTankSpecModal 
					visible
					onClose={() => setShowNewTankModal(false)}
					reload={() => setTankSpecs(null)}
					{...commonProps}
				/>
			)}
			{ showNewIslandModal && (
				<NewIslandSpecModal 
					visible
					onClose={() => setShowNewIslandModal(false)}
					reload={() => setIslandSpecs(null)}
					{...commonProps}
				/>
			)}
		</>
	)
}