import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { DatePicker, TimePicker } from 'antd'
import moment from 'moment'

import UploadAcceptanceAttachment from '../UploadAcceptanceAttachment'

import { updateProposal } from '../../services'
import { handleError, handleSuccess } from '../../../../helpers'

export default function ProposalAcceptanceForm({ proposal, reload }) {
    const [acceptedAtDay, setAcceptedAtDay] = useState(moment())
    const [acceptedAtTime, setAcceptedAtTime] = useState(moment())
    const [attachmentUri, setAttachmentUri] = useState('')

    const onSubmit = () => {
        updateProposal(proposal.id, { 
            accepted_at: `${acceptedAtDay.format('YYYY-MM-DD')} ${acceptedAtTime.format('HH:mm:ss')}`,
            accept_attachment_uri: attachmentUri
        })
            .then(res => handleSuccess(res.data.message, reload))
            .catch(error => handleError(error))
    }

    return (
        <>
            <Form.Group className='mb-10'>
                <Form.Label>Fecha de Aceptación</Form.Label>
                <div>
                    <DatePicker 
                        value={acceptedAtDay}
                        onChange={date => setAcceptedAtDay(date)}
                        disabledTime={false}
                        className="mr-10"
                    />
                    <TimePicker 
                        value={acceptedAtTime}
                        onChange={time => setAcceptedAtTime(time)}
                        format="hh:mm a"
                    />
                </div>
            </Form.Group>
            <Form.Group>
                <Form.Label>Subir Soporte de Aceptación</Form.Label>
                <UploadAcceptanceAttachment
                    setAttachmentUri={setAttachmentUri}
                    proposalId={proposal.id}
                />
            </Form.Group>
            <Button color="primary" onClick={onSubmit} className='mt-20' disabled={!attachmentUri}>
                Registrar Aceptación
            </Button>
        </>
    )
}