import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import ServiceVeredictPreview from './ServiceVeredictPreview'

export default function ServiceVeredicts() {
	return (
		<>
			<Switch>
				<Route exact path="/service_veredicts/preview/:id" component={ServiceVeredictPreview} />

				<Redirect to="/404" />
			</Switch>
		</>
	)
}