import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Spinner } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { reassignServiceInspector } from '../services'
import { searchInspectors } from '../../Users/services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function ReassignServiceInspectorModal({ visible, onClose, service, reload }) {
	const { register, handleSubmit, formState: { errors }, setValue } = useForm()
	const [userSelected, setUserSelected] = useState([])
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		reassignServiceInspector(service.service_inspector.id, values)
			.then(res => handleSuccess(res.data.message, reload, onClose))
			.catch(error => handleError(error, setLoading))
	}

	if(!can('Actualizar inspectores de servicios'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar asignaciones" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Actualizar Asignación de Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
							<Form.Label>Nuevo Inspector Asignado <span className='text-danger'>*</span></Form.Label>
							<ServerSideSelect
								value={userSelected}
								placeholder="Buscar por nombre de inspector"
								fetchOptions={searchInspectors}
								onChange={e => {
									setUserSelected(e)
									setValue('user_id', e.value)
								}}
								className="full-width mb-10"
							/>
							<Form.Control {...register('user_id', { required: true })} style={{ display:'none' }} />
						</Form.Group>
						<Form.Group>
							<Form.Label>Fecha envío asignación <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								{...register('assigned_at', { required: true })}
								type="date"
							/>
							{ errors.assigned_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Button color="primary" type='submit' className='mt-10' disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Reasignar Inspección
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}