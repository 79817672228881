import dewarApi from '../../services/dewarApi'

export const getComments = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/comments', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getComment = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/comments/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeComment = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/comments', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateComment = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/comments/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteComment = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/comments/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)