import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import SyncLogsList from './SyncLogsList'

export default function SyncLogs() {
	return (
		<>
			<Switch>
				<Route exact path="/sync_logs" component={SyncLogsList} />

				<Redirect to="/404" />
			</Switch>
		</>
	)
}