import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

import { getService } from '../../services'
import { handleError } from '../../../../helpers'

export default function RelatedFormatsModal({ visible, onClose, relatedService }) {
	const [service, setService] = useState(null)

	useEffect(() => {
		!service && getService(relatedService.id, {
			include: 'serviceInspector.serviceNotification,serviceInspector.servicePlan.serviceReview,serviceInspector.servicePlan.serviceReview.serviceReport.serviceVeredict,serviceInspector.servicePlan.serviceReview.serviceReport.serviceVeredict.certificate,verificationList'
		})
			.then(res => setService(res.data.data))
			.catch(error => handleError(error))
	}, [ service, relatedService ])

	// const serviceInspector = service && service.service_inspector
	// const servicePlan = serviceInspector && serviceInspector.service_plan
	// const serviceReview = servicePlan && servicePlan.service_review
	// const serviceReport = serviceReview && serviceReview.service_report
	// const serviceVeredict = serviceReport && serviceReport.service_veredict
	// const certificate = serviceVeredict && serviceVeredict.certificate
	const verificationList = service && service.verification_list

	let formats = []

	// if(service.business_scope === 'inspeccion') {
		formats = formats.concat([
			{
				title: 'Reporte de No Conformidades',
				subtitle: 'Items registrados como no conformidades',
				linkDir: 'no_conformidades',
				data: verificationList,
			},
			{
				title: 'Lista de Verificación',
				subtitle: 'Items registrados en el servicio',
				linkDir: 'lista_verificacion',
				data: verificationList,
			},
		])
	// } else if(service.business_scope === 'pruebas') {
		// formats = formats.concat([
		// 	{
		// 		title: 'Informe de Inspección',
		// 		subtitle: 'Resultados del proceso y decisión del comité técnico.',
		// 		linkDir: 'informes',
		// 		data: serviceReport
		// 	},
		// 	{
		// 		title: 'Certificado Otorgado',
		// 		subtitle: 'Certificado de acreditación oficial.',
		// 		linkDir: 'certificadosof',
		// 		data: certificate
		// 	}	
		// ])
	// }

	if(!service)
		return <Modal><ModalBody><Spinner animation='border' size="sm" /></ModalBody></Modal>

	return (
		<Modal isOpen={visible} size="xl">
			<ModalHeader toggle={onClose}></ModalHeader>
			<ModalBody>
				<Row className="g-2">
					{ formats.map((format, i) => (
						<Col key={i} md="3" sm="6" xs="12">
							<Container disabled={!format.data}>
								<h5>{format.title}</h5>
								{ format.subtitle && <p>{format.subtitle}</p> }
								{ format.linkDir && (
									<Button
										size="sm"
										outline
										color="primary"
										disabled={!format.data}
										onClick={() => window.open(`/#/vista_previa/${format.linkDir}/${format.data ? format.data.id : ''}`)}
									>
										{ format.data ? 'Abrir Formato' : 'Formato No Disponible' }
									</Button>
								)}
							</Container>
						</Col>
					)) }
				</Row>
			</ModalBody>
		</Modal>
	)
}

const Container = styled.div`
	border: 1px solid #f0f0f0;
	background-color: ${props => props.disabled ? '#f5f5f5' : '#e78035'};
	padding: 10px;
	min-height: 115px;
	&:hover {
		cursor: ${props => props.disabled ? 'not-allowed' : 'default'};
		opacity: ${props => props.disabled ? '0.5' : '1'};
	}
	& h5 {
		font-size: 16px;
		color: ${props => props.disabled ? '#333' : '#fff'};
		margin-bottom: 3px;
	}
	& p {
		font-size: 13px;
		color: ${props => props.disabled ? '#333' : '#f0f0f0'};
	}
	& button:hover {
		background-color: ${props => props.disabled ? '#fff' : '#333'};
	}
	& button:focus {
		background-color: ${props => props.disabled ? '#fff' : '#333'};
	}
`