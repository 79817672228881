import React from 'react'

import ProposalAcceptanceForm from '../forms/ProposalAcceptanceForm'
import ReplaceAcceptanceForm from '../forms/ReplaceAcceptanceForm'
import { Result } from 'antd'

export default function ProposalAcceptanceTab({ proposal, reload }) {

    if(proposal.status.label === 'Rechazada')
        return <Result 
            status="error"
            title="Propuesta Rechazada"
            subTitle="La propuesta ha sido rechazada por el cliente."
        />

    return (
        <>
            <p className='bold mt-20'>Aceptación de la Propuesta</p>
            { !proposal.accepted_at ? (
                <ProposalAcceptanceForm
                    proposal={proposal}
                    reload={reload}
                />
            ) : (
                <ReplaceAcceptanceForm
                    proposal={proposal}
                    reload={reload}
                />
            )}
        </>
    )
}