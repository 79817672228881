import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import PreviewFormat from './PreviewFormat'

export default function PreviewFormats() {
	return (
		<>
			<Switch>
				<Route 
					exact 
					path="/vista_previa/:type/:id" 
					render={props => (
						<PreviewFormat 
							type={props.match.params.type} 
							id={props.match.params.id}
						/>
					)}
				/>

				<Redirect to="/404" />
			</Switch>
		</>
	)
}