import React, { useEffect, useState } from 'react'
import { Divider, Drawer, Empty, Spin } from 'antd'

import NewCommentForm from './NewCommentForm'
import CommentCard from './CommentCard'

import { getComments } from '../services'
import { handleError } from '../../../helpers'

export default function CommentsDrawer({ type, id, visible, onClose }) {
    const [comments, setComments] = useState(null)
    const [reload, setReload] = useState(!comments)

    useEffect(() => {
        reload && getComments({
            [`filter[commentable_type]`]: type,
            [`filter[commentable_id]`]: id,
        })
            .then(res => setComments(res.data.data))
            .catch(error => handleError(error))
            .finally(() => setReload(false))
    }, [comments, type, id, reload])

    if(!comments) 
        return <Drawer title='Comentarios' open={visible} onClose={onClose}><Spin /></Drawer>

    return (
        <Drawer title='Comentarios' open={visible} onClose={onClose}>
            <div className='mb-20'>
                { comments.length > 0 ? (
                    <div>
                        { comments.map(comment => (
                            <CommentCard 
                                key={comment.id} 
                                comment={comment} 
                                reload={() => setReload(true)} 
                            />
                        )) }
                    </div>
                ) : (
                    <Empty description='No hay comentarios' />
                ) }
            </div>
            <Divider className='my-10' />
            <div className='mt-20'>
                <NewCommentForm 
                    id={id} 
                    type={type} 
                    reload={() => setReload(true)} 
                />
            </div>
        </Drawer>
    )
}
