import React from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/es'

import DocumentHeader from '../../../../components/DocumentHeader'
import selloDewar from '../../../../assets/images/sello_dewar.png'
import { currency, round } from '../../../../helpers'

export default function PRE_INVOICE_TEMPLATE_PRU01({ data }) {
	return (
		<Document>
			<DocumentHeader 
				title="CUENTA DE COBRO"
				version={data.format_version.sku}
				expireAt={data.format_version.expire_at}
				proposalSku={data.proposal.sku}
			/>
			<Row>
				<Col sm="7">
					<h6 className='mb-30'>
						No CCN-{data.id}-{moment(data.elaborated_at).format('MM')}-{moment(data.elaborated_at).format('YYYY')}
					</h6>
					<table>
						<tbody>
							<tr>
								<td>Fecha:</td>
								<td>{moment(data.elaborated_at).format('DD-MMM-YYYY').toUpperCase()}</td>
							</tr>
							<tr>
								<td>Cliente:</td>
								<td>{data.proposal.inquiry.branch_spec.branch.company.name}</td>
							</tr>
							<tr>
								<td>Nit:</td>
								<td>{data.proposal.inquiry.branch_spec.branch.company.complete_document}</td>
							</tr>
							<tr>
								<td>Teléfono:</td>
								<td>{data.proposal.inquiry.branch_spec.branch.company.phone} - {data.proposal.inquiry.branch_spec.branch.company.mobile}</td>
							</tr>
							<tr>
								<td>Dirección:</td>
								<td>{data.proposal.inquiry.branch_spec.branch.address_line} - {data.proposal.inquiry.branch_spec.branch.address_state}, {data.proposal.inquiry.branch_spec.branch.address_city}</td>
							</tr>
						</tbody>
					</table>
				</Col>
				<Col>
					<div className='details_container'>
						<p>DEWAR SAS</p>
						<p>NIT 900.260.126-9</p>
						<p>Carrera 61 # 96 A 23 Oficina 201</p>
						<p>7039155 7040352</p>
						<p>BOGOTA</p>
					</div>
				</Col>
			</Row>
			<h6>DEBE A DEWAR S.A.S POR CONCEPTO:</h6>
			<p className='details_container'>{data.details}</p>
			<Row>
				<Col>
					<table className='table'>
						<tbody>
							<tr>
								<td>VALOR POR UNIDAD</td>
								<td>$ {currency(data.service_value)} COP</td>
							</tr>
							<tr>
								<td>CANTIDAD</td>
								<td>{data.quantity}</td>
							</tr>
							<tr>
								<td>VALOR DEL SERVICIO</td>
								<td>${currency(data.service_value*data.quantity)} COP</td>
							</tr>
							{ data.trip_expenses_value > 0 && (
								<tr>
									<td>VALOR TRANSPORTE GRAVADO</td>
									<td>$ {currency(data.trip_expenses_value)}</td>
								</tr>
							)}
							<tr>
								<td>BASE TOTAL</td>
								<td>$ {currency(data.total_before_taxes)}</td>
							</tr>
							<tr>
								<td>IVA 19%</td>
								<td>$ {currency(Math.round(data.taxes))}</td>
							</tr>
							<tr className='bold'>
								<td>TOTAL</td>
								<td>$ {currency(round(data.total_after_taxes, 1))}</td>
							</tr>
							{ (data.soldicom_value > 0) && (
								<>
									<tr>
										<td>Descuento SOLDICOM</td>
										<td>$ {currency(data.soldicom_value)}</td>
									</tr>
									<tr className='bold'>
										<td>TOTAL A PAGAR</td>
										<td>$ {currency(Math.round(parseInt(data.total_after_taxes)-parseInt(data.soldicom_value)))}</td>
									</tr>
								</>
							)}
							<tr>
								<td colSpan={2}><small>Retenciones (favor leer notas)</small></td>
							</tr>
						</tbody>
					</table>
				</Col>
				<Col></Col>
			</Row>
			<p className='details_container mb-20'>{data.observations}</p>
			<h6>NOTAS:</h6>
			<ol>
				<li>Somos persona Jurídica régimen gravado, hacer retención en la fuente del 4% para el servicio de inspección de pruebas y del 1% para el transporte gravado. De no estar obligado a practicarla por favor abstenerse de hacerla.</li>
				<li>Actividad económica en ICA: 70201 tarifa para la ciudad de Bogotá 8,66 x 1000.</li>
				<li>Verificar muy bien la información tributaria antes de hacer su pago. De existir alguna diferencia con lo pagado, éste valor quedará como anticipo para el próximo servicio. No se hará devolución de dinero.</li>
				<li>Favor consignar en Bancolombia cuenta de Ahorros No. 650-582330-69 a nombre de Dewar S.A.S y enviar el soporte a los correos electrónicos: asistente@dewar.com.co y administrativo@dewar.com.co</li>
				<li>Se expedirá la Factura de Venta al momento de confirmar el pago y programar la presentación del servicio correspondiente. (En ningún momento esta cuenta de cobro reemplaza la factura de venta).</li>
			</ol>
			<p className='mt-30'>Cordialmente.</p>
			<img src={selloDewar} alt="Dewar" width="170px" />
			<p className='text-underline'>Asistente Comercial y Administrativa - DEWAR – OIN</p>
		</Document>
	)
}

const Document = styled.div`
	background-color: #fff;
	padding: 15px;
	p {
		font-size: 15px;
	}
	span.variable {
		color: #e78035;
		font-weight: 600;
	}
	h5 {
		font-size: 21px;
	}
	.details_container {
		padding: 15px;
		border: 2px dashed #888;
		margin-bottom: 20px;
	}
`