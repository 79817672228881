import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import NewInstalationTypeForm from './partials/NewInstalationTypeForm'
import InstalationTypesTable from './partials/InstalationTypesTable'

import { getInstalationTypes } from './services'
import { handleError } from '../../helpers'
import InternetRequired from '../Offline/partials/InternetRequired'

export default function InstalationTypesList() {
	const { isConnected } = useSelector(state => state.offline)
	const [instalationTypes, setInstalationTypes] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')

	useEffect(()=>{
		!instalationTypes && isConnected && getInstalationTypes({
			include: '',
			[`filter[${filterType}]`]: filterValue
		})
			.then(res => setInstalationTypes(res.data.data))
			.catch(error => handleError(error))
	}, [instalationTypes, filterType, filterValue, isConnected])
	
	if(!isConnected) return <InternetRequired />

	return (
		<>
			<Row>
				<Col md="3">
					<Card>
						<CardHeader>
							<CardTitle>Agregar Nuevo</CardTitle>
							<CardSubtitle>Nuevo Tipo de Instalación</CardSubtitle>
						</CardHeader>
						<CardBody>
							<NewInstalationTypeForm reload={()=>setInstalationTypes(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col md="9">
					<ListTopBar 
						filterType={filterType}
						setFilterType={setFilterType}
						filterValue={filterValue}
						setFilterValue={setFilterValue}
						title="Tipos de Instalación"
						subtitle="Plataforma DEWAR"
						reload={() => setInstalationTypes(null)}
						options={[
							{ label: 'Buscar por nombre', value: 'name' },
						]}
					/>
					<Card>
						<CardBody>
							<InstalationTypesTable 
								instalationTypes={instalationTypes} 
								reload={()=>setInstalationTypes(null)} 
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>			
		</>
	)
}