import React, { useState } from 'react'
import { Button } from 'reactstrap'

import PaymentReceiptsTable from '../../../PaymentReceipts/partials/PaymentReceiptsTable'
import NewPaymentReceiptModal from '../../../PaymentReceipts/partials/NewPaymentReceiptModal'

export default function ProposalPaymentReceiptsTab({ proposal, reload }) {
    const [showNewModal, setShowNewModal] = useState(false)

    return (
        <>
            <p className='bold mt-20'>
                Soportes de Pagos
            </p>
            <PaymentReceiptsTable 
                paymentReceipts={proposal.payment_receipts} 
                reload={reload} 
            />
            <div className='text-right mt-10'>
                <Button color='primary' onClick={() => setShowNewModal(true)}>
                    Agregar Soporte de Pago
                </Button>
            </div>
            { showNewModal && (
                <NewPaymentReceiptModal
                    visible
                    onClose={() => setShowNewModal(false)}
                    proposalId={proposal.id}
                    reload={reload}
                />
            )}
        </>
    )
}