import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import moment from 'moment'

import NewReviewLogbookForm from '../forms/NewReviewLogbookForm'
import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { storeReviewLogbook } from '../../services'
import { can, handleError, handleSuccess } from '../../../../helpers'

export default function NewReviewLogbookModal({ visible, onClose, reload, serviceReviewId }) {
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        defaultValues: {
            service_review_id: serviceReviewId,
            register_at: moment().format('YYYY-MM-DD HH:mm:ss'),
            spec_type: 'tank',
            format_version_keyword: 'tank',
            spec_details: {},
            survey_answers: {},
            wells_tests: {},
            tightness_tests: {},
            requirements_survey: {},
        }
    })

    const onSubmit = values => {
        setLoading(true)

        values.register_at = moment(values.register_at).format('YYYY-MM-DD HH:mm:ss')

        storeReviewLogbook(values)
            .then(res => handleSuccess(res.data.message, reload))
            .catch(error => handleError(error, setLoading))
    }

    if(!can('Crear Bitacora de Tanques/Tuberias'))
        return <PermissionIsRequired type="modal" title="No tiene permisos para crear bitacoras" onClose={onClose} />

    return (
        <Modal isOpen={visible}>
            <ModalHeader toggle={onClose}>Agregar Bitacora de Inspección</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <NewReviewLogbookForm 
                        register={register} 
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                    />
                    <Button color='primary' type="submit" disabled={loading}>
                        { loading && <Spinner animation='border' size='small' /> }
                        Agregar Bitacora
                    </Button>
                </Form>
            </ModalBody>
        </Modal>
    )
}