import React from 'react'
import { Form } from 'react-bootstrap'

export default function IslandSpecForm({ register, errors, islandSpec }) {
	return (
		<>
			<Form.Group>
				<Form.Label>Nº Islas</Form.Label>
				<Form.Control 
					type="number"
					{...register('island_number', { required: true })}
				/>
				{ errors.island_number && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>No. Equipos de Suministro / Nº Posiciones <small>(Plantas)</small></Form.Label>
				<Form.Control 
					type="number"
					{...register('equipos_suministro_total', { required: true })}
				/>
				{ errors.equipos_suministro_total && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>No. Mangueras / Nº Brazos <small>(Plantas)</small></Form.Label>
				<Form.Control 
					type="number"
					{...register('mangueras_total', { required: true })}
				/>
				{ errors.mangueras_total && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
		</>
	)
}