import React, { useState } from 'react'  
import { useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, Button, Row, Col } from 'reactstrap'
import { Form } from 'react-bootstrap'

import { updateTripExpense, storeTripExpense } from './services'
import { handleError, handleSuccess, currency } from '../../helpers'

export default function ServiceTripExpenseModal({ visible, onClose, service, tripExpense, reload }) {
	const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, watch } = useForm({
		defaultValues: {
            service_inspector_id: service.service_inspector.id,
            company_id: service.proposal.inquiry.branch_spec.branch.company.id,
            branch_id: service.proposal.inquiry.branch_spec.branch.id,
            cost_transport: tripExpense ? tripExpense.cost_transport : 0,
            cost_accommodation: tripExpense ? tripExpense.cost_accommodation : 0,
            cost_food: tripExpense ? tripExpense.cost_food : 0,
            cost_dead_time: tripExpense ? tripExpense.cost_dead_time : 0,
        }
	})

	const onSubmit = (data) => {
        tripExpense ? (
            updateTripExpense(tripExpense.id, data)
                .then(res => handleSuccess(res.data.message, reload, onClose))
                .catch(error => handleError(error, setLoading))
        ) : (
            storeTripExpense(data)
                .then(res => handleSuccess(res.data.message, reload, onClose))
                .catch(error => handleError(error, setLoading))
        )
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Gastos de Viaje</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Gastos de Transporte</Form.Label>
                            <Form.Control 
                                type="number" 
                                {...register('cost_transport', { required: true })} 
                                placeholder='Escriba aquí...'
                            />
                            <span className='text-muted'>{`$ ${currency(watch('cost_transport'))}`}</span>
                            { errors.cost_transport && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Gastos de Hospedaje</Form.Label>
                            <Form.Control 
                                type="number" 
                                {...register('cost_accommodation', { required: true })} 
                                placeholder='Escriba aquí...'
                            />  
                            <span className='text-muted'>{`$ ${currency(watch('cost_accommodation'))}`}</span>
                            { errors.cost_accommodation && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Gastos de Alimentación</Form.Label>
                            <Form.Control 
                                type="number" 
                                {...register('cost_food', { required: true })} 
                                placeholder='Escriba aquí...'
                            />
                            <span className='text-muted'>{`$ ${currency(watch('cost_food'))}`}</span>
                            { errors.cost_food && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Gastos de Tiempo Muerto</Form.Label>
                            <Form.Control 
                                type="number" 
                                {...register('cost_dead_time', { required: true })} 
                                placeholder='Escriba aquí...'
                            />
                            <span className='text-muted'>{`$ ${currency(watch('cost_dead_time'))}`}</span>
                            { errors.cost_dead_time && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                        </Form.Group>
                    </Row>
                    <Form.Group>
                        <Form.Label>Total Gastos de Viaje</Form.Label>
                        <Form.Control 
                            disabled
                            value={`$ ${currency(parseInt(watch('cost_transport')) + parseInt(watch('cost_accommodation')) + parseInt(watch('cost_food')) + parseInt(watch('cost_dead_time')))}`}
                        />
                    </Form.Group>

                    <Button type="submit" color="primary" disabled={loading}>
                        {loading ? 'Actualizando...' : 'Actualizar Gastos de Viaje'}
                    </Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}