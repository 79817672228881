import React from 'react'
import { Card, CardHeader, Button, CardTitle, CardSubtitle } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'

export default function ListTopBar({ 
	filterType, 
	setFilterType, 
	filterValue, 
	setFilterValue, 
	filterScope,
	setFilterScope,
	reload,
	title,
	subtitle,
	options,
	scopes,
	scopeLabel,
	ctaText,
	handleCtaClick,
	className,
	prefix,
	onExport,
	secundaryCtaText,
	handleSecundaryCtaClick
}) {
	return (
		<>
			<Card className={className}>
				<CardHeader>
					{ options && (
						<div className="float-right">
							<InputGroup>
								{ options.length > 0 && (
									<>
										{ prefix && (
											<InputGroup.Text>
												{prefix}
											</InputGroup.Text>
										)}
										<Form.Control 
											as="select" 
											className="bg-light"
											value={filterType}
											onChange={e => setFilterType(e.target.value)}
										>
											{options.map((option,i) => 
												<option key={i} value={option.value}>{option.label}</option>
											)}
										</Form.Control>
										<Form.Control 
											placeholder="Escriba aquí" 
											value={filterValue}
											onChange={e => setFilterValue(e.target.value)}
										/>
										<Button color={ctaText ? 'dark' : 'primary'} onClick={reload}>Buscar</Button>
									</>
								)}
								{ secundaryCtaText && (
									<Button className="ml-5" color="primary" onClick={handleSecundaryCtaClick}>{secundaryCtaText}</Button>
								)}
								{ ctaText && (
									<Button className="ml-5" color="primary" onClick={handleCtaClick}>{ctaText}</Button>
								)}
								{ onExport && (
									<Button className="ml-5" color="primary" onClick={onExport}>Exportar</Button>
								)}
							</InputGroup>
							{ scopes && (
								<InputGroup className='mt-0 mb-0' style={{ maxWidth: '450px', float: 'right' }}>
									<Form.Control 
										disabled 
										value={scopeLabel ? scopeLabel : 'Estado'} 
										style={{ maxWidth:'140px' }} 
										className="bg-light"
									/>
									<Form.Control
										as="select"
										value={filterScope}
										onChange={e => {
											setFilterScope(e.target.value)
											reload()
										}}
									>
										<option value="">:: Ver todos los registros ::</option>
										{ scopes.map(scope => (
											<option key={scope.value} value={scope.value}>{scope.label}</option>
										)) }
									</Form.Control>
								</InputGroup>
							)}
						</div>
					)}
					<div className={ scopes ? 'mt-10' : 'mt-0'  }>
						{ title && <CardTitle>{title}</CardTitle> }
						{ subtitle && <CardSubtitle>{subtitle}</CardSubtitle> }
					</div>
				</CardHeader>
			</Card>
		</>
	)
}