import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import NewFormatVersionForm from './partials/NewFormatVersionForm'
import FormatVersionsTable from './partials/FormatVersionsTable'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getFormatVersions } from './services'
import { handleError } from '../../helpers'

export default function FormatVersionsList() {
	const { isConnected } = useSelector(state => state.offline)
	const [formatVersions, setFormatVersions] = useState(null)
	const [filterType, setFilterType] = useState('keyword')
	const [filterValue, setFilterValue] = useState('')

	useEffect(()=>{
		!formatVersions && isConnected && getFormatVersions({
			include: '',
			[`filter[${filterType}]`]: filterValue
		})
			.then(res => setFormatVersions(res.data.data))
			.catch(error => handleError(error))
	}, [formatVersions, filterType, filterValue, isConnected])

	if(!isConnected) return <InternetRequired />

	return (
		<>
			<Row>
				<Col md="3">
					<Card>
						<CardHeader>
							<CardTitle>Agregar Versión de Formato</CardTitle>
							<CardSubtitle>Ingrese los datos</CardSubtitle>
						</CardHeader>
						<CardBody>
							<NewFormatVersionForm reload={()=>setFormatVersions(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col md="9">
					<ListTopBar 
						filterType={filterType}
						setFilterType={setFilterType}
						filterValue={filterValue}
						setFilterValue={setFilterValue}
						title="Versiones de Formatos"
						subtitle="Utilizados en plataforma Dewar"
						reload={() => setFormatVersions(null)}
						options={[
							{ label: 'Buscar por formato', value: 'keyword' },
							{ label: 'Buscar por código', value: 'sku' },
						]}
					/>
					<Card>
						<CardBody>
							<FormatVersionsTable
								formatVersions={formatVersions}
								reload={()=>setFormatVersions(null)}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>			
		</>
	)
}