import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import ProposalsTable from './partials/ProposalsTable'
import ProposalListScopes from './partials/ProposalListScopes'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getProposals } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function ProposalsList() {
	const { isConnected } = useSelector(state => state.offline)
	const [proposals, setProposals] = useState(null)
	const [filterType, setFilterType] = useState('inquiry.branchSpec.branch.company.name')
	const [filterValue, setFilterValue] = useState('')
	const [filterBusinessScope, setFilterBusinessScope] = useState('')
	const [filterStatusScope, setFilterStatusScope] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const history = useHistory()

	useEffect(() => {
		!proposals && isConnected && getProposals({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			'filter[formatVersion.business_scope]': filterBusinessScope,
			[`filter[${filterStatusScope}]`]: filterStatusScope ? 1 : null,
			include: 'inquiry,preInvoices.invoiceAttachment,inquiry.branchSpec.branch',
			sort: '-created_at'
		})
			.then(res => {
				setProposals(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [proposals, filterType, filterValue, isConnected, pagination, filterBusinessScope, filterStatusScope])

	if(!isConnected) return <InternetRequired />

	return (
		<>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title="Propuestas Comerciales"
				reload={() => setProposals(null)}
				options={[
					{ label: 'Buscar por empresa', value: 'inquiry.branchSpec.branch.company.name' },
					{ label: 'Buscar por nombre instalación', value: 'inquiry.branchSpec.branch.name' },
					{ label: 'Buscar por número de consecutivo', value: 'sku_key' },
					{ label: 'Buscar por año de consecutivo (YYYY)', value: 'elaborated_at' },
					{ label: 'Buscar por fecha de elaboración (YYYY-MM-DD)', value: 'elaborated_at' },
					{ label: 'Buscar por departamento', value: 'inquiry.branchSpec.branch.address_state' },
					{ label: 'Buscar por ciudad/municipio', value: 'inquiry.branchSpec.branch.address_city' },
				]}
				ctaText="Solicitudes de Servicio"
				handleCtaClick={() => history.push('/inquiries')}
				className="mb-0"
			/>
			<Card className='list-top-bar-extra-filters'>
				<CardBody className='p-5 pr-15'>
					<ProposalListScopes 
						filterBusinessScope={filterBusinessScope}
						setFilterBusinessScope={setFilterBusinessScope}
						filterStatusScope={filterStatusScope}
						setFilterStatusScope={setFilterStatusScope}
						reload={() => setProposals(null)}
					/>
				</CardBody>
			</Card>
			<Card>
				<CardBody>
					<ProposalsTable
						proposals={proposals}
						reload={() => setProposals(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</>
	)
}