import React from 'react'
import { Descriptions } from 'antd'

const { Item } = Descriptions

export default function BranchSpecDetails({ branchSpec }) {
	return (
		<>
			<Descriptions bordered size="small" className="mt-10 mb-10" column={2}>
				<Item label="Nombre Propietario:">
					{branchSpec && branchSpec.owner_name}
				</Item>
				<Item label="Documento Propietario:">
					{branchSpec && branchSpec.owner_document}
				</Item>
				<Item label="Nombre Operador:">
					{branchSpec && branchSpec.operator_name}
				</Item>
				<Item label="Documento Operador:">
					{branchSpec && branchSpec.operator_document}
				</Item>
			</Descriptions>
		</>
	)
}