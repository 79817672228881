import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import PlanTaskForm from './PlanTaskForm'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { updatePlanTask } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function EditPlanTaskModal({ visible, onClose, planTask, reload }) {
	const { register, handleSubmit, formState: { errors } } = useForm()

	const onSubmit = values => {
		updatePlanTask(planTask.id, values)
			.then(res => handleSuccess(res.data.message, reload, onClose))
			.catch(error => handleError(error))
	}

	if(!can('Actualizar actividades de plan de inspección'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar actividades" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Actualizar Requisito / Actividad por Evaluar</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<PlanTaskForm register={register} errors={errors} planTask={planTask} />
						<Button color="primary" type="submit">Actualizar Requisito / Actividad</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}