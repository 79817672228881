import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Spinner } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import ServiceInspectorForm from './ServiceInspectorForm'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { updateServiceInspector } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function EditServiceInspectorModal({ visible, onClose, service, reload }) {
	const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({
		defaultValues: {
			service_value: service.service_inspector.service_value,
			test_details: service.service_inspector.test_details,
			observations: service.service_inspector.observations,
		}
	})
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		updateServiceInspector(service.service_inspector.id, values)
			.then(res => handleSuccess(res.data.message, reload, onClose))
			.catch(error => handleError(error, setLoading))
	}

	if(!can('Actualizar inspectores de servicios'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar asignaciones" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Actualizar Asignación de Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ServiceInspectorForm 
							register={register} 
							errors={errors}
							watch={watch}
							setValue={setValue}
							businessScope={service.business_scope}
							editable
						/>
						<Button color="primary" type='submit' className='mt-10' disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Actualizar Asignación
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}