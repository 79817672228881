import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import CompanyForm from './CompanyForm'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storeCompany } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function NewCompanyModal({ visible, onClose, reload }) {
	const { register, handleSubmit, formState: { errors }, watch, setError } = useForm()
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {

		if((values.document[0] === '8' || values.document[0] === '9') && values.document.length !== 9) {
			setError('document', { message: 'El NIT debe tener 9 dígitos' })
			return message.warning('El NIT debe tener 9 dígitos')
		}

		setLoading(true)
		storeCompany(values)
			.then(res => handleSuccess(res.data.message, reload, onClose))
			.catch(error => handleError(error, setLoading))
	}

	if(!can('Crear empresas'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear empresas" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Agregar Empresa</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<CompanyForm register={register} errors={errors} watch={watch} />
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Agregar
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}