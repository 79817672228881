import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { message } from 'antd'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import FormatVersionSelect from '../FormatVersions/FormatVersionSelect'
import InternetRequired from '../Offline/partials/InternetRequired'
import PermissionIsRequired from '../../components/PermissionIsRequired'

import { offlineUpdateReview } from '../Services/actions'
import { queueRequest } from '../Offline/actions'
import { storeImpartialityNotice, updateImpartialityNotice } from './services'
import { can, handleError, handleSuccess } from '../../helpers'

export default function ReviewImpartialityNoticeModal({ visible, onClose, serviceReview, serviceId, reload }) {
	const { isConnected } = useSelector(state => state.offline)
	const { register, handleSubmit, formState: { errors }, setValue } = useForm()
	const dispatch = useDispatch()

	const onSubmit = values => {
		if(!serviceReview.impartiality_notice){
			storeImpartialityNotice({ ...values, service_review_id: serviceReview.id })
				.then(res => handleSuccess(res.data.message, reload))
				.catch(error => handleError(error))
		} else if(isConnected){
			updateImpartialityNotice(serviceReview.impartiality_notice.id, values)
				.then(res => handleSuccess(res.data.message, reload))
				.catch(error => handleError(error))
		} else {
			dispatch(offlineUpdateReview({ 
				...serviceReview, 
				impartiality_notice: {
					...serviceReview.impartiality_notice,
					...values
				}
			}, serviceId))
			dispatch(queueRequest({ 
				method: 'PUT', 
				url: `/impartiality_notices/${serviceReview.impartiality_notice.id}`,
				data: values
			}))
			message.info('Actualización en espera de internet')
			onClose()
		}
	}

	if(!can('impartiality_notices.create') || !can('impartiality_notices.update'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear y actualizar ratificaciones" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Ratificación Imparcialidad e Independencia</ModalHeader>
				<ModalBody>
					{ (isConnected || serviceReview.impartiality_notice) ? (
						<Form onSubmit={handleSubmit(onSubmit)}>
							<Form.Group>
								<Form.Label>Version <span className='text-danger'>*</span></Form.Label>
								{ isConnected ? (
									<FormatVersionSelect 
										keyword="impartialityNotice"
										setValue={setValue}
										defaultValue={serviceReview.impartiality_notice && serviceReview.impartiality_notice.format_version.sku}
									/>
								) : (
									<Form.Control 
										disabled
										defaultValue={serviceReview.impartiality_notice && serviceReview.impartiality_notice.format_version.sku}
									/>
								)}
								{ errors.format_version_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group>
								<Form.Label>Fecha Vigencia <span className='text-danger'>*</span></Form.Label>
								<Form.Control 
									disabled
									defaultValue={serviceReview.impartiality_notice && serviceReview.impartiality_notice.format_version.expire_at}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Nombre Empresa <span className='text-danger'>*</span></Form.Label>
								<Form.Control 
									{...register('inspector_name', { required: true })}
									placeholder='Escriba aquí...'
									defaultValue={serviceReview.impartiality_notice && serviceReview.impartiality_notice.inspector_name}
								/>
								{ errors.inspector_name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
							<Button color="primary" type="submit" outline className='mt-20'>
								Actualizar Formato
							</Button>
						</Form>
					) : (
						<InternetRequired />
					)}
				</ModalBody>
			</Modal>
		</>
	)
}