import dewarApi from '../../services/dewarApi'

export const getRoles = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/roles', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getRole = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/roles/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeRole = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/roles', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateRole = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/roles/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteRole = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/roles/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const syncPermissionsRole = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/roles/${id}/sync_permissions`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getPermissions = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/permissions', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)