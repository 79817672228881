import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'

import DecisionNoticeForm from './DecisionNoticeForm'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storeDecisionNotice } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function NewDecisionNoticeModal({ visible, onClose, serviceVeredict, businessScope, noCompliant, reload }) {
	const { register, handleSubmit, formState: { errors }, setValue } = useForm()
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		storeDecisionNotice({ service_veredict_id: serviceVeredict.id, ...values })
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error, setLoading))
	}

	if(!can('Crear comunidados de decisión'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear comunicados de decisión" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Emitir Comunicado Decisión Proceso de Inspección de Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<DecisionNoticeForm 
							register={register}
							errors={errors}
							setValue={setValue}
							businessScope={businessScope}
							noCompliant={noCompliant}
						/>
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Generar Comunicado
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}