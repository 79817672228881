import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import NonCompliantPreviewOffline from './NonCompliantPreviewOffline'
import ServiceProfileOffline from './ServiceProfileOffline'
import ServiceReviewPreviewOffline from './ServiceReviewPreviewOffline'
import ServicesListOffline from './ServicesListOffline'
import ReviewLogbookPreviewOffline from './ReviewLogbookPreviewOffline'

export default function Offline() {
	return (
		<>
			<Switch>
				<Route exact path="/offline/services/inspeccion" render={props => <ServicesListOffline {...props} businessScope='inspeccion' />} />
				<Route exact path="/offline/services/pruebas" render={props => <ServicesListOffline {...props} businessScope='pruebas' />} />
				<Route exact path="/offline/services/:id" component={ServiceProfileOffline} />
				<Route 
					exact 
					path="/offline/service_reviews/preview/:serviceId/:reviewId" 
					component={ServiceReviewPreviewOffline} 
				/>
				<Route 
					exact 
					path="/offline/non_compliant_reports/preview/:serviceId/:id" 
					component={NonCompliantPreviewOffline} 
				/>
				<Route 
					exact 
					path="/offline/review_logbooks/preview/:serviceId/:id" 
					component={ReviewLogbookPreviewOffline} 
				/>

				<Redirect to="/404" />
			</Switch>
		</>
	)
}