import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, InputGroup, Form } from 'react-bootstrap'
import { Spinner } from 'reactstrap'

import NewTankModal from './partials/NewTankModal'
import TanksTable from './partials/TanksTable'
import InternetRequired from '../Offline/partials/InternetRequired'
import PermissionIsRequired from '../../components/PermissionIsRequired'

import { getTanks } from './services'
import { can, handleError } from '../../helpers'

export default function BranchTanksList({ branchSpecId }) {
	const { isConnected } = useSelector(state => state.offline)
	const [tanks, setTanks] = useState(null)
	const [showNewModal, setShowNewModal] = useState(null)

	useEffect(() => {
		!tanks && isConnected && getTanks({ 'filter[branch_spec_id]': branchSpecId })
			.then(res => setTanks(res.data.data))
			.catch(error => handleError(error))
	}, [ tanks, branchSpecId, isConnected ])
	
	const sumTotal = (type) => {
		let total = 0
		for (let i = 0; i < tanks.length; i++) {
			if(tanks[i].type === type && tanks[i].should_sum)
				total++
		}
		return total
	}

	if(!isConnected) return <InternetRequired />

	if(!can('Ver listado de Tanques/Tuberias')) 
		return <PermissionIsRequired title="No tiene permisos para ver los tanques" />

	if(!tanks) return <Spinner size="sm" />

	return (
		<>
			<Row>
				<InputGroup as={Col}>
					<InputGroup.Text>Total tanques enterrados:</InputGroup.Text>
					<Form.Control value={sumTotal('enterrado')} disabled/>
				</InputGroup>
				<InputGroup as={Col}>
					<InputGroup.Text>Total tanques superficiales:</InputGroup.Text>
					<Form.Control value={sumTotal('superficial')} disabled/>
				</InputGroup>
				<InputGroup as={Col}>
					<InputGroup.Text>Total tanques:</InputGroup.Text>
					<Form.Control value={parseInt(sumTotal('enterrado')) + parseInt(sumTotal('superficial'))} disabled/>
				</InputGroup>
			</Row>
			<TanksTable tanks={tanks} reload={() => setTanks(null)} />
			<p className="text-link" onClick={() => setShowNewModal(true)}>
				<FontAwesomeIcon icon={faPlus} />{" "}Agregar tanque nuevo
			</p>
			{ showNewModal && (
				<NewTankModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => setTanks(null)}
					branchSpecId={branchSpecId}
				/>
			)}
		</>
	)
}