import React from 'react'
import { Link } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { Divider, Result } from 'antd'
import moment from 'moment'

import UploadAcceptanceAttachment from '../UploadAcceptanceAttachment'

import { updateProposal } from '../../services'
import { handleError, handleSuccess } from '../../../../helpers'

export default function ReplaceAcceptanceForm({ proposal, reload }) {

    const onResubmit = newAttachmentUri => {		
		updateProposal(proposal.id, {
			accept_attachment_uri: newAttachmentUri
		})
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

    return (
        <>
            <Result
                status="success" 
                title={`Propuesta aceptada el ${moment(proposal.accepted_at).format('YYYY-MM-DD hh:mm a')}`} 
                extra={(
                    <>
                        <Link to={`/proposals/acceptance_preview/${proposal.id}`}>
                            <Button color="primary">Ver Aceptación</Button>
                        </Link>
                        { proposal.accept_attachment_uri && (
                            <>
                                <Divider type="vertical" />
                                <a href={`${proposal.accept_attachment_uri}?cache=${Date.now()}`}target="_blank" rel="noreferrer">
                                    <Button color="primary">Ver Adjunto</Button>
                                </a>
                                <Divider type="horizontal" />
                                <Form.Label>Reemplazar Soporte de Aceptación</Form.Label>
                                <Form.Text className='text-muted'>Esta acción no se puede revertir. Se perderá por completo el archivo anterior, descarguelo primero en caso de requerir copia del mismo.</Form.Text>
                                <UploadAcceptanceAttachment
                                    setAttachmentUri={onResubmit}
                                    proposalId={proposal.id}
                                />
                            </>
                        )}
                    </>
                )}
            />
        </>
    )
}