import React from 'react'
import { Link } from 'react-router-dom'
import { Empty, Table } from 'antd'
import moment from 'moment'

import PermissionIsRequired from '../../../../components/PermissionIsRequired'
import { can } from '../../../../helpers'

export default function ProposalServicesTab({ proposal }) {

    const columns = [
        {
			title: 'ID',
			dataIndex: 'id',
			render: t => <Link to={`/services/${t}`}>#{t}</Link>
		},
		{
			title: 'Fecha de Inicio',
			dataIndex: 'start_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase(),
			width: '120px'
		},
        {
            title: 'Servicio',
            dataIndex: 'proposal',
            render: t => t.format_version.business_scope === 'pruebas' ? 'Servicio de Pruebas' : 'Servicio de Inspección'
        },
		{
			title: 'Tipo',
			dataIndex: 'type',
			hidden: proposal.format_version.business_scope === 'pruebas'
		},
        {
            title: 'Tipo',
            dataIndex: 'initial_service_id',
            render: t => t ? 'Reprueba' : 'Inicial'
        },
		{
			title: 'Inspector',
			dataIndex: 'service_inspector',
			render: t => t ? t.user_name : <em>Sin inspector asignado</em>
		}
    ].filter(item => !item.hidden)

    if(!can('Ver servicio asociados a la propuesta'))
        return <PermissionIsRequired title="No tiene permisos para ver servicio asociados a la propuesta" />

    return (
        <>
            <p className='bold mt-20'>Servicios Asociados</p>
            <Table 
                dataSource={proposal.services}
                rowKey={r => r.id}
                columns={columns}
                loading={!proposal}
                locale={{ emptyText: <Empty description="No tiene servicios asociados" /> }}
                size="small"
            />
        </>
    )
}