import React from 'react'
import { Form } from 'react-bootstrap'

export default function ReviewLogbookDirectorVeredictForm({ register, errors, watch, specType }) {
    return (
        <>
            <Form.Group>
                <Form.Label>Presión de la Prueba / PSI <span className='text-primary'>*</span></Form.Label>
                <Form.Control
                    {...register('requirements_survey.director_psi_presion', { required: true })}
                    placeholder='Escriba aquí...'
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Revisión y Decisión del Director Técnico <span className='text-primary'>*</span></Form.Label>
                <Form.Control
                    as="select"
                    {...register('requirements_survey.is_director_compliant', { required: true })}
                >
                    <option value="">:: Selecciona una opción ::</option>
                    <option>Conforme</option>
                    <option>No Conforme</option>
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>Observaciones del Director Técnico <small><em>(Opcional)</em></small></Form.Label>
                <Form.Control
                    as="textarea"
                    {...register('requirements_survey.director_observations', { })}
                    placeholder='Escriba aquí...'
                    style={{ height: '100px' }}
                />
            </Form.Group>
        </>
    )
}