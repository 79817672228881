import React, { useState } from 'react'
import { Card, CardBody } from 'reactstrap'

import IntroStep from './partials/wizard_steps/IntroStep'
import CompanyStep from './partials/wizard_steps/CompanyStep'
import BranchStep from './partials/wizard_steps/BranchStep'
import EmployeeStep from './partials/wizard_steps/EmployeeStep'
import PastCertificationStep from './partials/wizard_steps/PastCertificationStep'
import TypeAndTanksStep from './partials/wizard_steps/TypeAndTanksStep'
import FinalDetailsStep from './partials/wizard_steps/FinalDetailsStep'
import TestDetailsStep from './partials/wizard_steps/TestDetailsStep'

export default function InquiryWizard() {
	const [currentStep, setCurrentStep] = useState(0)
	const [form, setForm] = useState({
		sku_key: '',
		sku_number: '',
		version: '',
		expire_at: '',
		has_previous_certification: 0,
		previous_competitor: '',
		previous_start_at: '',
		previous_end_at: '',
		client_requirements: '',
		invoice_requirements: '',
		legal_reference: 'Resolución No. 40198 del 24 de junio de 2021, “Por la cual se modifica la Resolución No. 40405 del 24 de diciembre de 2020 “Por la cual se expide el reglamento técnico aplicable a las Estaciones de Servicio, Plantas de Abastecimiento, Instalaciones del Gran Consumidor con Instalación Fija y Tanques de Almacenamiento del consumidor final, que sean nuevos o existentes, que almacenen biocombustibles, crudos y/o combustibles líquidos derivados del petróleo, y sus mezclas de los mismos con biocombustibles, excepto GLP”, y de la Resolución No. 40405 del 24 de diciembre de 2020, expedidas por Ministerio de Minas y Energía.',
		observations: '',
		sent_by: '',
		sent_by_mobile: '',
		sent_at: '',
		branch_spec: {
			owner_name: '',
			owner_document: '',
			operator_name: '',
			operator_document: '',
			total_tanques_enterrados: 0,
			total_tanques_superficiales: 0,
			total_islas: 0,
			total_mangueras: 0,
			total_surtidores: 0,
			tipo_llenado: '',
			total_brazos: 0,
			total_islas_recepcion: 0,
			total_lineas_recepcion: 0,
			total_recintos: 0,
		},
		test_details: {
			total_tanks: '',
			aprox_altitude: '',
			valve_present: 'No',
			last_test_at: '',
			service_name: ''
		}
	})

	const stepProps = { form, setForm, currentStep, setCurrentStep }

	const steps = [
		{
			title: 'SOLICITUD DE SERVICIO NUEVO',
			render: <IntroStep {...stepProps} />
		},
		{
			title: 'INFORMACIÓN DE LA EMPRESA QUE SOLICITA EL SERVICIO',
			render: <CompanyStep {...stepProps} />
		},
		{
			title: 'INSTALACIÓN A INSPECCIONAR',
			render: <BranchStep {...stepProps} />
		},
		{
			title: 'PERSONA RESPONSABLE DE LA INSPECCIÓN EN LA INSTALACIÓN',
			render: <EmployeeStep {...stepProps} />
		},
		{
			title: 'INFORMACIÓN CERTIFICACIÓN',
			render: <PastCertificationStep {...stepProps} />
		},
		{
			title: 'DETALLES DE INSTALACIÓN A INSPECCIONAR',
			render: <TypeAndTanksStep {...stepProps} />
		},
		{
			title: 'FINALIZACIÓN DE SOLICITUD',
			render: <FinalDetailsStep {...stepProps} />
		},
		{
			title: 'DETALLES DE SERVICIO DE PRUEBAS',
			render: <TestDetailsStep {...stepProps} />
		},
	]

	return (
		<>
			<Card>
				<CardBody>
					<h5 className="mb-0">{steps[currentStep].title}</h5>
					<p className="mb-0">Paso {currentStep + 1} de 8</p>
				</CardBody>
			</Card>
			<Card>
				<CardBody>
					{ steps[currentStep].render }
				</CardBody>
			</Card>
		</>
	)
}