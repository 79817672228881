import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { message, Spin } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons'

import { BottomBar } from '../../../../components/WizardComponents'
import ServerSideSelect from '../../../../components/ServerSideSelect'

import BranchDetails from '../../../Branches/partials/BranchDetails'
import NewBranchModal from '../../../Branches/partials/NewBranchModal'
import EditBranchModal from '../../../Branches/partials/EditBranchModal'
import BranchSpecWizardForm from '../../../BranchSpecs/partials/BranchSpecWizardForm'

import { getBranch, searchActiveBranches } from '../../../Branches/services'
import { storeBranchSpec, updateBranchSpec } from '../../../BranchSpecs/services'
import { handleError } from '../../../../helpers'

export default function BranchStep({ form, setForm, currentStep, setCurrentStep }) {
	const [branch, setBranch] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)
	const [selectedBranch, setSelectedBranch] = useState( form.branch_id ? {
		value: form.branch_id, label: form.branch_label
	} : [])

	useEffect(() => {
		selectedBranch.value && !branch && getBranch(selectedBranch.value, {
			include: 'company,instalationType'
		})	
			.then(res => {
				let newBranch = res.data.data
				let currentSpec = form.branch_spec
				setBranch(newBranch)
				setForm({
					...form,
					branch_spec: {
						...currentSpec,
						owner_name: currentSpec.owner_name ? currentSpec.owner_name : newBranch.company.name,
						owner_document: currentSpec.owner_document ? currentSpec.owner_document : newBranch.company.complete_document,
						operator_name: currentSpec.operator_name ? currentSpec.operator_name : newBranch.company.name,
						operator_document: currentSpec.operator_document ? currentSpec.operator_document : newBranch.company.complete_document,
					},
					instalation_type: { ...newBranch.instalation_type }
				})
			})
			.catch(error => handleError(error))
	}, [ selectedBranch, branch, setForm, form ])

	const handleBackStep = () => {
		setCurrentStep(currentStep-1)
	}

	const handleNextStep = () => {
		if(!form.branch_id) return message.warning('Debe seleccionar una instalación')
		if(!form.branch_spec.owner_name) return message.warning('Debe ingresar el nombre del propietario')
		if(!form.branch_spec.owner_document) return message.warning('Debe ingresar el nit del propietario')
		if(!form.branch_spec.operator_name) return message.warning('Debe ingresar el nombre del operador')
		if(!form.branch_spec.operator_document) return message.warning('Debe ingresar el nit del operador')

		!form.branch_spec_id ? (
			storeBranchSpec({ ...form.branch_spec, branch_id: form.branch_id})
				.then(res => {
					setForm({ ...form, branch_spec_id: res.data.data.id })
					setCurrentStep(currentStep+1)
				})
				.catch(error => handleError(error))
		) : (
			updateBranchSpec(form.branch_spec_id, { ...form.branch_spec })
				.then(res => setCurrentStep(currentStep+1))
				.catch(error => handleError(error))
		)
	}

	return (
		<>
			<Form.Group>
				<Form.Label>INSTALACIÓN A INSPECCIONAR</Form.Label>
				<ServerSideSelect 
					value={selectedBranch}
					placeholder="Buscar por nombre"
					fetchOptions={searchActiveBranches}
					onChange={val => {
						setSelectedBranch(val)
						setForm({ ...form, branch_id: val.value, branch_label: val.label })
						setBranch(null)
					}}
					className="full-width mb-10"
					scopeId={form.company_id}
				/>
				<Form.Text className="text-link" onClick={() => setShowNewModal(true)}>
					<FontAwesomeIcon icon={faPlus} />{" "}Agregar instalación nueva
				</Form.Text>
			</Form.Group>
			<Spin spinning={(!branch && selectedBranch.value) ? true : false}>
				<BranchDetails branch={branch} />
				{ branch && ( 
					<>
						<Form.Text className="text-link" onClick={() => setShowEditModal(true)}>
							<FontAwesomeIcon icon={faEdit} />{" "}Actualizar datos de la instalación
						</Form.Text>
						<BranchSpecWizardForm 
							form={form}
							setForm={setForm}
							branch={branch}
						/>
					</>
				)}
			</Spin>
			<BottomBar handleNextStep={handleNextStep} handleBackStep={handleBackStep} />
			{ showNewModal && (
				<NewBranchModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => {
						setSelectedBranch([])
						setBranch(null)
					}}
					companyId={form.company_id}
				/>
			)}
			{ showEditModal && (
				<EditBranchModal 
					visible
					onClose={() => setShowEditModal(false)}
					branch={branch}
					reload={() => setBranch(null)}
				/>
			)}
		</>
	)
} 