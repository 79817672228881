import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import ImpartialityNoticePreview from './ImpartialityNoticePreview'

export default function ImpartialityNotices() {
	return (
		<>
			<Switch>
				<Route exact path="/impartiality_notices/preview/:serviceReviewId" component={ImpartialityNoticePreview} />

				<Redirect to="/404" />
			</Switch>
		</>
	)
}