import dewarApi from '../../services/dewarApi'

export const getAttachments = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/attachments', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getAttachment = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/attachments/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeAttachment = data => (
	new Promise((resolve, reject) => {
		dewarApi('multipart/form-data')
			.post('/attachments', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateAttachment = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/attachments/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteAttachment = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/attachments/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)