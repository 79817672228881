import React from 'react'
import { Descriptions } from 'antd'

import { is_positive, text } from '../../../../helpers'

const { Item } = Descriptions

export default function ReviewLogbookSpecDetailsTable({ reviewLogbook }) {
    switch (reviewLogbook.spec_type) {
        case 'tank':
            return (
                <>
                    <Descriptions bordered size='small' column={3} className='mb-10'>
                        <Item label="Fecha de Inspección">{reviewLogbook.register_at}</Item>
                        <Item label="Nº Tanque">{reviewLogbook.spec_number}</Item>
                        <Item label="Identificación del tanque">{text(reviewLogbook.spec_details.identificacion_tanque)}</Item>
                        <Item label="Capacidad (gal)">{text(reviewLogbook.spec_details.capacidad)}</Item>
                        <Item label="Producto">{reviewLogbook.product_name !== 'Otro' ? reviewLogbook.product_name : reviewLogbook.product_other_name}</Item>
                        <Item label="Tipo">{reviewLogbook.spec_category_slug}</Item>
                        <Item label="Material">{text(reviewLogbook.spec_details.material)}</Item>
                        <Item label="Nivel de llenado (gal)">{text(reviewLogbook.spec_details.nivel_llenado)}</Item>
                        <Item label="Diámetro de tanque (m)">{text(reviewLogbook.spec_details.diametro_tanque)}</Item>
                        <Item label="Compartido">{text(reviewLogbook.spec_details.bicompartido)}</Item>
                        <Item label="Bypass">{text(reviewLogbook.spec_details.bypass)}</Item>
                        <Item label="Tiene sifón">{text(reviewLogbook.spec_details.tiene_sifon)}</Item>
                        <Item label="Doble contención">{text(reviewLogbook.spec_details.doble_contencion)}</Item>
                        { is_positive(reviewLogbook.spec_details.doble_contencion) && (
                            <>
                                <Item label="Hay contaminación por olor ó existe traza de combustible en el Intersticio" labelStyle={{ width: '250px'}}>{text(reviewLogbook.spec_details.olor_hc)}</Item>
                                <Item label="Espacio Anular">{text(reviewLogbook.spec_details.espacio_anular)}</Item>
                                <Item label="Nivel de Salmuera Inicial (cm)">{text(reviewLogbook.spec_details.salmuera_inicial)}</Item>
                                <Item label="Nivel de Salmuera Final (cm)">{text(reviewLogbook.spec_details.salmuera_final)}</Item>
                            </>
                        )}
                        <Item label="No. De bocas">{text(reviewLogbook.spec_details.numero_bocas)}</Item>
                        <Item label="Diámetro de bocas (pulg)">{text(reviewLogbook.spec_details.diametro_bocas)}</Item>
                        <Item label="Diámetro de manhole (pulg)">{text(reviewLogbook.spec_details.diametro_manhole)}</Item>
                    </Descriptions>
                </>
            )

        case 'pipeline':
            return (
                <>
                    <Descriptions bordered size='small' column={3} className='mb-10'>
                        <Item label="Fecha de Inspección">{reviewLogbook.register_at}</Item>
                        <Item label="Nº Tubería">{reviewLogbook.spec_number}</Item>
                        <Item label="Producto">{reviewLogbook.product_name !== 'Otro' ? reviewLogbook.product_name : reviewLogbook.product_other_name}</Item>
                        <Item label="Desde el tanque">{text(reviewLogbook.spec_details.desde_tanque)}</Item>
                        <Item label="Hacia equipo">{text(reviewLogbook.spec_details.hacia)}</Item>
                        <Item label="Isla de llenado">{text(reviewLogbook.spec_details.isla_llenado)}</Item>
                        <Item label="Equipo">{reviewLogbook.spec_category_slug}</Item>
                    </Descriptions>
                </>
            )
    
        default:
            return;
    }
}