import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

export default function TaskTemplateForm({ register, errors, onlyEditable = false }) {
	return (
		<>
			{ !onlyEditable && (
				<>
					<Form.Group>
						<Form.Label>Ítem</Form.Label>
						<Form.Control 
							{...register('sku', { required: true })}
							placeholder='Escriba aquí...'
						/>
						{ errors.sku && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Artículo</Form.Label>
						<Form.Control 
							{...register('article', { required: true })}
							placeholder='Escriba aquí...'
							as="textarea"
							style={{ height: '50px' }}
						/>
						{ errors.article && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Descripción General (Resolución)</Form.Label>
						<Form.Control 
							{...register('legal_description', { required: true })}
							placeholder='Escriba aquí...'
							as="textarea"
							style={{ height: '50px' }}
						/>
						{ errors.legal_description && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Aplicación para Instalaciones</Form.Label>
						<Form.Control
							as="select"
							{...register('instalation_period')}
						>
							<option>Antiguas</option>
							<option>Nuevas</option>
							<option>Modificadas o ampliadas - Almacenamiento</option>
							<option>Modificadas o ampliadas - Abastecimiento</option>
							<option>Todas</option>
						</Form.Control>
					</Form.Group>
				</>
			)}
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Tipo</Form.Label>
					<Form.Control
						as="select"
						{...register('type')}
					>
						<option>Todas</option>
						<option>Enterrados</option>
						<option>Superficiales</option>
					</Form.Control>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Limite Tanques Superficiales</Form.Label>
					<Form.Control
						as="select"
						{...register('superficial_limit')}
					>
						<option value="Menos/Más">Aplica para todas</option>
						<option>Menos de 150.000 l</option>
						<option>Más de 150.000 l</option>
					</Form.Control>
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Característica Minminas</Form.Label>
				<Form.Control 
					{...register('minminas_criteria', { required: true })}
					placeholder='Escriba aquí...'
					as="textarea"
					style={{ height: '50px' }}
				/>
				{ errors.minminas_criteria && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Evidencia Mminas</Form.Label>
				<Form.Control 
					{...register('general_criteria', { required: true })}
					placeholder='Escriba aquí...'
					as="textarea"
					style={{ height: '50px' }}
				/>
				{ errors.general_criteria && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Plazo <small>(opcional)</small></Form.Label>
				<Form.Control 
					{...register('description', {})}
					placeholder='Escriba aquí...'
					as="textarea"
					style={{ height: '50px' }}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Tipo de Tarea <span className='text-danger'>*</span></Form.Label>
				<Form.Control
					as="select"
					{...register('task_type', { required:true })}
				>
					<option>Técnica</option>
					<option>Documental</option>
				</Form.Control>
			</Form.Group>
			<Form.Group>
				<Form.Label>Comentario Inspector Cuando es Conforme</Form.Label>
				<Form.Control 
					{...register('resolved_placeholder', { required: true })}
					placeholder='Escriba aquí...'
					as="textarea"
					style={{ height: '50px' }}
				/>
				{ errors.resolved_placeholder && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Comentario Inspector Cuando es No Conforme</Form.Label>
				<Form.Control 
					{...register('no_resolved_placeholder', { required: true })}
					placeholder='Escriba aquí...'
					as="textarea"
					style={{ height: '50px' }}
				/>
				{ errors.no_resolved_placeholder && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Comentario Inspector Cuando es No Aplica</Form.Label>
				<Form.Control 
					{...register('no_apply_placeholder', { required: true })}
					placeholder='Escriba aquí...'
					as="textarea"
					style={{ height: '50px' }}
				/>
				{ errors.no_apply_placeholder && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</>
	)
}