import React from "react"

import { useSelector } from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"

import Authentication from './containers/Authentication/'
import Branches from "./containers/Branches"
import Certificates from "./containers/Certificates"
import Companies from "./containers/Companies"
import DecisionNotices from "./containers/DecisionNotices"
import EmailLogs from "./containers/EmailLogs"
import Error404 from './containers/ErrorsPages/Error404'
import ImpartialityNotices from "./containers/ImpartialityNotice"
import Inquiries from "./containers/Inquiries"
import NonCompliantReports from "./containers/NonCompliantReports"
import Offline from "./containers/Offline"
import PreInvoices from "./containers/PreInvoices"
import PreviewFormats from "./containers/PreviewFormats"
import Proposals from "./containers/Proposals"
import Reports from "./containers/Reports"
import RetryNotices from "./containers/RetryNotices"
import ServiceInspectors from "./containers/ServiceInspectors"
import ServiceNotifications from "./containers/ServiceNotifications"
import ServicePlans from "./containers/ServicePlans"
import ServiceReports from "./containers/ServiceReports"
import ServiceReviews from "./containers/ServiceReviews"
import Services from "./containers/Services"
import ServiceVeredicts from "./containers/ServiceVeredicts"
import Settings from "./containers/Settings"
import SyncLogs from "./containers/SyncLogs"
import VerificationLists from "./containers/VerificationLists"
import Tickets from "./containers/Tickets"
import ReviewLogbooks from "./containers/ReviewLogbooks"

import MainLayout from "./layouts/MainLayout"

export default function App() {	
	const {token, user} = useSelector(state => state.auth)
	
	return (
		<>			
			<Switch>
				<Redirect 
					exact 
					path="/" 
					to={user && user.role.includes('pruebas') ? '/services/pruebas' : '/services/inspeccion'} 
				/>

				<Route path="/auth" component={Authentication} />
				<Route path="/vista_previa" component={PreviewFormats} />
				
				{!token && <Redirect to="/auth/login" />}

				<MainLayout>
					<Route path="/companies" component={Companies} />
					<Route path="/branches" component={Branches} />
					<Route path="/inquiries" component={Inquiries} />
					<Route path="/proposals" component={Proposals} />
					<Route path="/pre_invoices" component={PreInvoices} />
					<Route path="/services" component={Services} />
					<Route path="/service_inspectors" component={ServiceInspectors} />
					<Route path="/service_notifications" component={ServiceNotifications} />
					<Route path="/service_plans" component={ServicePlans} />
					<Route path="/service_reviews" component={ServiceReviews} />
					<Route path="/verification_lists" component={VerificationLists} />
					<Route path="/service_reports" component={ServiceReports} />
					<Route path="/service_veredicts" component={ServiceVeredicts} />
					<Route path="/impartiality_notices" component={ImpartialityNotices} />
					<Route path="/non_compliant_reports" component={NonCompliantReports} />
					<Route path="/certificates" component={Certificates} />
					<Route path="/decision_notices" component={DecisionNotices} />
					<Route path="/retry_notices" component={RetryNotices} />
					<Route path="/settings" component={Settings} />
					<Route path="/reports" component={Reports} />
					<Route path="/email_logs" component={EmailLogs} />
					<Route path="/offline" component={Offline} />
					<Route path="/sync_logs" component={SyncLogs} />
					<Route path="/tickets" component={Tickets} />
					<Route path="/review_logbooks" component={ReviewLogbooks} />
					
					<Route exact path="/404" component={Error404} />
				</MainLayout>
			</Switch>
		</>
	);	
}