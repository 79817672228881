import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Col, Row } from 'reactstrap'
import { Empty, Popconfirm, Tooltip } from 'antd'

import { deleteReportEvidence } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

export default function ReportEvidenceGrid({ reportEvidence, reload, editable }) {
	const [tooltipLabel, setTooltipLabel] = useState('Copiar')

	const copyToClipboard = link => {
		navigator.clipboard.writeText(link)

		setTooltipLabel('¡Copiado!')

		setTimeout(() => {
			setTooltipLabel('Copiar')
		}, 1000);
	}

	const handleDelete = id => {
		can('Eliminar evidencias') && deleteReportEvidence(id)
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	if(!can('Ver listado de evidencias'))
		return <PermissionIsRequired title="No tiene acceso a ver listado de evidencias del informe" />

	return (
		<>
			{ reportEvidence.length > 0 ? (
				<>
					{ reportEvidence.map( evidence => (
						<EvidenceContainer key={evidence.id}>
							<Row>
								<Col>
									<div className='bg-light text-center'>
										<EvidenceImage src={evidence.attachment_uri} />
									</div>
									<div className='p-5 bg-light small mt-5 text-muted' style={{ cursor: 'pointer' }} onClick={() => copyToClipboard(evidence.attachment_uri)}>
										<Tooltip title={tooltipLabel} placement='right'>
											{evidence.attachment_uri}
										</Tooltip>
									</div>
								</Col>
								<Col className='text-center pt-20'>
									{ can('Eliminar evidencias del informe') && (
										<Popconfirm
											title="Esta acción no se puede revertir"
											okText="Eliminar"
											cancelText="Cancelar"
											okButtonProps={{ danger: true }}
											onConfirm={() => handleDelete(evidence.id)}
										>
											<Button size="sm" color="danger" disabled={!editable} outline>Eliminar</Button>
										</Popconfirm>
									)}
								</Col>
							</Row>
						</EvidenceContainer>
					)) }
				</>
			) : (
				<Empty 
					description="No tiene registros fotográficos ingresados" 
					imageStyle={{ height: '70px' }} 
					className='mb-20'
				/>
			)}
		</>
	)
}

const EvidenceContainer = styled.div`
	margin-bottom: 15px;
`

const EvidenceImage = styled.img`
	max-height: 70px;
`