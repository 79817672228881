import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import EmailLogsTable from './partials/EmailLogsTable'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getEmailLogs } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function EmailLogsList() {
	const { isConnected } = useSelector(state => state.offline)
	const [emailLogs, setEmailLogs] = useState(null)
	const [filterType, setFilterType] = useState('to_address')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!emailLogs && isConnected && getEmailLogs({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'service.proposal.inquiry.branchSpec.branch.company',
			sort: '-created_at'
		})
			.then(res => {
				setEmailLogs(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [emailLogs, filterType, filterValue, pagination, isConnected])

	if(!isConnected) return <InternetRequired />

	return (
		<>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title="Estado de Correos Enviados"
				subtitle="Plataforma DEWAR"
				reload={() => setEmailLogs(null)}
				options={[
					{ label: 'Buscar por correo', value: 'to_address' },
					{ label: 'Buscar por tipo', value: 'type' },
					{ label: 'Buscar por id servicio', value: 'service_id' },
					{ label: 'Buscar por empresa', value: 'service.proposal.inquiry.branchSpec.branch.company.name' },
					{ label: 'Buscar por instalación', value: 'service.proposal.inquiry.branchSpec.branch.name' },
					{ label: 'Buscar por número de propuesta', value: 'service.proposal.sku_key' }
				]}
				ctaText="Refrescar"
				handleCtaClick={() => setEmailLogs(null)}
			/>
			<Card>
				<CardBody>
					<EmailLogsTable 
						emailLogs={emailLogs}
						reload={() => setEmailLogs(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</>
	)
}