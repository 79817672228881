import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Tooltip, Divider, Popconfirm, Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'reactstrap'
import moment from 'moment'

import EditInquiryModal from './modals/EditInquiryModal'
import Pagination from '../../../components/Pagination'
import CommentsIcon from '../../Comments/partials/CommentsIcon'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { deleteInquiry } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function InquiriesTable({ inquiries, reload, pagination, setPagination }) {
	const currentUser = useSelector(state => state.auth.user)
	const [editModal, setEditModal] = useState(null)

	const handleDelete = id => {
		can('Eliminar solicitudes de servicio') && deleteInquiry(id)
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}
	
	const columns = [
		{
			title: 'ID',
			dataIndex: 'sku',
			render: (t, r) => <Link to={`/inquiries/preview/${r.id}`}>{t}</Link>
		},
		{
			title: 'Fecha Solicitud',
			dataIndex: 'sent_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase()
		},
		{
			title: 'Servicio',
			dataIndex: 'format_version',
			render: t => t.business_scope_label
		},
		{
			title: 'Empresa',
			dataIndex: 'branch_spec',
			render: t => t.branch.company_name
		},
		{
			title: 'Tipo',
			dataIndex: 'branch_spec',
			render: t => t.branch.instalation_type_name
		},
		{
			title: 'Instalación',
			dataIndex: 'branch_spec',
			render: t => t.branch.name
		},
		{
			title: 'Fecha Elaboración',
			dataIndex: 'created_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase()
		},
		{
			title: 'Propuesta',
			dataIndex: 'proposals',
			render: (t, r) => currentUser.is_super_admin ? (
				<>
					{ t.map(proposal => (
						<React.Fragment key={proposal.id}>
							<Link to={`/proposals/preview/${proposal.id}`} >{proposal.sku}</Link>
							<Divider type="vertical" />
						</React.Fragment>
					)) }
					<Link to={`/proposals/wizard/${r.id}`}><em>Crear propuesta</em></Link>
				</>
			) : (
				<em className='hint-text'>Acceso restringido</em>
			),
		},
		{
			title: 'Acciones',
			width: 100,
			render: (t, r) => (
				<>
					<Tooltip title="Ver Solicitud">
						<Link to={`/inquiries/preview/${r.id}`}>
							<FontAwesomeIcon className="text-link" icon={faSearch} />
						</Link>
					</Tooltip>
					<Divider type="vertical" />
					<CommentsIcon type="inquiry" id={r.id} />
					{ r.proposals.length === 0 && (
						<>
							<Divider type="vertical" />
							<Tooltip title="Editar Solicitud">
								<span className='text-link' onClick={() => setEditModal(r)}>
									<FontAwesomeIcon icon={faEdit} />
								</span>
							</Tooltip>
						</>
					)}
					{ can('Eliminar solicitudes de servicio') && r.proposals.length === 0 && (
						<>
							<Divider type="vertical" />
							<Popconfirm 
								title="¿Desea eliminar la solicitud?"
								okText="Eliminar"
								cancelText="Cancelar"
								onConfirm={() => handleDelete(r.id)}
								okButtonProps={{ danger:true }}
							>
								<FontAwesomeIcon className="text-link" icon={faTrash} />
							</Popconfirm>
						</>
					)}
				</>
			)
		}
	]

	const expandedRow = r => (
		<>
			<Row>
				<Col>
					<p className='mb-10'><strong>Email:</strong> {r.branch_spec.branch.email ? r.branch_spec.branch.email : <em className='hint-text'>Sin email registrado</em>}</p>
					<p className='mb-10'><strong>Celular:</strong> {r.branch_spec.branch.mobile}</p>
					<p className='mb-10'><strong>Fijo:</strong> {r.branch_spec.branch.phone ? r.branch_spec.branch.phone : <em className='hint-text'>Sin teléfono fijo registrado</em>}</p>
				</Col>
				<Col>
					<p className='mb-10'><strong>Bandera:</strong> {r.branch_spec.branch.flag}</p>
					<p className='mb-10'><strong>SICOM:</strong> {r.branch_spec.branch.sicom_code}</p>
					<p className='mb-10'><strong>Constructor:</strong> {r.branch_spec.branch.constructor_name ? r.branch_spec.branch.constructor_name : <em className='hint-text'>Sin nombre de constructor registrado</em>}</p>
					<p className='mb-0'><strong>Tipo Instalación:</strong> {r.branch_spec.branch.instalation_type_name}</p>
				</Col>
			</Row>
		</>
	)

	if(!can('Ver listado de solicitudes de servicio'))
		return <PermissionIsRequired title='No tiene permisos para ver solicitudes de servicio' />
	
	return (
		<>
			<Table 
				rowKey={r => r.id}
				dataSource={inquiries}
				loading={!inquiries}
				columns={columns}
				expandable={{ expandedRowRender: expandedRow }}
				size="small"
				pagination={false}
				className="mb-15"
			/>
			<Pagination
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			{ editModal && (
				<EditInquiryModal
					visible
					onClose={() => setEditModal(false)}
					inquiry={editModal}
					reload={reload}
				/>
			)}
		</>
	)
}