import React from 'react'
import { Form } from 'react-bootstrap'

export default function VerificationTaskForm({ register, errors, verificationTask, setValue, taskTemplate, isRetry }) {
	return (
		<>
			<Form.Group>
				<Form.Label>Estado Actual <span className='text-danger'>*</span></Form.Label>
				<Form.Control
					as="select"
					{...register('status', { required: true })}
					defaultValue={verificationTask && verificationTask.status.split(" ")[0]}
					onChange={e => {
						let result = e.target.value
						if(result === 'C')
							setValue('observations', taskTemplate.resolved_placeholder)
						else if(result === 'NC' || result === 'NCP')
							setValue('observations', taskTemplate.no_resolved_placeholder)
						else
							setValue('observations', '')
					}}
				>
					<option values="">:: Seleccione una opción ::</option>
					<option value="C">C - Conforme</option>
					<option value="NC">NC - No Conforme</option>
					<option value="NA">NA - No Aplica</option>
					<option value="NCP">NCP - No Conformidad con tiempo en la resolución</option>
				</Form.Control>
				{ errors.status && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Observaciones <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					as="textarea"
					{...register('observations', { required: true })}
					placeholder='Escribe aquí...'
					style={{ height: '70px' }}
					defaultValue={verificationTask ? (isRetry ? verificationTask.observations_retry : verificationTask.observations) : null}
				/>
				{ errors.observations && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</>
	)
}