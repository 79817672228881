import React from 'react'

import CERTIFICATE_TEMPLATE_INS01 from './templates/CERTIFICATE_TEMPLATE_INS01'
import CERTIFICATE_TEMPLATE_PRU01 from './templates/CERTIFICATE_TEMPLATE_PRU01'

import DocumentTemplate404 from '../../../components/DocumentTemplate404'

export default function CertificateDocument({ certificate, reviewLogbooks, filters }) {
	
	switch (certificate.format_version.format_template) {
		case 'CERTIFICATE_TEMPLATE_INS01':
			return <CERTIFICATE_TEMPLATE_INS01 data={certificate} filters={filters} />

		case 'CERTIFICATE_TEMPLATE_PRU01':
			return <CERTIFICATE_TEMPLATE_PRU01 
				data={certificate} 
				reviewLogbooks={reviewLogbooks} 
				filters={filters}
			/>
	
		default:
			return <DocumentTemplate404 />
	}

}

