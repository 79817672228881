import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { currency, round } from '../../../../helpers'

export default function EditProposalForm({ register, errors, watch, isInspection }) {
    return (
        <>
            <Form.Group>
                <Form.Label>Fecha Elaboración</Form.Label>
                <Form.Control 
                    type="date"
                    {...register('elaborated_at', {required:true})}
                />
                { errors.elaborated_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
            </Form.Group>
            <Row>
                <Form.Group as={Col} sm="8">
                    <Form.Label>Valor por Unidad <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        {...register('service_value', { required:true })}
                    />
                    { errors.service_value && <Form.Text className='text-warning'>Este campo es requerido y debe ser númerico</Form.Text> }
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Ctd <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        {...register('quantity', { required:true })}
                    />
                    { errors.quantity && <Form.Text className='text-warning'>Este campo es requerido y debe ser númerico</Form.Text> }
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Valor del Servicio</Form.Label>
                    <Form.Control value={currency(round(parseInt(watch('service_value'))*parseInt(watch('quantity')),-1))} disabled />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Total { isInspection ? 'Gastos de Viaje' : 'Gastos de Transporte Gravado' } <small>(opcional)</small></Form.Label>
                    <Form.Control 
                        {...register('trip_expenses_value')}
                    />
                    { errors.trip_expenses_value && <Form.Text className='text-warning'>Este campo es requerido y debe ser númerico</Form.Text> }
                </Form.Group>
            </Row>
            <Form.Group>
                <Form.Label>Valor Total</Form.Label>
                <Form.Control value={currency((parseInt(watch('service_value'))*parseInt(watch('quantity'))) + parseInt(watch('trip_expenses_value')))} disabled />
            </Form.Group>
            <Form.Group>
                <Form.Label>Términos y condiciones del precio <span className='text-danger'>*</span></Form.Label>
                <Form.Control 
                    {...register('price_terms', { required:true })}
                    placeholder='Escriba aquí...'
                    as="textarea"
                    style={{ height: '200px' }}
                />
                { errors.price_terms && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
            </Form.Group>
            { isInspection && (
                <Form.Group>
                    <Form.Label>¿Es Dominguez Sanchez SAS el constructor?</Form.Label>
                    <Form.Control
                        as="select"
                        {...register('is_ds_construction')}
                    >
                        <option value="">:: Seleccione una opción ::</option>
                        <option value={1}>Sí</option>
                        <option value={0}>No</option>
                    </Form.Control>
                </Form.Group>
            )}
        </>
    )
}