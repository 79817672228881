import React from 'react'
import styled from 'styled-components'

export default function NcTaskCard({ task, ncp, index }) {
	return (
		<>
			<Table className='table table-sm'>
				<thead>
					<tr>
						<th colSpan={4}>
							<p className='float-right mb-0 mt-2'>No {index + 1}</p>
							NO CONFORMIDAD Y PLAN DE CORRECCIÓN Y ACCIÓN CORRECTIVA
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className='bold' colSpan={2}>Requisito aplicable:</td>
						<td>{task.task_template.article}</td>
					</tr>
					<tr>
						<td colSpan={4}>{task.task_template.legal_description}</td>
					</tr>
					<tr>
						<td className='bold' colSpan={2}>Característica Lista Verificación Ministerio</td>
						<td>{task.task_template.minminas_criteria}</td>
					</tr>
					<tr>
						<td className='bold' colSpan={2}>Evidencia:</td>
						<td>{task.task_template.general_criteria}</td>
					</tr>
					<tr>
						<td className='bold' colSpan={2}>Observaciones Inspección Inicial:</td>
						<td>{task.observations}</td>
					</tr>
					{ task.observations_retry && (
						<>
							{ !ncp && (
								<tr>
									<td className='bold' colSpan={2}>Observaciones Inspección Cierre:</td>
									<td>{task.observations_retry}</td>
								</tr>
							)}
							<tr>
								<td className='bold' colSpan={2}>Resultado del Cierre:</td>
								<td>{task.status}</td>
							</tr>
						</>
					)}
				</tbody>
			</Table>
		</>
	)
}

const Table = styled.table`
	border: 1px solid #333;
	thead {
		background-color: #e8e8e8;
	}
	td {
		border: 1px solid #e8e8e8;
	}
`