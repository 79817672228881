import React from 'react'

import { SectionTitle, SectionContent } from '../../../components/WizardComponents'
import BranchTanksList from '../../Tanks/BranchTanksList'

export default function HasTanksEdsWizardForm({ form, setForm }) {
	return (
		<>
			<SectionTitle>Información para instalaciones de combustible líquido:</SectionTitle>
			<SectionContent className='pt-10 pb-0'>
				<BranchTanksList 
					branchSpecId={form.branch_spec_id}  
				/>
			</SectionContent>
		</>
	)
}