import React from 'react'
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import Slider from "react-slick"
import { Empty } from 'antd'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default function EvidenceImagesSliderModal({ visible, onClose, evidence }) {

	const settings = {
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
	}

	if(!evidence) return <Spinner />

	const validFormats = ['.jpg', '.jpeg', '.gif', '.png', '.svg','.JPG', '.JPEG', '.GIF', '.PNG' ]
	const images = evidence.filter(e => validFormats.some(v => e.attachment_uri.includes(v)))

	return (
		<>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Carrusel de Fotos</ModalHeader>
				<ModalBody className='p-0'>
					{ images.length > 0 ? (
						<Slider {...settings}>
							{ images.map( image => (
								<div key={image.id}>
									<img src={image.attachment_uri} alt={image.id} width="100%" />
								</div>
							)) }
						</Slider>
					) : <Empty description="No se tienen imagenes como evidencia" />}
				</ModalBody>
			</Modal>
		</>
	)
}