import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import EmployeesTable from './partials/EmployeesTable'
import NewEmployeeModal from './partials/NewEmployeeModal'
import ListTopBar from '../../components/ListTopBar'

import { getEmployees } from './services'
import { can, handleError } from '../../helpers'

export default function CompanyEmployeesModal({ visible, onClose, company }) {
	const [employees, setEmployees] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')

	useEffect(() => {
		!employees && can('Ver listado de empleados') && getEmployees({ 
			'filter[company_id]': company.id,
			[`filter[${filterType}]`]: filterValue,
		})
			.then(res => setEmployees(res.data.data))
			.catch(error => handleError(error))
	}, [employees, company, filterType, filterValue])

	return (
		<>
			<Modal isOpen={visible} size="lg">
				<ModalHeader toggle={onClose}>Personal vinculado a cliente</ModalHeader>
				<ModalBody>
					<ListTopBar
						className="simple"
						filterType={filterType}
						setFilterType={setFilterType}
						filterValue={filterValue}
						setFilterValue={setFilterValue}
						reload={() => setEmployees(null)}
						options={[
							{ label: 'Buscar por nombre', value: 'name' },
							{ label: 'Buscar por cargo', value: 'position' },
							{ label: 'Buscar por email', value: 'email' },
						]}
					/>
					<EmployeesTable
						employees={employees}
						reload={() => setEmployees(null)}
					/>
					<Button color="primary" onClick={() => setShowNewModal(true)} className='mt-10'>Agregar Nuevo</Button>
				</ModalBody>
			</Modal>
			{ showNewModal && (
				<NewEmployeeModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => setEmployees(null)} 
					companyId={company.id}
				/>
			)}
		</>
	)
}