import React, { useState, useEffect } from 'react'
import { Spinner } from 'reactstrap'
import { useSelector } from 'react-redux'
import moment from 'moment'

import AuthLayout from '../../layouts/AuthLayout'
import InternetRequired from '../Offline/partials/InternetRequired'

import { publicGetServiceReport } from './services'
import { handleError } from '../../helpers'
import { Result } from 'antd'

export default function ServiceReportVeracity({ id }) {
    const [serviceReport, setServiceReport] = useState(null)
    const { isConnected } = useSelector(state => state.offline)

    useEffect(() => {
		!serviceReport && isConnected && publicGetServiceReport(id, {
			include: 'serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company',
			append: 'sku_formated'
		})
			.then(res => setServiceReport(res.data.data))
			.catch(error => handleError(error))
	}, [serviceReport, id, isConnected])

    if(!isConnected) return <InternetRequired />

    if(!serviceReport) return <Spinner />

    return (
        <AuthLayout>
            <Result 
                status="success"
                title="Veracidad del informe"
                subTitle={`Informe ${serviceReport.sku_formated}`}
                className='pt-0 pb-20'
            />
            <div className='text-left mb-20'>
                <p className='mb-5'><strong>Fecha de Emisión:</strong> {moment(serviceReport.created_at).format('DD-MMM-YYYY')}</p>
                <p className='mb-5'><strong>Instalación inspeccionada:</strong> {serviceReport.service_review.service_plan.service_inspector.service.proposal.inquiry.branch_spec.branch.name}</p>
            </div>
            <p>Si se requiere más información puede enviar un correo electrónico a <a href='mailto:pruebas@dewar.com.co'>pruebas@dewar.com.co</a></p>
        </AuthLayout>
    )
}