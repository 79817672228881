import React from 'react'
import { Form } from 'react-bootstrap'

export default function InstalationTypeForm({ instalationType, register, errors }) {
	return (
		<>
			<Form.Group>
				<Form.Label>Nombre <span className="text-danger">*</span></Form.Label>
				<Form.Control 
					{...register('name', {required:true})} 
					defaultValue={instalationType && instalationType.name} 
				/>
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Descripción Corta <small>(opcional)</small></Form.Label>
				<Form.Control 
					{...register('hint', {})} 
					defaultValue={instalationType && instalationType.hint} 
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Criterio <span className='text-primary'>*</span></Form.Label>
				<Form.Control 
					as="textarea"
					{...register('criteria', { register: true })} 
					defaultValue={instalationType && instalationType.criteria} 
					style={{ height: '120px' }}
				/>
				{ errors.criteria && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Tiene combustible líquido</Form.Label>
				<Form.Check 
					label="SI"
					value="1"
					type="radio"
					{...register('has_tanks', { required: true })}
					checked={instalationType && instalationType.has_tanks}
					className="mr-15 pt-5"
				/>
				<Form.Check 
					label="NO"
					value="0"
					type="radio"
					{...register('has_tanks', { required: true })}
					checked={instalationType && !instalationType.has_tanks}
					className="mr-15"
				/>
				{ errors.has_tanks && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Es EDS o tiene EDS privada</Form.Label>
				<Form.Check 
					label="SI"
					value="1"
					type="radio"
					{...register('has_eds', { required: true })}
					checked={instalationType && instalationType.has_eds}
					className="mr-15 pt-5"
				/>
				<Form.Check 
					label="NO"
					value="0"
					type="radio"
					{...register('has_eds', { required: true })}
					checked={instalationType && !instalationType.has_eds}
					className="mr-15"
				/>
				{ errors.has_eds && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Es Planta, Mayorista o Gran Consumidor</Form.Label>
				<Form.Check 
					label="SI"
					value="1"
					type="radio"
					{...register('has_high_volume', { required: true })}
					checked={instalationType && instalationType.has_high_volume}
					className="mr-15 pt-5"
				/>
				<Form.Check 
					label="NO"
					value="0"
					type="radio"
					{...register('has_high_volume', { required: true })}
					checked={instalationType && !instalationType.has_high_volume}
					className="mr-15"
				/>
				{ errors.has_eds && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>URL Requerimientos Anexos <small>(opcional)</small></Form.Label>
				<Form.Control 
					{...register('requirements_url', {})} 
					defaultValue={instalationType && instalationType.requirements_url}
				/>
			</Form.Group>
			<div className="mb-10" />
		</>
	)
}