import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'

import PaymentReceiptsTable from './partials/PaymentReceiptsTable'
import NewPaymentReceiptModal from './partials/NewPaymentReceiptModal'

import { getPaymentReceipts } from './services'
import { can, handleError } from '../../helpers'

export default function ProposalPaymentsModal({ visible, onClose, proposalId, reload }) {
	const [paymentReceipts, setPaymentReceipts] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!paymentReceipts && can('Ver listado de recibos de pagos')	&& getPaymentReceipts({ 'filter[proposal_id]': proposalId })
			.then(res => setPaymentReceipts(res.data.data))
			.catch(error => handleError(error))
	}, [paymentReceipts, proposalId])

	return (
		<>
			<Modal isOpen={visible} size='lg'>
				<ModalHeader toggle={onClose}>Soportes de Pago</ModalHeader>
				<ModalBody>
					{ paymentReceipts ?  (
						<>
							<PaymentReceiptsTable 
								paymentReceipts={paymentReceipts} 
								reload={() => {
									setPaymentReceipts(null)
									reload()
								}} 
							/>
							<Button className='float-right mt-10' color='primary' onClick={() => setShowNewModal(true)}>
								Agregar Soporte de Pago
							</Button>
						</>
					) : <Spinner />}
				</ModalBody>
			</Modal>
			{ showNewModal === true && (
				<NewPaymentReceiptModal 
					visible
					onClose={() => setShowNewModal(false)}
					proposalId={proposalId}
					reload={() => setPaymentReceipts(null)}
				/>
			)}
		</>
	)
}