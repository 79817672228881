import React from 'react'
import { Form } from 'react-bootstrap'

export default function TankSpecForm({ register, errors, tankSpec, watch }) {
	return (
		<>
			<Form.Group>
				<Form.Label>No. Tanque</Form.Label>
				<Form.Control
					{...register('tank_number', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.tank_number && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Producto Almacenado</Form.Label>
				<Form.Control 
					as="select"
					{...register('product_name', { required: true })}
					placeholder="Escriba aquí..."
				>
					<option value="">:: Seleccione una opción ::</option>
					<option>GASOLINA CORRIENTE</option>
					<option>GASOLINA EXTRA</option>
					<option>ACPM </option>
					<option>DIESEL </option>
					<option>BIODIESEL</option>
					<option>NAFTA</option>
					<option>CRUDO Y MEZCLAS</option>
					<option>FUEL OIL</option>
					<option>JET A-1</option>
					<option>AVGAS</option>
					<option>ETANOL</option>
					<option>OTRO</option>
				</Form.Control>
				{ errors.product_name && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			{ watch('product_name') === 'OTRO' && (
				<Form.Group>
					<Form.Label>Otro. ¿Cuál?</Form.Label>
					<Form.Control 
						{...register('other_product_name', { required: true })}
						placeholder="Escriba aquí..."
					/>
					{ errors.other_product_name && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
			)}
			<Form.Group>
				<Form.Label>Capacidad (GL)</Form.Label>
				<Form.Control 
					type="number"
					{...register('tank_capacity', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.tank_capacity && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label className='block mt-10'>Tipo de Tanque</Form.Label>
				<Form.Check 
					type="radio"
					{...register('is_underground', { required: true })}
					value="1"
					label="Tanque Enterrado"
					inline
					defaultChecked={tankSpec && tankSpec.is_underground}
				/>
				<Form.Check 
					type="radio"
					{...register('is_underground', { required: true })}
					value="0"
					label="En Superficie"
					inline
					defaultChecked={tankSpec && !tankSpec.is_underground}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Tipo <small>(Solo Plantas)</small></Form.Label>
				<Form.Control 
					{...register('tank_type')}
					placeholder="Escriba aquí..."
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Diametro <small>(Solo Plantas)</small></Form.Label>
				<Form.Control 
					type="number"
					{...register('tank_diameter')}
					placeholder="Escriba aquí..."
					step="0.01"
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label className='mr-10'>Sumar en total de tanques:</Form.Label>
				<Form.Check 
					{...register('should_sum')}
					label="Si"
					value="1"
					type="radio"
					inline
					defaultChecked={tankSpec ? tankSpec.should_sum ? 1 : 0 : 1}
				/>
				<Form.Check 
					{...register('should_sum')}
					label="No"
					value="0"
					type="radio"
					inline
					defaultChecked={tankSpec ? !tankSpec.should_sum ? 1 : 0 : 0}
				/>
			</Form.Group>
		</>
	)
}