import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Upload } from "antd"
import { InboxOutlined } from '@ant-design/icons'
import AWS from "aws-sdk"
import moment from 'moment'

import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storeReportEvidence } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

const { Dragger } = Upload

export default function NewReportEvidenceModal({ visible, onClose, serviceReportId, serviceReviewId, reload, directory }) {

	const props = {
		multiple: true,
		customRequest({
			file,
			onError,
			onProgress,
			onSuccess,
		}) {
			AWS.config.update({
				accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    			secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
			});
		
			const S3 = new AWS.S3({
				params: { Bucket: 'dewar' },
				region: 'us-east-1',
		  });

		  let fileExt = file.name.split('.').pop();
		  let fileName = `app/${directory}/${serviceReportId ? serviceReportId : `SR${serviceReviewId}`}/${moment().format('hhmmss')}${Math.floor(Math.random()*10)}.${fileExt}`
		
			const objParams = {
				Bucket: "dewar",
				Key: fileName,
				Body: file,
				ContentType: file.type
			};
		
			S3.putObject(objParams)
				.on("httpUploadProgress", function({ loaded, total }) {
					onProgress(
						{ percent: Math.round((loaded / total) * 100) },
						file
					);
				})
				.send(function(err, data) {
					if (err) {
						onError();
						console.error('Error al subir imagen: ', err.message);
					} else {
						onSuccess(data.response, file);
						
						const values = {
							attachment_uri: `https://dewar.s3.us-east-1.amazonaws.com/${fileName}`
						}

						if(serviceReportId) values.service_report_id = serviceReportId
						if(serviceReviewId) values.service_review_id = serviceReviewId
						
						storeReportEvidence(values)
							.then(res => handleSuccess(res.data.message, reload, onClose))
							.catch(error => handleError(error))
					}
				});
			}
		};

	if(!can('Crear evidencias del informe'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear evidencia del informe" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Registro Fotográfico / Archivo Adjunto</ModalHeader>
				<ModalBody>
					<Dragger {...props}>
						<p className="ant-upload-drag-icon">
							<InboxOutlined />
						</p>
						<p className="ant-upload-text">Haga clic o arraste la imagen o archivo aquí</p>
						<p className="ant-upload-hint">
							Solo se permite adjuntar un archivo menor a 5MB
						</p>
					</Dragger>
				</ModalBody>
			</Modal>
		</>
	)
}