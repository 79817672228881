import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import FormatVersionSelect from '../../FormatVersions/FormatVersionSelect'

export default function DecisionNoticeForm({ register, errors, setValue, businessScope, noCompliant }) {
    return (
        <>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Versión <span className='text-danger'>*</span></Form.Label>
                    <FormatVersionSelect 
                        keyword="decisionNotice"
                        setValue={setValue}
                        businessScope={businessScope}
                    />
                    { errors.format_version_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Fecha Vigencia <span className='text-danger'>*</span></Form.Label>
                    <Form.Control {...register('expire_at')} disabled />
                </Form.Group>
            </Row>
            <Form.Group>
                <Form.Label>Fecha del Comunicado <span className='text-danger'>*</span></Form.Label>
                <Form.Control 
                    type="date"
                    {...register('published_at', { required: true })}
                />
                { errors.published_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
            </Form.Group>
            { noCompliant && businessScope === 'inspeccion' && (
                <Form.Group>
                    <Form.Label>Motivo para No Otorgar Personalizado <small>(opcional)</small></Form.Label>
                    <Form.Control 
                        as="textarea"
                        {...register('custom_response')}
                        style={{ height: '70px' }}
                    />
                </Form.Group>
            )}
        </>
    )
}