import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Row, Col, Spinner } from 'react-bootstrap'
import moment from 'moment'

import FormatVersionSelect from '../../../FormatVersions/FormatVersionSelect'
import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { storeServiceReport } from '../../services'
import { can, handleError, handleSuccess } from '../../../../helpers'

export default function NewServiceReportModal({ visible, onClose, serviceReviewId, businessScope }) {
	const [loading, setLoading] = useState(false)
	const { register, handleSubmit, formState: { errors }, setValue } = useForm({
		defaultValues: {
			results_details: {},
			reported_at: moment().format('YYYY-MM-DD')
		}
	})
	const history = useHistory()

	const onSubmit = values => {
		setLoading(true)
		storeServiceReport({ 
			...values,
			service_review_id: serviceReviewId,
			sku: `${businessScope === 'pruebas' ? 'INP-' : 'INN-'}${values.sku_number}`
		})
			.then(res => handleSuccess(res.data.message, () => history.push(`/service_reports/${res.data.data.id}`)))
			.catch(error => handleError(error, setLoading))
	}

	if(!can('Crear Informe de inspección - INSTALACIÓN') || !can('Crear Informe de inspección - PRUEBAS'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar informes de inspección" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Generar Informe de Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Form.Group as={Col}>
								<Form.Label>Versión <span className='text-danger'>*</span></Form.Label>
								<FormatVersionSelect 
									keyword="serviceReport"
									setValue={setValue}
									businessScope={businessScope}
								/>
								{ errors.format_version_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Fecha Vigencia <span className='text-danger'>*</span></Form.Label>
								<Form.Control {...register('expire_at')} disabled />
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} sm="2" className='pr-0'>
								<Form.Label>Prefijo</Form.Label>
								<Form.Control 
									value={businessScope === 'pruebas' ? 'INP-' : 'INN-'}
									disabled 
								/>
							</Form.Group>
							<Form.Group as={Col} sm="4" style={{ display: businessScope !== 'pruebas' ? 'none' : 'inline-block' }}>
								<Form.Label>Consecutivo <span className='small'><em>(opcional)</em></span></Form.Label>
								<Form.Control 
									{...register('sku_number', {})}
									type="number"
								/>
								{ errors.sku_number && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Fecha de Elaboración <span className='text-danger'>*</span></Form.Label>
								<Form.Control 
									type="date"
									{...register('reported_at', { required: true })}
								/>
								{ errors.reported_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
						</Row>
						<Form.Group>
							<Form.Label>Director Asignado <span className='text-danger'>*</span></Form.Label>
							<Form.Control
								as="select"
								{...register('director_user_id', { required: true })}
							>
								<option value="">:: Seleccione una opción ::</option>
								{ businessScope === 'inspeccion' ? (
									<option value="11">Alejandra Matin</option>
								) : (
									<>
										<option value="9">Alejandro Robles Santos</option>
										<option value="7">Cano Moya Rodríguez</option>
									</>
								)}
							</Form.Control>
							{ errors.director_user_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Generar Informe de Inspección
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}