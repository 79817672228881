import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Spinner } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import ServicePlanForm from './ServicePlanForm'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storeServicePlan } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function NewServicePlanModal({ visible, onClose, service, reload }) {
	const { register, handleSubmit, formState: { errors }, setValue } = useForm({
		defaultValues: {
			location: service.business_scope === 'pruebas' ? 'En sitio de la instalación' : '',
			observations: service.business_scope === 'inspeccion' ? 'Por favor confirmar recibo de ese Plan y enviar a mi correo electrónico un comunicado oficial de la instalación informando si ha realizado ó no modificaciones o ampliaciones desde la última visita de inspección de la instalación. En caso afirmativo especificar cuales modificaciones o ampliaciones. ¡¡Gracias!!' : 'Durante la prueba de hermeticidad el tanque se encontrará fuera de funcionamiento mínimo 2 horas aproximadamente. \n\nEs preferible que los tanques a inspeccionar se encuentren listos para ser inspeccionados, se verifica el taponamiento de bocas del tanque y tuberías, incluyendo el desfogue. \n\nEl servicio está programado de acuerdo con las especificaciones registradas en la solicitud del servicio. Si en el momento de llegar a la instalación se encuentran especificaciones que difieren de las de la Solicitud de Servicio, los costos adicionales tendrán que ser asumidos y aprobados por el cliente. \n\nSi la distancia de los desfogues difiere de la consignada en la solicitud de servicio, y por este motivo no se puede realizar el servicio, se dará cumplimiento a lo establecido en el Reglamento de inspección de DEWAR.'
		}
	})
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		storeServicePlan({ ...values, service_inspector_id: service.service_inspector.id, })
			.then(res => handleSuccess(res.data.message, reload, onClose))
			.catch(error => handleError(error, setLoading))
	}

	if(!can('Crear planes de servicios'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear planes de inspección" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Plan de Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ServicePlanForm 
							register={register} 
							errors={errors}
							setValue={setValue}
							businessScope={service.business_scope}
						/>
						<Button color="primary" type='submit' className='mt-10' disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Crear Plan de Inspección
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}