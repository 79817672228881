import React from 'react'

import DoubleScopesBar from '../../../components/DoubleScopesBar'

export default function ProposalListScopes({ 
    filterBusinessScope, setFilterBusinessScope, filterStatusScope, setFilterStatusScope, reload 
}) {

    const businessScopes = [
        { label: 'Servicios de Inspección', value: 'inspeccion' },
        { label: 'Pruebas de Tanques y Tuberías', value: 'pruebas' },
    ]

    const statusScopes = [
        { label: 'Realizadas', value: 'fulfilled' },
        { label: 'Aceptadas', value: 'accepted' },
        { label: 'Publicadas', value: 'published' },
        { label: 'En Borrador', value: 'draft' },
        { label: 'Rechazadas', value: 'rejected' },
    ]

    return (
        <>
            <DoubleScopesBar
                businessScopes={businessScopes}
                statusScopes={statusScopes}
                filterBusinessScope={filterBusinessScope}
                setFilterBusinessScope={setFilterBusinessScope}
                filterStatusScope={filterStatusScope}
                setFilterStatusScope={setFilterStatusScope}
                reload={reload}
            />
        </>
    )
}