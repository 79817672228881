import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router'
import { Spinner } from 'react-bootstrap'

import { userLogout } from './actions'
import { logout } from './services'

export default function Logout() {
	const dispatch = useDispatch()
	const token = useSelector(state => state.auth.token)

	useEffect(() => {
		logout()
			.catch(error => {})
			.finally(() => dispatch(userLogout()))
	})

	if(!token) return <Redirect to="/auth/login" />

	return (
		<>
			<div className="text-center">
				<Spinner animation='border' size='lg' />
			</div>
		</>
	)
}