import React, { useState } from 'react'
import { Alert, Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'
import moment from 'moment'

import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { updateCertificate } from '../../services'
import { can, handleError } from '../../../../helpers'

export default function PublishCertificateModal({ visible, onClose, certificate, reload }) {
	const [loading, setLoading] = useState(false)

	const onSubmit = () => {
		setLoading(true)
		updateCertificate(certificate.id, { 
			published_at: moment().format('YYYY-MM-DD HH:mm:ss')
		})
			.then(() => {
				message.success('Certificado publicado exitosamente')
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	if(!can('Actualizar certificados'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar certificados" onClose={onClose} />
 
	return (
		<Modal isOpen={visible}>
			<ModalHeader toggle={onClose}>Publicar Certificado #{certificate.id}</ModalHeader>
			<ModalBody>
				<Alert color='info'>Al publicar el certificado este pasará a ser visible al cliente desde el Portal de Clientes Dewar</Alert>
				<Button 
					color='primary' 
					onClick={onSubmit}
					disabled={loading}
				>
					Publicar en Portal de Clientes
				</Button>
			</ModalBody>
		</Modal>
	)
}