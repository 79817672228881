import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import moment from 'moment'

import TripExpenseDetails from './partials/TripExpenseDetails'

import { getTripExpenses } from './services'
import { handleError } from '../../helpers'
import { Divider, Empty } from 'antd'

export default function SuggestedTripExpenses({ inquiry }) {
    const [relatedTripExpenses, setRelatedTripExpenses] = useState(null)

    useEffect(() => {
        !relatedTripExpenses && getTripExpenses({
            'filter[branch.address_city]': inquiry.branch_spec.branch.address_city,
            'filter[company_id]': inquiry.branch_spec.branch.company_id,
            'include': 'serviceInspector.service'
        })
            .then(res => setRelatedTripExpenses(res.data.data))
            .catch(error => handleError(error))
    }, [inquiry, relatedTripExpenses])
    
	return (
		<div>
			<h6>Histórico de gastos de viaje para <b>{inquiry.branch_spec.branch.address_city}</b> para <b>{inquiry.branch_spec.branch.company.name}</b></h6>
            { relatedTripExpenses ? (
                <React.Fragment>
                    { relatedTripExpenses.length > 0 ? (
                        <React.Fragment>
                            { relatedTripExpenses.map(tripExpense => (
                                <React.Fragment key={tripExpense.id}>
                                    <Divider className='my-10' />
                                    <p className='mb-0 bold'>
                                        Servicio: {tripExpense.service_inspector.service.id}.{" "}
                                        Asignado el: {moment(tripExpense.service_inspector.created_at).format('YYYY-MM-DD')}
                                    </p>
                                    <TripExpenseDetails tripExpense={tripExpense} />
                                </React.Fragment>
                            )) }
                        </React.Fragment>
                    ) : (
                        <Empty description="No se encontraron gastos de viaje relacionados" />
                    )}
                </React.Fragment>
            ) : (
                <Spinner size="sm" className="mt-10" />
            )}
		</div>
	)
}