import React from 'react'
import { Descriptions } from 'antd'
import TanksTable from '../../Tanks/partials/TanksTable'

const { Item } = Descriptions

export default function HasTanksDetails({ branchSpec }) {
	return (
		<>
			<Descriptions bordered size="small" className="mt-10 mb-10" column={3}>
				<Item label="Total tanques enterrados:">
					{branchSpec && branchSpec.total_tanques_enterrados} tanques
				</Item>
				<Item label="Total tanques superficiales:">
					{branchSpec && branchSpec.total_tanques_superficiales} tanques
				</Item>
				<Item label="Total de tanques:">
					{branchSpec && parseInt(branchSpec.total_tanques_superficiales) + parseInt(branchSpec.total_tanques_enterrados)} tanques
				</Item>
			</Descriptions>
			<TanksTable tanks={branchSpec.tanks} />
		</>
	)
}