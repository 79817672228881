import React from 'react'
import { Form } from 'react-bootstrap'

export default function RoleForm({ role, register, errors }) {
	return (
		<>
			<Form.Group>
				<Form.Label>Nombre del Rol <span className="text-danger">*</span></Form.Label>
				<Form.Control 
                    {...register('name', {required:true})} 
                    defaultValue={role && role.name} 
                />
				{ errors.name && (
                    <Form.Text className="text-warning">
                        Este campo es requerido.
                    </Form.Text>
                )}
				<Form.Control {...register('guard_name', {required:true})} style={{"display":"none"}} />
			</Form.Group>
		</>
	)
}