import React from 'react'
import { Result } from 'antd'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

export default function PermissionIsRequired({ 
    type = 'graphic',
    title = 'No cuenta con permisos suficientes.',
    onClose = () => {}
}) {
    switch (type) {
        case 'graphic':
            return <Result 
				status="403"
				title={title}
			/>

        case 'warning':
            return <Result 
				status="warning"
				title={title}
			/>

        case 'modal':
            return (
                <Modal isOpen toggle={onClose}>
                    <ModalHeader toggle={onClose}>
                        Acceso Denegado
                    </ModalHeader>
                    <ModalBody>
                        <Result 
                            status="403"
                            title={title}
                        />
                    </ModalBody>
                </Modal>
            )

        case 'text':
            return <em className='small text-danger'>{title}</em>

        case 'empty':
            return ''
    
        default:
            return <em className='small text-danger'>{title}</em>
    }
}