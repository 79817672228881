import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import { message, Popconfirm } from 'antd'

import { handleError } from '../helpers'
import { queueRequest } from '../containers/Offline/actions'

export default function SendNotificationButton({ notifyService, offlineRequest = null }) {
	const { isConnected } = useSelector(state => state.offline)
	const dispatch = useDispatch()

	const onConfirm = () => {
		if(isConnected){
			notifyService()
				.then(res => message.success(res.data.message))
				.catch(error => handleError(error))
		} else {
			if(!offlineRequest) 
				return message.warning('Esta notificación no se puede enviar offline')

			dispatch(queueRequest(offlineRequest))
			message.info('Notificación solicitada y en espera de internet')
		}
	}

	return (
		<>
			<Popconfirm
				title="¿Seguro desea enviar la notificacion"
				cancelText="Cancelar"
				okText="Enviar"
				onConfirm={onConfirm}
			>
				<Button color='primary' outline>
					Enviar Notificación
				</Button>
			</Popconfirm>
		</>
	)
}