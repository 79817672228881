import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Row, Col } from 'reactstrap'
import { Form, InputGroup, Spinner } from 'react-bootstrap'

import FormatVersionSelect from '../../../FormatVersions/FormatVersionSelect'

import { storeCertificate } from '../../services'
import { handleError, handleSuccess } from '../../../../helpers'

export default function NewTestCertificateForm({ serviceVeredictId, criteria, branch, reload }) {
	const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: { article_numerals: criteria }
    })
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		storeCertificate({ 
			service_veredict_id: serviceVeredictId, 
			company_name: branch.company.name,
			company_document: branch.company.complete_document,
			company_sicom: branch.sicom_code,
			...values,
            sku: "DWP-" + values.sku
		})
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error, setLoading))
	}

	return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Versión <span className='text-danger'>*</span></Form.Label>
                    <FormatVersionSelect 
                        keyword="certificate"
                        setValue={setValue}
                        businessScope="pruebas"
                    />
                    { errors.format_version_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Fecha de Vigencia</Form.Label>
                    <Form.Control 
                        {...register('expire_at', {})}
                        disabled
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Consecutivo <span className='text-danger'>*</span></Form.Label>
                    <InputGroup>
                        <InputGroup.Text>DWP-</InputGroup.Text>
                        <Form.Control 
                            type="number"
                            {...register('sku', { required: true })}
                        />
                        <InputGroup.Text>-YYYY</InputGroup.Text>
                    </InputGroup>
                    { errors.sku && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Fecha Emisión <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        {...register('issued_at', { required: true })}
                        type="date"
                    />
                    { errors.issued_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                </Form.Group>
            </Row>			
            <Form.Group>
                <Form.Label>Numerales Aplicables</Form.Label>
                <Form.Control 
                    as="textarea"
                    {...register('article_numerals', { required: true })}
                    style={{ height: '120px' }}
                    disabled
                />
                { errors.article_numerals && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
            </Form.Group>
            <Form.Group>
                <Form.Label>Observaciones <small>(opcional)</small></Form.Label>
                <Form.Control 
                    as="textarea"
                    {...register('observations')}
                    style={{ height: '70px' }}
                    defaultValue="Las pruebas fueron realizadas conforme al procedimiento de pruebas de hermeticidad, manteniendo la presión durante más de una hora."
                />
            </Form.Group>
            <Button color="primary" type="submit" disabled={loading}>
                { loading && <Spinner animation='border' size="sm" className='mr-5' /> }
                Generar Certificado
            </Button>
        </Form>
	)
}