import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import { Divider } from 'antd'

import PreInvoiceDocument from './partials/PreInvoiceDocument'
import SendNotificationButton from '../../components/SendNotificationButton'
import InternetRequired from '../Offline/partials/InternetRequired'

import { notifyPreInvoice, publicGetPreInvoice } from './services'
import { handleError } from '../../helpers'

export default function PreInvoicePreview(props) {
	const { token } = useSelector(state => state.auth)
	const { isConnected } = useSelector(state => state.offline)
	const id = props.id ? props.id : props.match.params.id
	const [preInvoice, setPreInvoice] = useState(null)

	useEffect(() => {
		!preInvoice && isConnected && publicGetPreInvoice(id, {
			include: 'proposal.inquiry.branchSpec.branch'
		})
			.then(res => setPreInvoice(res.data.data))
			.catch(error => handleError(error))
	}, [ preInvoice, id, isConnected ])

	if(!isConnected) return <InternetRequired />

	return (
		<>
			<div>
				{ preInvoice ? (
					<>
						<PreInvoiceDocument preInvoice={preInvoice} />
						{ token && (
							<div className="no-print">
								<Divider />
								<SendNotificationButton notifyService={() => notifyPreInvoice(preInvoice.id)} />
							</div>
						)}
					</>
				) : (
					<Spinner />
				)}
			</div>
		</>
	)
}