import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Button } from 'reactstrap'

import { updateReviewLogbook } from '../../services'
import { handleError, handleSuccess } from '../../../../helpers'

export default function ReviewLogbookWellTestForm({ onClose, reload, reviewLogbook, defaultData }) {
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: defaultData
    })

    const onSubmit = values => {
        const wellTests = reviewLogbook.wells_tests.tests ? [ ...reviewLogbook.wells_tests.tests.filter(r => r.sku !== values.sku) ] : []
        wellTests.push(values)

        updateReviewLogbook(reviewLogbook.id, { wells_tests: { tests: wellTests } })
            .then(res => handleSuccess(res.data.message, reload, onClose))
            .catch(error => handleError(error, setLoading))
    }

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    <Form.Label>N° Pozo <span className='text-primary'>*</span></Form.Label>
                    <Form.Control 
                        {...register('sku', { required: true })}
                        placeholder='Escriba aquí...'
                    />
                    { errors.sku && <Form.Text className='text-danger'>Este campo es requerido</Form.Text> }
                    <Form.Text className='text-muted'>En caso de ya existir se sobreescribira la información existente.</Form.Text>
                </Form.Group>
                <p className='bold mt-15 mb-5'>Antes de la Prueba</p>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Nivel Freático (m)</Form.Label>
                        <Form.Control 
                            {...register('before_level', {})}
                            placeholder='Escriba aquí...'
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Contaminación</Form.Label>
                        <Form.Control 
                            as="select"
                            {...register('before_contamination', {})}
                        >
                            <option value="">:: Selecione una opción ::</option>
                            <option>Sí</option>
                            <option>No</option>
                        </Form.Control>
                    </Form.Group>
                </Row>
                <p className='bold mt-15 mb-5'>Después de la Prueba</p>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Nivel Freático (m)</Form.Label>
                        <Form.Control 
                            {...register('after_level', {})}
                            placeholder='Escriba aquí...'
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Contaminación</Form.Label>
                        <Form.Control 
                            as="select"
                            {...register('after_contamination', {})}
                        >
                            <option value="">:: Selecione una opción ::</option>
                            <option>Sí</option>
                            <option>No</option>
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Button color='primary' type="submit" disabled={loading}>
                    Agregar Prueba de Pozo
                </Button>
            </Form>
        </>
    )
}