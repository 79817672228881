import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { Divider } from 'antd'

import ServiceReportDetails from './partials/ServiceReportDetails'
import ReportEvidenceList from '../ReportEvidence/ReportEvidenceList'
import EmailLogsModal from '../EmailLogs/EmailLogsModal'
import SendNotificationButton from '../../components/SendNotificationButton'

import { notifyServiceReport } from './services'

export default function ServiceReportTab({ serviceReport, reload }) {
	const [emailLogsModal, setEmailLogsModal] = useState(null)

	return (
		<>
			<h6 className='mt-10 mb-0 text-center'>Informe de Inspección</h6>
			<ServiceReportDetails 
				serviceReport={serviceReport}
				reload={reload}
			/>
			<p className='bold mt-20'>REGISTRO FOTOGRÁFICO</p>
			<ReportEvidenceList
				serviceReport={serviceReport}
				editable={serviceReport.is_editable}
				directory="report_evidence"
			/>
			<Link to={`/service_reports/preview/${serviceReport.id}`}>
				<Button color="primary" outline>
					Formato de Informe de Inspección
				</Button>
			</Link>
			<Divider type="vertical" />
			<SendNotificationButton notifyService={() => notifyServiceReport(serviceReport.id)} />
			<Divider type="vertical" />
			<Button color="primary" outline onClick={() => setEmailLogsModal(serviceReport.id)}>
				Correos Enviados
			</Button>

			{ emailLogsModal && (
				<EmailLogsModal
					visible
					onClose={() => setEmailLogsModal(null)}
					modelType="service_report"
					modelId={emailLogsModal}
				/>
			)}
		</>
	)
}