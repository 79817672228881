import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { attachBranchEmployee, searchActiveBranches } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function BranchEmployeeAttachModal({ visible, onClose, reload, employee }) {
    const [selectedBranch, setSelectedBranch] = useState([])
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: {
            employee_id: employee.id
        }
    })

    const onSubmit = values => {
        setLoading(true)
        attachBranchEmployee(values)
            .then(res => handleSuccess(res.data.message, reload, onClose))
            .catch(error => handleError(error, setLoading))
    }

    if(!can('Actualizar sedes'))
        return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar instalaciones" onClose={onClose} />

    return (
        <Modal isOpen={visible} toggle={onClose}>
            <ModalHeader toggle={onClose}>Asociar sede a {employee.name}</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label>Instalación a Asociar</Form.Label>
                        <ServerSideSelect
                            value={selectedBranch}
                            placeholder="Buscar por nombre"
                            fetchOptions={searchActiveBranches}
                            onChange={val => {
                                setSelectedBranch(val)
                                setValue('branch_id', val.value)
                            }}
                            className="full-width mb-10"
                            scopeId={employee.company_id}
                        />
                        <Form.Control {...register('branch_id', { required: true })} style={{display:'none'}} />
                        { errors.branch_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                    </Form.Group>
                    <Button color="primary" type="submit" disabled={loading}>
                        { loading && <Spinner animation='border' size="sm" className='mr-5' /> }
                        Asociar Instalación
                    </Button>
                </Form>
            </ModalBody>
        </Modal>
    )
}