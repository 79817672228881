import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Spinner } from 'reactstrap'

import NonCompliantReportDocument from './partials/NonCompliantReportDocument'
import InternetRequired from '../Offline/partials/InternetRequired'

import { publicGetVerificationTasks } from '../VerificationTasks/services'
import { publicGetVerificationList } from '../VerificationLists/services'
import { handleError } from '../../helpers'

export default function NonCompliantReportPreview(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.id ? props.id : props.match.params.id
	const [verificationList, setVerificationList] = useState(null)
	const [verificationTasks, setVerificationTasks] = useState(null)

	useEffect(() => {
		!verificationList && isConnected && publicGetVerificationList(id, {
			include: 'verificationTasks,service.proposal.inquiry.branchSpec.branch.company,service.proposal.inquiry.employee,service.serviceInspector.user,service.serviceInspector.servicePlan.serviceReview.nonCompliantReport.formatVersion',
		})
			.then(res => setVerificationList(res.data.data))
			.catch(error => handleError(error))
	}, [ verificationList, id, isConnected ])

	useEffect(() => {
		!verificationTasks && publicGetVerificationTasks({
			'filter[verification_list_id]': id,
			'filter[only_unresolved]': 1,
			sort: 'sku',
			per_page: 10000
		})
			.then(res => setVerificationTasks(res.data.data))
			.catch(error => handleError(error))
	}, [ verificationTasks, id ])

	if(!isConnected) return <InternetRequired />

	if(!verificationList || !verificationTasks) return <Spinner />

	return (
		<>
			<Container>
				<NonCompliantReportDocument 
					verificationList={{
						...verificationList,
						verification_tasks: verificationTasks
					}}
				/>
			</Container>
		</>
	)
}

const Container = styled.div`
	padding: 0px 10px;
`