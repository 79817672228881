import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'

import RetryNoticeDocument from './partials/RetryNoticeDocument'
import InternetRequired from '../Offline/partials/InternetRequired'

import { publicGetRetryNotice } from './services'
import { handleError } from '../../helpers'

export default function RetryNoticePreview(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.id ? props.id : props.match.params.id
	const [retryNotice, setRetryNotice] = useState(null)

	useEffect(() => {
		!retryNotice && isConnected && publicGetRetryNotice(id, {
			include: 'serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company',
		})
			.then(res => setRetryNotice(res.data.data))
			.catch(error => handleError(error))
	}, [ retryNotice, id, isConnected ])

	if(!isConnected) return <InternetRequired />

	if(!retryNotice) return <Spinner />
	
	return (
		<>
			<div className='px-10'>
				<RetryNoticeDocument retryNotice={retryNotice} />
			</div>
		</>
	)
}