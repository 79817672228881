import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import { Form } from 'react-bootstrap'

import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { getInstalationTypes } from '../../InstalationTypes/services'
import { attachInstalationType, detachInstalationType } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function TaskInstalationTypesModal({ visible, onClose, taskTemplate, reload }) {
	const [instalationTypes, setInstalationTypes] = useState(null)

	useEffect(() => {
		!instalationTypes && can('is_super_admin') && getInstalationTypes()
			.then(res => setInstalationTypes(res.data.data))
			.catch(error => handleError(error))
	}, [ instalationTypes ])

	const handleAttach = (typeId, scope) => {
		attachInstalationType(taskTemplate.id, typeId, { scope })
			.then(res => handleSuccess(res.data.message, () => reload(res.data.data)))
			.catch(error => handleError(error))
	}

	const handleDetach = (typeId) => {
		detachInstalationType(taskTemplate.id, typeId)
			.then(res => handleSuccess(res.data.message, () => reload(res.data.data)))
			.catch(error => handleError(error))
	}

	if(!can('is_super_admin'))
		return <PermissionIsRequired type="modal" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Tipos de Instalación Asociados</ModalHeader>
				<ModalBody>
					{ instalationTypes ? (
						<>
							{ instalationTypes.map( type => {
								let matches = taskTemplate.instalation_types.filter(t => t.id === type.id)
								let current = matches.length > 0 ? { value: matches[0].pivot.scope } : { value: '' }
								return (
									<div key={type.id} className='mb-10'>									
										<span className='bold'>{type.name}</span>
										<Form.Control
											as="select"
											className='inline'
											onChange={e => e.target.value ? handleAttach(type.id, e.target.value) : handleDetach(type.id)}
											value={current.value}
										>
											<option value="">No Aplica</option>
											<option value='Todas'>Aplica para todas las instalaciones</option>
											<option value='Enterrados'>Instalaciones con tanques enterrados</option>
											<option value='Superficiales'>Instalaciones con tanques superficiales</option>
										</Form.Control>
									</div>
								)
							}) }
						</>
					) : <Spinner />}
				</ModalBody>
			</Modal>
		</>
	)
}