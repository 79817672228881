import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Tooltip, Divider, Popconfirm, Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCashRegister, faCheckDouble, faEdit, faMoneyBill, faSearch, faToggleOn, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Badge, Col, Row } from 'reactstrap'
import moment from 'moment'

import EditProposalModal from './modals/EditProposalModal'
import ProposalPreInvoicesModal from '../../PreInvoices/ProposalPreInvoicesModal'
import ProposalAcceptanceModal from './modals/ProposalAcceptanceModal'
import ProposalPaymentsModal from '../../PaymentReceipts/ProposalPaymentsModal'

import PermissionIsRequired from '../../../components/PermissionIsRequired'
import Pagination from '../../../components/Pagination'

import { deleteProposal, updateProposal } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function ProposalsTable({ proposals, reload, pagination, setPagination }) {
	const [preInvoicesModal, setPreInvoicesModal] = useState(null)
	const [acceptanceModal, setAcceptanceModal] = useState(null)
	const [paymentReceiptsModal, setPaymentReceiptsModal] = useState(null)
	const [editProposalModal, setEditProposalModal] = useState(null)

	const handleDelete = id => {
		deleteProposal(id)
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	const handlePublishProposal = id => {
		updateProposal(id, { 
			published_at: moment().format('YYYY-MM-DD HH:mm:ss') 
		})
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}
	
	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			render: (t, r) => <Link to={`/proposals/${r.id}`}>{r.sku}</Link>,
			width: '130px'
		},
		{
			title: 'Elaboración',
			dataIndex: 'elaborated_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase(),
			width: '120px'
		},
		{
			title: 'Empresa',
			dataIndex: 'inquiry',
			render: t => t.branch_spec.branch.company_name
		},
		{
			title: 'Instalación',
			dataIndex: 'inquiry',
			render: t => t.branch_spec.branch.name
		},
		{
			title: 'Tipo Instalación',
			dataIndex: 'inquiry',
			render: t => t.branch_spec.branch.instalation_type_name
		},
		{
			title: 'Fecha Envío',
			dataIndex: 'created_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase(),
			width: '120px'
		},
		{
			title: 'Cta de Cobro',
			dataIndex: 'pre_invoices',
			render: t => t.length > 0 ? (
				<>
					<Badge color="success">
						{t.length} Enviada{t.length > 1 && 's'}
					</Badge>
					<Badge color={t.filter(c => c.invoice_attachment).length > 0 ? 'success' : 'warning'}>
						{t.filter(c => c.invoice_attachment).length} Facturas
					</Badge>
				</>
			) : (
				<Badge color='danger'>No enviada</Badge>
			)
		},
		{
			title: 'Estado',
			dataIndex: 'accepted_at',
			render: (t,r) => {
				return <Badge color={r.status.color}>{r.status.label}</Badge>
			}
		},
		{
			title: 'Acciones',
			width: '220px',
			render: (t, r) => (
				<>
					<Tooltip title="Perfil de Propuesta">
						<Link to={`/proposals/${r.id}`}>
							<FontAwesomeIcon className="text-link" icon={faSearch} />
						</Link>
					</Tooltip>
					{ (r.status.label === 'Publicada' || r.status.label === 'Aceptada') && (
						<>
							<Divider type="vertical" />
							<Tooltip title="Cuentas de Cobro">
								<div className='inline' onClick={() => setPreInvoicesModal(r.id)}>
									<FontAwesomeIcon className="text-link" icon={faMoneyBill} />
								</div>
							</Tooltip>
							<Divider type="vertical" />
							<Tooltip title="Aceptación de Propuesta">
								<div className='inline' onClick={() => setAcceptanceModal(r)}>
									<FontAwesomeIcon className='text-link' icon={faCheckDouble} />
								</div>
							</Tooltip>
							<Divider type="vertical" />
							<Tooltip title="Soportes de Pagos">
								<div className='inline' onClick={() => setPaymentReceiptsModal(r.id)}>
									<FontAwesomeIcon className="text-link" icon={faCashRegister} />
								</div>
							</Tooltip>
						</>
					)}
					{ r.status.label === 'En Borrador' && can('Actualizar propuestas') && (
						<>
							<Divider type="vertical" />
							<Tooltip title="Editar Propuesta">
								<div className='inline' onClick={() => setEditProposalModal(r)}>
									<FontAwesomeIcon className="text-link" icon={faEdit} />
								</div>
							</Tooltip>
							<Divider type="vertical" />
							{ can('Publicar Propuestas') && (
								<Tooltip title="Publicar Propuesta">
									<Popconfirm
										title="¿Desea publicar propuesta?"
										okText="Publicar"
										cancelText="Cancelar"
										onConfirm={() => handlePublishProposal(r.id)}
										okButtonProps={{ danger:true }}
									>
										<FontAwesomeIcon className='text-link' icon={faToggleOn} />
									</Popconfirm>
								</Tooltip>
							)}
						</>
					)}
					{ can('Eliminar propuestas') && (r.status.label === 'En Borrador' || r.status.label === 'Publicada' || r.status.label === 'Aceptada') && (
						<>
							<Divider type="vertical" />
							<Tooltip title="Eliminar Propuesta">
								<Popconfirm 
									title="¿Desea eliminar propuesta?"
									okText="Eliminar"
									cancelText="Cancelar"
									onConfirm={() => handleDelete(r.id)}
									okButtonProps={{ danger:true }}
								>
									<FontAwesomeIcon className="text-link" icon={faTrash} />{" "}
								</Popconfirm>
							</Tooltip>
						</>
					)}
				</>
			)
		}
	]

	const expandedRow = r => (
		<>
			<Row>
				<Col>
					<p className='mb-10'><strong>Email:</strong> {r.inquiry.branch_spec.branch.email ? r.inquiry.branch_spec.branch.email : <em className='hint-text'>Sin email registrado</em>}</p>
					<p className='mb-10'><strong>Celular:</strong> {r.inquiry.branch_spec.branch.mobile}</p>
					<p className='mb-10'><strong>Fijo:</strong> {r.inquiry.branch_spec.branch.phone ? r.inquiry.branch_spec.branch.phone : <em className='hint-text'>Sin teléfono fijo registrado</em>}</p>
				</Col>
				<Col>
					<p className='mb-10'><strong>Bandera:</strong> {r.inquiry.branch_spec.branch.flag}</p>
					<p className='mb-10'><strong>SICOM:</strong> {r.inquiry.branch_spec.branch.sicom_code}</p>
					<p className='mb-0'><strong>Constructor:</strong> {r.inquiry.branch_spec.branch.constructor_name ? r.inquiry.branch_spec.branch.constructor_name : <em className='hint-text'>Sin nombre de constructor registrado</em>}</p>
				</Col>
			</Row>
		</>
	)

	if(!can('Ver listado de propuestas'))
		return <PermissionIsRequired title="No tiene permisos para ver propuestas comerciales" />
	
	return (
		<>
			<Table 
				rowKey={r => r.id}
				dataSource={proposals}
				loading={!proposals}
				columns={columns}
				expandable={{ expandedRowRender: expandedRow }}
				size="small"
				pagination={false}
				className="mb-15"
			/>
			<Pagination
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			{ preInvoicesModal && (
				<ProposalPreInvoicesModal 
					visible
					onClose={() => setPreInvoicesModal(null)}
					proposalId={preInvoicesModal}
					reload={reload}
				/>
			)}
			{ acceptanceModal && (
				<ProposalAcceptanceModal 
					visible
					onClose={() => setAcceptanceModal(null)}
					proposal={acceptanceModal}
					reload={() => {
						setAcceptanceModal(null)
						reload()
					}}
				/>
			)}
			{ paymentReceiptsModal && (
				<ProposalPaymentsModal 
					visible
					onClose={() => setPaymentReceiptsModal(null)}
					proposalId={paymentReceiptsModal}
				/>
			)}
			{ editProposalModal && (
				<EditProposalModal 
					visible
					onClose={() => setEditProposalModal(null)}
					proposal={editProposalModal}
					reload={() => {
						setEditProposalModal(null)
						reload()
					}}
				/>
			)}
		</>
	)
}