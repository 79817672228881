import React from 'react'
import styled from 'styled-components'

import DocumentHeader from '../../../../components/DocumentHeader'
import moment from 'moment'
import { text } from '../../../../helpers'

export default function INQUIRY_TEMPLATE_PRU02({ inquiry }) {

	const branch = inquiry.branch_spec.branch
	const company = branch.company

	return (
		<Container>
			<DocumentHeader 
				title="SOLICITUD DE SERVICIO"
				version={inquiry.format_version.sku}
				expireAt={inquiry.format_version.expire_at}
			/>
            <SectionContent center>En DEWAR SAS contamos con acreditación ONAC, vigente a la fecha,  con código de acreditación 21-OIN-008, bajo la norma NTC ISO/IEC 17020:2012</SectionContent>
			<SectionTitle center>VERACIDAD Y SUFICIENCIA DE LA INFORMACIÓN</SectionTitle>
			<SectionContent>En esta solicitud debe diligenciarse con información veraz, declarando la totalidad de la información que se solicita, para analizar la competencia y alcance de DEWAR. Los datos suministrados en este formato serán verificados durante el proceso y corresponderán a los datos consignados en los documentos resultantes del mismo.                                                                                                                        La información aquí consignada será tratada bajo cumplimiento del principio de confidencialidad.</SectionContent>
			<SectionTitle center>DILIGENCIAR TODA LA INFORMACIÓN - Este formulario debe enviarse con el RUT al correo electrónico: comercial@dewar.com.co para cualquier orientación puede comunicarse al CELULAR 3163702077</SectionTitle>

			<SectionTitle style={{ marginTop: '10px' }}>1. INFORMACIÓN DE LA EMPRESA QUE SOLICITA EL SERVICIO: *Razón social y NIT de quien sería titular del certificado</SectionTitle>
			<div className="p-0">
				<table className='table m-0'>
					<tbody>
						<tr>
							<td width="100px" className='bg-light'>Razón Social*:</td>
							<td colSpan={5}>{company.name}</td>
                        </tr>
                        <tr>
							<td width='80px' className='bg-light'>NIT*:</td>
							<td colSpan={3}>{company.document}</td>
                            <td className='bg-light'>Dígito de verificación*:</td>
                            <td>{company.verification_code}</td>
						</tr>
						<tr>
							<td colSpan={2} width="140px" className='bg-light'>Nombre Representante Legal:</td>
							<td colSpan={4}>{company.legal_owner_name}</td>
						</tr>
						<tr>
							<td className='bg-light' colSpan={2} width="140px">Dirección Empresa:</td>
							<td colSpan={4}>{company.address_line}</td>
						</tr>
                        <tr>
                            <td className='bg-light'>Departamento:</td>
                            <td colSpan={2}>{company.address_state}</td>
                            <td className='bg-light'>Cuidad/Municipio:</td>
                            <td colSpan={2}>{company.address_city}</td>
                        </tr>
						<tr>
                            <td className='bg-light'>Correo electrónico:</td>
                            <td>{company.email}</td>
                            <td className='bg-light'>Teléfono:</td>
                            <td>{company.phone}</td>
                            <td className='bg-light'>Celular:</td>
                            <td>{company.mobile}</td>
                        </tr>
                        <tr>
                            <td colSpan={5} className='bg-light'>Marque con X si es Agente retenedor de retención en la fuente:</td>
                            <td>{company.is_autorretenedor ? 'X' : ''}</td>
                        </tr>
					</tbody>
				</table>
                <SectionTitle>2. INSTALACIÓN DONDE SE PRESTA EL SERVICIO: Diligencie los campos requeridos solo si son diferentes a los de la Empresa</SectionTitle>
				<table className='table m-0'>
					<tbody>
                        <tr>
                            <td colSpan={2} className='bg-light'>Nombre de la instalación:</td>
                            <td colSpan={4}>{branch.name}</td>
                        </tr>
                        <tr>
                            <td className='bg-light'>Dirección:</td>
                            <td colSpan={3}>{branch.address_line}</td>
                            <td className='bg-light'>Bandera:</td>
                            <td>{branch.flag}</td>
                        </tr>
                        <tr>
                            <td className='bg-light'>Departamento:</td>
                            <td colSpan={2}>{branch.address_state}</td>
                            <td className='bg-light'>Municipio:</td>
                            <td colSpan={2}>{branch.address_city}</td>
						</tr>
						<tr>
							<td className='bg-light'>Correo electrónico:</td>
							<td>{branch.email}</td>
							<td className='bg-light'>Teléfono:</td>
							<td>{branch.phone}</td>
							<td className='bg-light'>Celular:</td>
							<td>{branch.mobile}</td>
						</tr>
                        <tr>
                            <td colSpan={2} className='bg-light'>Tipo de Instalación*:</td>
                            <td colSpan={4}>{branch.instalation_type_name}</td>
                        </tr>
                        <tr>
                            <td colSpan={6} className='bg-light'>* Los criterios de inspección de instalaciones se establecerán en la propuesta comercial dependiendo del tipo de Instalación.</td>
                        </tr>
                        <tr>
                            <td colSpan={2} rowSpan={2} className='bg-light'>Información de los Tanques de Combustible Líquido</td>
                            <td>Número de tanques:</td>
                            <td>{inquiry.branch_spec.total_tanques_enterrados + inquiry.branch_spec.total_tanques_superficiales}</td>
                            <td>Tanques Bicompartidos:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colSpan={2}>{text(inquiry.branch_spec.tanks_orientations)}</td>
                            <td>Subterráneos: {inquiry.branch_spec.total_tanques_enterrados}</td>
                            <td>Superficiales: {inquiry.branch_spec.total_tanques_superficiales}</td>
                        </tr>
                        <tr>
                            <td colSpan={2} className='bg-light'>Productos Almacenados</td>
                            <td colSpan={4}>
                                {text(inquiry.branch_spec.tanks_products)}
                            </td>
                        </tr>
					</tbody>
				</table>
			</div>

			<SectionTitle>3. DATOS DE LA PERSONA RESPONSABLE DE ATENDER EL SERVICIO:</SectionTitle>
			<div>
				<table className='table m-0'>
					<tbody>
						<tr>
							<td width="130px" className='bg-light'>Nombre Completo:</td>
							<td colSpan={2}>{inquiry.employee.name}</td>
							<td width="100px" className='bg-light'>Cargo:</td>
							<td colSpan={2}>{inquiry.employee.position}</td>
						</tr>
						<tr>
							<td width="120px" className='bg-light'>Correo electrónico:</td>
							<td>{inquiry.employee.email}</td>
							<td width="100px" className='bg-light'>Teléfono:</td>
							<td>{inquiry.employee.phone}</td>
							<td width="100px" className='bg-light'>Celular:</td>
							<td>{inquiry.employee.mobile}</td>
						</tr>
					</tbody>
				</table>
			</div>

			<SectionTitle>4. SERVICIO QUE SOLICITA</SectionTitle>
			<SectionContent className="text-center bold">{inquiry.test_details.service_name}</SectionContent>

			<SectionTitle>5. INFORMACIÓN ESPECIFICA PRUEBAS DE HERMETICIDAD:</SectionTitle>						
            <div>
                <table className='table m-0'>
                    <tbody>
                        <tr>
                            <td width="200px" className='bg-light'>Material de los Tanques:</td>
                            <td colSpan={3}>{text(inquiry.branch_spec.tanks_materials)}</td>
                        </tr>
                        <tr>
                            <td className='bg-light'>Altura aproximada del desfogue</td>
                            <td width="150px">{text(inquiry.test_details.aprox_altitude)}</td>
                            <td className='bg-light'>¿Tiene válvula para independizar el desfogue del tanque?</td>
                            <td>{text(inquiry.test_details.valve_present)}</td>
                        </tr>
                        <tr>
							<td colSpan={3} width="215px" className='bg-light text-right'>Fecha de las últimas pruebas de hermeticidad (dd/mm/aaaa):</td>
							<td colSpan={1}>{inquiry.test_details.last_test_at ? moment(inquiry.test_details.last_test_at).format('DD/MM/YYYY') : text(inquiry.test_details.last_test_at)}</td>
						</tr>
                    </tbody>
                </table>
            </div>

			<SectionTitle>6. INFORMACIÓN ESPECIFICA INSPECCIÓN INSTALACIONES DE COMBUSTIBLE</SectionTitle>
            <div>
                <table className='table m-0'>
                    <tbody>
                        <tr>
                            <td className='bg-light'>Código SICOM:</td>
                            <td className='bg-light'>Tiene Certificado Anterior:</td>
                            <td className='bg-light'>Organismo Certificador:</td>
                            <td className='bg-light'>Fecha de emisión:</td>
                        </tr>
                        <tr>
                            <td>{branch.sicom_code}</td>
                            <td>{inquiry.has_previous_certification ? 'SI' : 'NO'}</td>
                            <td>{text(inquiry.previous_competitor)}</td>
                            <td>{inquiry.previous_start_at ? moment(inquiry.previous_start_at).format('DD/MM/YYYY') : text(inquiry.previous_start_at)}</td>
                        </tr>
                        <tr>
                            <td colSpan={3} className='bg-light text-right'>La capacidad de almacenamiento total de los tanques a inspeccionar <br/> excede los 150.000 L (39.000 gal)</td>
                            <td>{inquiry.branch_spec.tanks_total_liters > 150000 ? 'SI' : 'NO'}</td>
                        </tr>
                        <tr>
                            <td rowSpan={2} className='bg-light'>6.1. Información Estaciones de Servicio</td>
                            <td className='bg-light'>Número de Islas:</td>
                            <td className='bg-light'>Nº de Pistolas-Mangueras:</td>
                            <td className='bg-light'>Nº de Dispensadores - Surtidores</td>
                        </tr>
                        <tr>
                            <td>{inquiry.branch_spec.total_islas}</td>
                            <td>{inquiry.branch_spec.total_mangueras}</td>
                            <td>{inquiry.branch_spec.total_surtidores}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <SectionTitle>7. REQUISITOS DEL CLIENTE:</SectionTitle>
            <SectionContent>Indique si para la realización de la inspección, el personal asignado DEWAR debe cumplir requisitos como actividades de capacitación, requisitos especificos para el ingreso a la instalación, o requisitos especificos de seguridad y salud en el trabajo. Entre otras observaciones.</SectionContent>
            <SectionContent>{inquiry.observations}</SectionContent>
			<div>
				<table className='table m-0'>
					<tbody>
						<tr>
							<td className='text-center bg-light'><b>AUTORIZACIÓN TRATAMIENTO DE DATOS PERSONALES:</b> Otorgo mi consentimiento a <b>DEWAR SAS</b> para tratar mi información personal, de acuerdo con la política de tratamiento de datos personales, en concordancia con la Ley 1581 de 2012, y el Decreto reglamentario 1377 de 2013</td>
							<td className='bg-light text-center bold pt-20' width="180px">ACEPTO</td>
						</tr>
					</tbody>
				</table>
				<table className='table m-0'>
					<tbody>
						<tr>
							<td width="135px" className='bold bg-light'>Nombre de quien envía la solicitud:</td>
							<td>{inquiry.sent_by}</td>
							<td width="100px" className='bold bg-light'>Celular:</td>
							<td>{inquiry.sent_by_mobile}</td>
							<td width="110px" className='bold bg-light'>Fecha de envío (dd/mm/aaaa):</td>
							<td>{moment(inquiry.sent_at).format('DD/MM/YYYY')}</td>
						</tr>
					</tbody>
				</table>
			</div>
            <SectionTitle center>Norma frente a las cuales se realiza el proceso de inspección - Pruebas de Hermeticidad</SectionTitle>
            <SectionContent center className='bg-light'>Requisitos del numeral 5.6.2 Pruebas periódicas literal m y n de la Resolución No. 40198 del 24 de junio de 2021 y de la Resolución No. 40405 del 24 de diciembre de 2020, expedidas por Ministerio de Minas y Energía; y numerales 21.5 y 27.7 de la  NFPA 30 Código de Líquidos inflamables y combustibles</SectionContent>
            <SectionTitle center>Norma frente a las cuales se realiza el proceso de inspección - Instalaciones de Combustibles</SectionTitle>
            <SectionContent center className='bg-light'>Resolución No. 40198 del 24 de junio de 2021, “Por la cual se modifica la Resolución No. 40405 del 24 de diciembre de 2020 “Por la cual se expide el reglamento técnico aplicable a las Estaciones de Servicio, Plantas de Abastecimiento, Instalaciones del Gran Consumidor con Instalación Fija y Tanques de Almacenamiento del consumidor final, que sean nuevos o existentes, que almacenen biocombustibles, crudos y/o combustibles líquidos derivados del petróleo, y sus mezclas de los mismos con biocombustibles, excepto GLP”, y de la Resolución No. 40405 del 24 de diciembre de 2020, expedidas por Ministerio de Minas y Energía.</SectionContent>
            <SectionTitle center>DECLARACIÓN DE IMPARCIALIDAD E INDEPENDENCIA</SectionTitle>
            <SectionContent center className='bg-light'>DEWAR realiza el proceso de inspección de manera imparcial e independiente de entidades relacionadas dedicadas a la propiedad, diseño, construcción / fabricación, suministro, instalación o mantenimiento de las instalaciones objeto de inspección. Las decisiones que se tomen en los procesos de inspección corresponden específicamente a los criterios del Reglamento Técnico objeto de evaluación.</SectionContent>
            <SectionTitle center>DECLARACIÓN DE CONFIDENCIALIDAD</SectionTitle>
            <SectionContent center className='bg-light'>Con la información suministrada en este formulario se elaborará la propuesta comercial donde se especificarán las condiciones contractuales del servicio de inspección y los requerimientos y documentos que la instalación debe aportar para la prestación del servicio.</SectionContent>
            <SectionContent center className='bold bg-light'>De ser requerida información adicional será solicitada al cliente, una vez se revise la presente solicitud de servicio.</SectionContent>
		</Container>
	)
}

const Container = styled.div`
	font-family: 'Arial';
	font-size: 13px;
	line-height: 15px;
	width: 21.59cm;
	// height: 27.94cm;
	margin 0 auto;
	background-color: white;
	padding: 0.5cm;
	overflow: hidden;
	& table tr td {
		border: 1px solid black;
		padding: 5px 3px;
	}
`

const SectionTitle = styled.div`
	background-color: #fdeada;
	font-weight: bold;
	padding: 2px 6px;
	border: 1px solid black;
	margin: 0px;
	color: black;
	text-align: ${props => props.center ? 'center' : 'left'};
`

const SectionContent = styled.div`
	padding: 5px;
	font-size: 12px;
	border: 1px solid black;
	margin: 0px;
	color: black;
    text-align: ${props => props.center ? 'center' : 'left'};
`