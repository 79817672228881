import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Alert } from 'antd'
import { Form } from 'react-bootstrap'

import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { updateServiceInspector } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function RejectServiceModal({ visible, onClose, serviceInspector, reload }) {
	const [rejectComment, setRejectComment] = useState('')

	const onSubmit = () => {
		updateServiceInspector(serviceInspector.id, { 
			reject_comment: rejectComment
		})
			.then(res => handleSuccess(res.data.message, reload, onClose))
			.catch(error => handleError(error))
	}

	if(!can('Actualizar inspectores de servicios'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar asignaciones" onClose={onClose} />

	return (
		<Modal isOpen={visible}>
			<ModalHeader toggle={onClose}>Rechazar Asignación</ModalHeader>
			<ModalBody>
				<Alert description="Al rechazar la asignación no podrá reversar este proceso" type='warning' />
				<Form.Group>
					<Form.Label>Razón de rechazo de asignación</Form.Label>
					<Form.Control 
						as="textarea"
						value={rejectComment}
						onChange={e => setRejectComment(e.target.value)}
						placeholder="Escribir aquí..."
						style={{ height: '100px' }}
					/>
				</Form.Group>
				<Button color="primary" className='mt-10' onClick={onSubmit} disabled={!rejectComment}>
					Rechazar Asignación
				</Button>
			</ModalBody>
		</Modal>
	)
}