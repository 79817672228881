import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { Divider, Popconfirm, Tooltip } from 'antd'
import styled from 'styled-components'

import EmailLogsModal from '../../../EmailLogs/EmailLogsModal'
import ProposalFormPreview from '../ProposalFormPreview'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInbox, faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import { notifyProposal } from '../../services'
import { can, handleError, handleSuccess } from '../../../../helpers'

export default function ProposalDetailsTab({ proposal, reload }) {
    const [emailLogsModal, setEmailLogsModal] = useState(null)

    const handleProposalNotify = () => {
        notifyProposal(proposal.id)
            .then(res => handleSuccess(res.data.message, reload))
            .catch(error => handleError(error))
    }

    return (
        <>
            <div className='mb-10 mt-10 text-right'>
                { proposal.status.label !== 'Publicada' && proposal.status.label !== 'Rechazada' && can('Enviar Notificación') && (
                    <>
                        <Tooltip title="Enviar Notificación">
                            <Popconfirm 
                                title="¿Desea enviar notificación?"
                                okText="Enviar"
                                cancelText="Cancelar"
                                onConfirm={() => handleProposalNotify()}
                                okButtonProps={{ danger:true }}
                            >
                                <Button color='primary' outline>
                                    <FontAwesomeIcon className="text-link" icon={faPaperPlane} />{" "}
                                    Enviar Notificación
                                </Button>
                            </Popconfirm>
                        </Tooltip>
                    </>
                )}
                { proposal.published_at && (
                    <>
                        <Divider type="vertical" />
                        <Tooltip title="Correos Enviados">
                            <Button color='primary' outline onClick={() => setEmailLogsModal()}>
                                <FontAwesomeIcon className="text-link" icon={faInbox} />{" "}
                                Correos Enviados
                            </Button>
                        </Tooltip>
                    </>
                )}
            </div>
            <PreviewContainer>
                <p className='bold mb-10'>Previsualización del Formato</p>
                <ProposalFormPreview 
                    form={{
                        ...proposal,
                        format_template: proposal.format_version.format_template
                    }}
                    inquiry={proposal.inquiry}
                />
            </PreviewContainer>
            { emailLogsModal && (
                <EmailLogsModal 
                    visible
                    onClose={() => setEmailLogsModal(null)}
                    modelType="proposal"
                    modelId={proposal.id}
                />
            )}
        </>
    )
}

const PreviewContainer = styled.div`
    padding: 10px;
    max-height: 500px;
    overflow-y: auto;
`