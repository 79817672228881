import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { InboxOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { Upload } from "antd"

import { storeAttachment } from '../services'

const { Dragger } = Upload

export default function UploadAttachment({ 
    scope, attachableType, attachableId, onSuccess, defaultLabel = ''
}) {
    const [label, setLabel] = useState(defaultLabel)

    const props = {
        multiple: false,
        customRequest: async ({ file, onError, onSuccess: handleSuccess }) => {
            const fileExt = file.name.split('.').pop().toLowerCase()

            const values = new FormData()
            values.append('file', file)
            values.append('file_path', `app/${attachableType}/${attachableId}/${scope}/${new Date().getTime()}.${fileExt}`)
            values.append('attachable_type', attachableType)
            values.append('attachable_id', attachableId)
            values.append('scope', scope)
            values.append('label', label)

            try {
                const response = await storeAttachment(values)
                handleSuccess(response.data, file)
                onSuccess(response.data.url)
            } catch (error) {
                onError(error)
                console.error('Error al subir el archivo: ', error.message)
            }
        }
    };

    return (
        <>
            <Form.Group>
                <Form.Label>Etiqueta del Archivo <span className='text-danger'>*</span></Form.Label>
                <Form.Control 
                    value={label}
                    onChange={e => setLabel(e.target.value)}
                    className="full-width"
                    placeholder='Escriba aquí la etiqueta...'
                />
            </Form.Group>
            <Dragger {...props} disabled={!label}>
                <p className="ant-upload-drag-icon">
                    { label ? <InboxOutlined /> : <MinusCircleOutlined style={{ color:'#888' }} />}
                </p>
                <p className="ant-upload-text">
                    { label ? 'Haga clic o arrastre el adjunto' : 'Ingrese la Etiqueta Primero' }
                </p>
                <p className="ant-upload-hint">
                    Solo se permite adjuntar un archivo menor a 5MB.
                </p>
            </Dragger>
        </>
    )
}
