import dewarApi, { getDomain } from '../../services/dewarApi'

export const getUsers = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/users', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeUser = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/users', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateUser = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/users/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteUser = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/users/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchInspectors = async (name, token) => (
	fetch( getDomain()+`/users?filter[name]=${name}&filter[role]=inspector&filter[is_active]=1`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(user => ({
				label: user.name,
				value: user.id,
			})),
		)
)

export const syncRolesUser = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/users/${id}/sync_roles`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)