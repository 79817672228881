import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { InputGroup } from 'react-bootstrap'
import { message } from 'antd'

import ServerSideSelect from '../../../components/ServerSideSelect'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storeReportDefaultEvidence } from '../services'
import { searchEvidenceTemplatesByItem } from '../../EvidenceTemplates/services'
import { can, handleError } from '../../../helpers'

export default function NewReportDefaultEvidenceForm({ instalationType, reload }) {
    const [selectedEvidenceTemplate, setSelectedEvidenceTemplate] = useState([])

    const onSubmit = () => {
		storeReportDefaultEvidence({ 
			instalation_type_id: instalationType.id, 
			evidence_template_id: selectedEvidenceTemplate.value
		})
			.then(res => {
				message.success(res.data.message)
				reload()
				setSelectedEvidenceTemplate([])
			})
			.catch(error => handleError(error))
	}

    if(!can('Crear evidencias por defecto para el informe'))
        return <PermissionIsRequired type="text" title="No tiene permisos para crear evidencia por defecto" />

    return (
        <>
            <InputGroup className='mt-10' >
                <ServerSideSelect 
                    value={selectedEvidenceTemplate}
                    placeholder="Buscar por item"
                    fetchOptions={searchEvidenceTemplatesByItem}
                    onChange={value => setSelectedEvidenceTemplate(value)}
                    className="full-width mb-10"
                />
                <Button 
                    className='btn-inputgroup' 
                    color="primary" 
                    disabled={!selectedEvidenceTemplate.value} 
                    onClick={onSubmit}
                >
                    Asociar Evidencia
                </Button>
            </InputGroup>
        </>
    )
}