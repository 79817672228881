import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import { getPermissions, syncPermissionsRole } from '../services'
import { handleError, handleSuccess } from '../../../helpers'

export default function EditRolePermissionsModal({ visible, onClose, role, reload }) {
    const [permissions, setPermissions] = useState(null)
    const { register, handleSubmit } = useForm({
        defaultValues: {
            permissions: role.permissions.map(p => p.name)
        }
    })

    useEffect(() => {
        !permissions && getPermissions({
            'filter[adminWebappEnable]': true
        })
            .then(res => setPermissions(res.data.data))
            .catch(error => handleError(error))
    }, [permissions])

    const onSubmit = values => {
        if(values.permissions.length === 0)
            return message.warning('Debe seleccionar al menos un permiso')

        syncPermissionsRole(role.id, values)
            .then(res => handleSuccess(res.data.message, reload, onClose))
            .catch(error => handleError(error))
    }

    return (
        <Modal isOpen={visible}>
            <ModalHeader toggle={onClose}>Editar Permisos del Rol</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    { permissions ? (
                        <>
                            { permissions.map(permission => (
                                <Form.Check
                                    key={permission.id}
                                    type="checkbox"
                                    label={permission.label}
                                    value={permission.name}
                                    {...register('permissions[]')}
                                />
                            )) }
                        </>
                    ) : (
                        <Spinner animation='border' size="sm" />
                    )}
                    <Button color="primary" type="submit">
                        Sincronizar Permisos
                    </Button>
                </Form>
            </ModalBody>
        </Modal>
    )
}