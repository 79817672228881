import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import BranchesList from './BranchesList'

export default function Branches() {
	return (
		<>
			<Switch>
				<Route exact path="/branches" component={BranchesList} />

				<Redirect to="/404" />
			</Switch>
		</>
	)
}