import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'

import UserForm from './UserForm'

import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storeUser } from '../services'
import { can, handleError } from '../../../helpers'

export default function NewUserForm({ reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const onSubmit = values => {
		storeUser(values)
			.then(() => {
				message.success('Usuario creado exitosamente.')
				reload()
				reset()
			})
			.catch(error => handleError(error))
	}

	if(!can('Crear usuarios'))
		return <PermissionIsRequired type="warning" title="No tiene permisos para crear usuarios" />

	return (
		<>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<UserForm 
					register={register} 
					errors={errors}
				/>
				<Button type="submit" color="primary">Crear Usuario</Button>
			</Form>
		</>
	)
}