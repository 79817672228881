import React from 'react'

import DoubleScopesBar from '../../../components/DoubleScopesBar'

export default function InquiriesListScopes({ 
    filterBusinessScope, setFilterBusinessScope, filterStatusScope, setFilterStatusScope, reload 
}) {

    const businessScopes = [
        { label: 'Servicios de Inspección', value: 'inspeccion' },
        { label: 'Pruebas de Tanques y Tuberías', value: 'pruebas' },
    ]

    const statusScopes = [
        { label: 'Sin Propuesta', value: 'withoutProposal' },
        { label: 'Con Propuesta', value: 'withProposal' },
    ]

    return (
        <>
            <DoubleScopesBar
                businessScopes={businessScopes}
                statusScopes={statusScopes}
                filterBusinessScope={filterBusinessScope}
                setFilterBusinessScope={setFilterBusinessScope}
                filterStatusScope={filterStatusScope}
                setFilterStatusScope={setFilterStatusScope}
                reload={reload}
            />
        </>
    )
}
