import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { offlineUpdateReviewLogbook } from '../../../Services/actions'
import { queueRequest } from '../../../Offline/actions'

import { updateReviewLogbook } from '../../services'
import { can, handleError, handleSuccess } from '../../../../helpers'

export default function EditReviewLogbookOrderModal({ visible, onClose, reviewLogbook, serviceId, reload }) {
	const { isConnected } = useSelector(state => state.offline)
    const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors }} = useForm({
        defaultValues: {
            order: reviewLogbook.order,
        }
    })

    const onSubmit = values => {
        if(isConnected) {
            updateReviewLogbook(reviewLogbook.id, values)
                .then(res => handleSuccess(res.data.message, reload, onClose))
                .catch(error => handleError(error, setLoading))
        } else {
            dispatch(offlineUpdateReviewLogbook(values, serviceId, reviewLogbook.id))
			dispatch(queueRequest({ url: `/review_logbooks/${reviewLogbook.id}`, data: values }))
			message.info('Actualización en espera de internet')
			onClose()
        }
    }

    if(!can('review_logbooks.update'))
        return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar bitacoras" onClose={onClose} />

    return (
        <Modal isOpen={visible}>
            <ModalHeader toggle={onClose}>Actualizar Bitacora de Inspección</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label>Orden de Visualización <span className='text-danger'>*</span></Form.Label>
                        <Form.Control 
                            type="number"
                            {...register('order', { required:true, min: 0 })}
                            defaultValue={10}
                        />
                        { errors.number && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                    </Form.Group>
                    <Button color='primary' type="submit" disabled={loading}>
                        { loading && <Spinner animation='border' size="sm" /> }
                        Actualizar Bitacora
                    </Button>
                </Form>
            </ModalBody>
        </Modal>
    )
}