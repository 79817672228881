import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { Alert, message } from 'antd'

import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { offlineUpdateReview } from '../../../Services/actions'
import { queueRequest } from '../../../Offline/actions'

import { updateServiceReview } from '../../services'
import { can, handleError, handleSuccess } from '../../../../helpers'

export default function ServiceReviewResultModal({ visible, onClose, serviceReview, reload, serviceId }) {
	const { isConnected } = useSelector(state => state.offline)
	const { register, handleSubmit, formState: { errors } } = useForm()
	const dispatch = useDispatch()

	const onSubmit = values => {
		if(isConnected){
			updateServiceReview(serviceReview.id, values)
				.then(res => handleSuccess(res.data.message, reload))
				.catch(error => handleError(error))
		} else {
			dispatch(offlineUpdateReview({ is_compliant: parseInt(values.is_compliant) }, serviceId))
			dispatch(queueRequest({ url: `/service_reviews/${serviceReview.id}`, data: values }))
			message.info('Actualización en espera de internet')
			onClose()
		}
	}

	if(!can('Actualizar Acta de inspección'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar actas de inspección" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Resultado de la Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
							<Form.Label>Resultado Final</Form.Label>
							<Form.Control
								as="select"
								{...register('is_compliant', { required: true })}
								defaultValue={serviceReview && serviceReview.is_compliant ? '1' : '0'}
							>
								<option value="">:: Seleccione el resultado ::</option>
								<option value="1" disabled={(serviceReview && isConnected && serviceReview.total_nc_tasks > 0) ? true : false}>Conforme</option>
								<option value="0">No Conforme</option>
							</Form.Control>
							{ errors.is_compliant && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Alert type="warning" className='mt-20 mb-20' description="Una vez confirme este resultado final no podra cambiarlo" />
						<Button color="primary" type="submit">Confirmar Resultado Final</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}