import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { Divider, Popconfirm } from 'antd'

import ReviewLogbookTable from './partials/ReviewLogbookTable'
import NewReviewLogbookModal from './partials/modals/NewReviewLogbookModal'

import { getReviewLogbooks } from './services'
import { handleError, handleSuccess } from '../../helpers'
import { serviceNotifyDirector } from '../Services/services'

export default function ReviewLogbookTab({ serviceReview, service }) {
	const { isConnected } = useSelector(state => state.offline)
    const [reviewLogbooks, setReviewLogbooks] = useState(null)
    const [showNewLogbookModal, setShowNewLogbookModal] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        !reviewLogbooks && getReviewLogbooks({
            'filter[service_review_id]': serviceReview.id,
            include: 'formatVersion',
            sort: 'order'
        })
            .then(res => setReviewLogbooks(res.data.data))
            .catch(error => handleError(error))
    }, [ reviewLogbooks, serviceReview ])

    if(!reviewLogbooks) return <Spinner animation='border' />

    const sendServiceToDirector = () => {
        setLoading(true)
        serviceNotifyDirector({}, service.id)
            .then(res => handleSuccess(res.data.message, () => setLoading(false)))
            .catch(error => handleError(error, setLoading))
    }

    return (
        <>
            <h6 className='mt-10 mb-15 text-center'>Bitacoras de Inspección</h6>
            <ReviewLogbookTable 
                reviewLogbooks={reviewLogbooks}
                reload={() => setReviewLogbooks(null)}
                serviceReview={serviceReview}
                serviceId={service.id}
            />
            <Button color='primary' outline onClick={() => setShowNewLogbookModal(true)} disabled={!isConnected}>
                Agregar Nueva Bitacora
            </Button>
            <Divider type='vertical' />
            <Popconfirm
                title="Se enviara un correo al director"
                okText="Notificar"
                cancelText="Cancelar"
                onConfirm={sendServiceToDirector}
                disabled={loading}
            >
                <Button color='primary' outline disabled={loading || !isConnected}>
                    { loading && <Spinner animation='border' size="sm" className='mr-5' /> }{" "}
                    Notificar Director
                </Button>
            </Popconfirm>
            { showNewLogbookModal && (
                <NewReviewLogbookModal
                    visible
                    onClose={() => setShowNewLogbookModal(false)}
                    reload={() => setReviewLogbooks(null)}
                    serviceReviewId={serviceReview.id}
                />
            )}
        </>
    )
}