import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, Spinner, Row, Col } from 'reactstrap'
import { Empty, Tooltip } from 'antd'

import { getAttachments } from '../../Attachments/services'
import { handleError, handleSuccess } from '../../../helpers'
import { storeReportEvidence } from '../services'

export default function ExistingEvidenceModal({ visible, onClose, serviceReport, reload }) {
    const [attachments, setAttachments] = useState(null)

    useEffect(() => {
        const serviceIds = [ serviceReport.service_review.service_plan.service_inspector.service.id ]
        if(serviceReport.service_review.service_plan.service_inspector.service.initial_service) 
            serviceIds.push(serviceReport.service_review.service_plan.service_inspector.service.initial_service.id)
        if(serviceReport.service_review.service_plan.service_inspector.service.retry_service)
            serviceIds.push(serviceReport.service_review.service_plan.service_inspector.service.retry_service.id)
    
        !attachments && visible && getAttachments({
            'filter[attachable_type]': 'service',
            'filter[attachable_id]': serviceIds.join(','),
            'filter[scope]': 'evidence'
        })
            .then(res => setAttachments(res.data.data))
            .catch(handleError)
    }, [attachments, visible, serviceReport])

    const handleAddEvidence = (attachment) => {
        storeReportEvidence({
            service_report_id: serviceReport.id,
            service_review_id: serviceReport.service_review.id,
            attachment_uri: attachment.url,
        })
            .then(res => handleSuccess(res.data.message, reload, onClose))
            .catch(error => handleError(error))
    }

	return (
		<Modal isOpen={visible} size="lg">
			<ModalHeader toggle={onClose}>Seleccionar Evidencia Existente</ModalHeader>
            <ModalBody>
                { attachments ? (
                    <React.Fragment>
                        { attachments.length > 0 ? (
                            <Row>
                                { attachments.map(attachment => (
                                    <Col key={attachment.id} xs={12} sm={6} md={4} lg={3} xl={2}>
                                        <Tooltip title="Agregar a informe" onClick={() => handleAddEvidence(attachment)}>
                                            <img src={attachment.attachment_uri} alt={attachment.id} width="100%" />
                                        </Tooltip>
                                    </Col>
                                )) }
                            </Row>
                        ) : (
                            <Empty 
                                description="No hay evidencias relacionadas"
                                imageStyle={{ height: '70px' }}
                            />
                        )}
                    </React.Fragment>
                ) : <Spinner />}
            </ModalBody>
		</Modal>
	)   
}