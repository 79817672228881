import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import InquiriesList from './InquiriesList'
import InquiryPreview from './InquiryPreview'
import InquiryWizard from './InquiryWizard'

export default function Inquiries() {
	return (
		<>
			<Switch>
				<Route exact path="/inquiries" component={InquiriesList} />
				<Route exact path="/inquiries/wizard" component={InquiryWizard} />
				<Route exact path="/inquiries/preview/:id" component={InquiryPreview} />

				<Redirect to="/404" />
			</Switch>
		</>
	)
}