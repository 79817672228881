import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

export default function UserEquipmentForm({ register, errors }) {
	return (
		<>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Tipo de Servicio <span className='text-danger'>*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('business_scope', { required:true })}
					>
						<option value="inspeccion">Inspección</option>
						<option value="pruebas">Pruebas</option>
					</Form.Control>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Ítem <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('item', { required:true })}
						placeholder='Escriba aquí...'
						type="number"
					/>
					{ errors.item && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Equipo <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('name', { required:true })}
						placeholder='Escriba aquí...'
					/>
					{ errors.name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Marca <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('brand', { required:true })}
						placeholder='Escriba aquí...'
					/>
					{ errors.brand && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Serial <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('serial', { required:true })}
						placeholder='Escriba aquí...'
					/>
					{ errors.serial && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Código Interno <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('code', { required:true })}
						placeholder='Escriba aquí...'
					/>
					{ errors.code && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Fecha de calibración / verificación <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('verified_at', { required:true })}
					placeholder='Escriba aquí...'
					type="date"
				/>
				{ errors.verified_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Fecha de Entrega a Inspector <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('last_delivered_at', {})}
					placeholder='Escriba aquí...'
					type="date"
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Equipo Predefinido <span className='text-danger'>*</span></Form.Label>
				<Form.Control
					as="select"
					{...register('is_predifined', { required:true })}
				>
					<option value="">:: Seleccione una opción ::</option>
					<option value="1">Sí</option>
					<option value="0">No</option>
				</Form.Control>
				<Form.Text className='text-muted small'>Los equipos predefinidos serán lo que se tomen por defecto al crearse una nueva Acta de Inspección</Form.Text>
				{ errors.is_predifined && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Observaciones <small><em>Opcional</em></small></Form.Label>
				<Form.Control 
					{...register('observations', {})}
					placeholder='Escriba aquí...'
					as="textarea"
					style={{ minHeight: '70px' }}
				/>
			</Form.Group>
		</>
	)
}