import React from 'react'
import { Divider, Empty, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { can, handleError, handleSuccess } from '../../../helpers'
import { deletePaymentReceipt } from '../services'

export default function PaymentReceiptsTable({ paymentReceipts, reload }) {

	const handleDelete = id => {
		deletePaymentReceipt(id)
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}
	
	const columns = [
		{
			title: 'ID',
			dataIndex: 'id'
		},
		{
			title: 'Fecha de Registro',
			dataIndex: 'created_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase()
		},
		{
			title: 'Método de Pago',
			dataIndex: 'payment_method',
		},
		{
			title: 'Comentarios',
			dataIndex: 'comments',
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<>
					<Tooltip title="Ver Soporte">
						<a href={r.attachment_uri} target="_blank" rel="noreferrer">
							<FontAwesomeIcon className="text-link" icon={faSearch} />
						</a>
					</Tooltip>
					{ can('Eliminar recibos de pagos') && (
						<>
							<Divider type="vertical" />
							<Tooltip title="Eliminar Soporte">
								<Popconfirm 
									title="¿Desea eliminar el soporte de pago?"
									okText="Eliminar"
									cancelText="Cancelar"
									onConfirm={() => handleDelete(r.id)}
									okButtonProps={{ danger:true }}
								>
									<FontAwesomeIcon className="text-link" icon={faTrash} />{" "}
								</Popconfirm>
							</Tooltip>
						</>
					)}
				</>
			)
		}
	]

	if(!can('Ver listado de recibos de pagos'))
		return <PermissionIsRequired title="No tiene permisos para ver soportes de pagos" />
	
	return (
		<>
			<Table 
				dataSource={paymentReceipts}
				columns={columns}
				rowKey={r => r.id}
				size='small'
				loading={!paymentReceipts}
				locale={{ emptyText: <Empty description="No hay soportes de pagos registrados" /> }}
			/>
		</>
	)
}