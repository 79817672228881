import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'
import { Alert } from 'antd'

import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { updateServiceReport } from '../../services'
import { can, handleError, handleSuccess } from '../../../../helpers'

export default function ReportCommitteeResultModal({ visible, onClose, serviceReport, reload }) {
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: {
			committee_sku_prefix: 'CTN'
		}
	})

	const onSubmit = values => {
		updateServiceReport(serviceReport.id, values)
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	if(!can('Actualizar Informe de inspección - INSTALACIÓN') || !can('Actualizar Informe de inspección - PRUEBAS'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar informes de inspección" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Decisión Comité Técnico</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Form.Group as={Col}>
								<Form.Label>Consecutivo Comité <span className='text-danger'>*</span></Form.Label>
								<InputGroup>
									<Form.Control
										as="select"
										{...register('committee_sku_prefix', { required: true })}
									>
										<option>CTN</option>
										<option>CTO</option>
									</Form.Control>
									<InputGroup.Text>-</InputGroup.Text>
									<Form.Control 
										type="number"
										{...register('committee_sku', { required: true })}
									/>
								</InputGroup>
								{ errors.committee_sku && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Fecha de Comité <span className='text-danger'>*</span></Form.Label>
								<Form.Control 
									{...register('committee_at', { required: true })}
									defaultValue={serviceReport && serviceReport.committee_at}
									type="date"
								/>
								{ errors.committee_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
						</Row>
						<Form.Group>
							<Form.Label>Resultado Final <span className='text-danger'>*</span></Form.Label>
							<Form.Control
								as="select"
								{...register('is_committee_compliant', { required: serviceReport.is_committee_compliant !== null ? false : true })}
								defaultValue={serviceReport && serviceReport.is_committee_compliant ? '1' : '0'}
								disabled={serviceReport && serviceReport.is_committee_compliant !== null}
							>
								<option value="">:: Seleccione una opción ::</option>
								<option value="1">Conforme</option>
								<option value="0">No Conforme</option>
							</Form.Control>
							{ errors.is_committee_compliant && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>Observaciones Adicionales <small>(opcional)</small></Form.Label>
							<Form.Control 
								as="textarea"
								{...register('committee_observations')}
								defaultValue={(serviceReport && serviceReport.committee_observations) ? serviceReport.committee_observations : 'Los registros documentales y fotográficos corresponden con lo solicitado en la lista de verificación para evaluaciones de este tipo de instalación y se puede determinar la conformidad con la Resolución 40405 de 2020 modificada por la Resolución 40198 de 2021'}
								placeholder='Escriba aquí...'
								style={{ height: '70px' }}
							/>
						</Form.Group>
						<Alert type="warning" className='mt-20 mb-20' description="Una vez register su decisión únicamente podrá modificar las observaciones." />
						<Button color="primary" type="submit">Actualizar Decisión del Comité</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}