import React, { useState, useEffect } from 'react'

import PlanTasksTable from './partials/PlanTasksTable'
import NewPlanTaskModal from './partials/NewPlanTaskModal'

import { getPlanTasks } from './services'
import { can, handleError } from '../../helpers'

export default function ServicePlanTasksList({ service, servicePlanId }) {
	const [planTasks, setPlanTasks] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!planTasks && can('Ver listado de actividades de plan de inspección') && getPlanTasks({ 
			'filter[service_plan_id]': servicePlanId,
			sort: 'day,time'
		})
			.then(res => setPlanTasks(res.data.data))
			.catch(error => handleError(error))
	}, [ planTasks, servicePlanId ])

	return (
		<>
			<PlanTasksTable
				planTasks={planTasks}
				reload={() => setPlanTasks(null)}
				editable={service.is_editable}
			/> 
			{ service.is_editable && (
				<div className='text-right'>
					<p className='text-link mb-10' onClick={() => setShowNewModal(true)}>
						Agregar Actividad por Evaluar
					</p>
				</div>
			)}
			{ showNewModal && (
				<NewPlanTaskModal 
					visible
					onClose={() => setShowNewModal(false)}
					servicePlanId={servicePlanId}
					reload={() => setPlanTasks(null)}
				/>
			)}
		</>
	)
}