import dewarApi from '../../services/dewarApi'

export const getTripExpenses = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/trip_expenses', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getTripExpense = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/trip_expenses/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeTripExpense = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/trip_expenses', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateTripExpense = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/trip_expenses/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteTripExpense = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/trip_expenses/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const notifyTripExpense = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post(`/trip_expenses/${id}/notify`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)