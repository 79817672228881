import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Alert, Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import NewServiceReviewModal from '../../ServiceReviews/partials/modals/NewServiceReviewModal'
import NewVerificationListModal from '../../VerificationLists/partials/NewVerificationListModal'
import NewNonCompliantReportModal from '../../NonCompliantReports/partials/NewNonCompliantReportModal'

import { syncService } from '../../Services/actions'
import { syncTaskTemplates } from '../../TaskTemplates/actions'

import { getForSyncService } from '../../Services/services'
import { getTaskTemplates } from '../../TaskTemplates/services'
import { handleError } from '../../../helpers'

export default function EnableInspectionOfflineModal({ visible, onClose, serviceId }) {
	const dispatch = useDispatch()
	const [service, setService] = useState(null)
	const [loading, setLoading] = useState(false)
	const [showNewReviewModal, setShowNewReviewModal] = useState(false)
	const [showNewListModal, setShowNewListModal] = useState(false)
	const [showNewNCReportModal, setShowNewNCReportModal] = useState(false)
	const [hasSyncTaskTemplates, setHasSyncTaskTemplates] = useState(false)
	const { data: taskTemplates, sync_at: taskTemplatesSyncAt } = useSelector(state => state.task_templates)

	useEffect(() => {
		!service && getForSyncService(serviceId)
			.then(res => setService(res.data.data))
			.catch(error => handleError(error))
	}, [ service, serviceId ])

	if(!service) return null
	
	const servicePlan = service.service_inspector ? service.service_inspector.service_plan : null
	const serviceReview = servicePlan ? servicePlan.service_review : null

	const handleServiceSync = () => {
		setLoading(true)
		getForSyncService(serviceId)
			.then(res => dispatch(syncService(res.data.data)))
			.then(() => message.success('Servicio habilitado para uso Offline'))
			.then(() => onClose())
			.catch(error => handleError(error))
	}

	const canSync = () => {
		if(loading) return false
		if(!serviceReview) return false
		if(!service.verification_list) return false
		if(!serviceReview.non_compliant_report) return false
		if(taskTemplates.length === 0) return false
		if(!hasSyncTaskTemplates) return false

		return true
	}

	const handleSyncTaskTemplates = () => {
		setLoading(true)
		getTaskTemplates({ sort: 'sku' })
			.then(res => dispatch(syncTaskTemplates(res.data.data)))
			.then(() => setHasSyncTaskTemplates(true))
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
	}

	return (
		<>
			<Modal isOpen={visible} size="lg">
				<ModalHeader toggle={onClose}>Habilitar Servicio de Inspección #{serviceId} Offline</ModalHeader>
				<ModalBody>
					<p className='my-5'>
						<span className='bold mr-5'>1. El servicio cuenta con una acta de inspección creada</span>
						<Badge color={serviceReview ? 'success' : 'warning'}>
							{ serviceReview ? 'OK' : 'Pendiente' }
						</Badge>
					</p>
					{ servicePlan && !serviceReview && (
						<Button color="primary" onClick={() => setShowNewReviewModal(true)} outline size="sm">
							Iniciar Acta de Inspección
						</Button>
					)}
					{ !servicePlan && <Alert color='warning'>Debe ingresar al servicio y crear el plan de inspección. <Link to={`/services/${service.id}`}>Ingresar al Servicio</Link></Alert> }
					<p className='mt-15 mb-5'>
						<span className='bold mr-5'>2. El servicio cuenta con una lista de verificación</span>
						<Badge color={service.verification_list ? 'success' : 'warning'}>
							{ service.verification_list ? 'OK' : 'Pendiente' }
						</Badge>
					</p>
					{ serviceReview && !service.verification_list && (
						<Button color="primary" onClick={() => setShowNewListModal(true)} outline size="sm">
							Generar Lista de Verificación
						</Button>
					)}
					<p className='mt-15 mb-5'>
						<span className='bold mr-5'>3. El servicio cuenta con un reporte de no conformidades</span>
						<Badge color={(serviceReview && serviceReview.non_compliant_report) ? 'success' : 'warning'}>
							{ (serviceReview && serviceReview.non_compliant_report) ? 'OK' : 'Pendiente' }
						</Badge>
					</p>
					{serviceReview && !serviceReview.non_compliant_report && (
						<Button color="primary" onClick={() => setShowNewNCReportModal(true)} outline size="sm">
							Generar Reporte de No Conformidades
						</Button>
					)}
					<p className='mt-15 mb-5'>
						<span className='bold mr-5'>4. Lista maestra de verificación sincronizada</span>
						<Badge color={(taskTemplates.length > 0 && hasSyncTaskTemplates) ? 'success' : 'warning'}>
							{ (taskTemplates.length > 0 && hasSyncTaskTemplates) ? 'OK' : 'Pendiente' }
						</Badge>
					</p>
					<Button color="primary" onClick={handleSyncTaskTemplates} outline size="sm" disabled={loading}>
						Sincronizar Lista Maestra
					</Button>
					<p className='hint-text mb-0'>Última el: {taskTemplatesSyncAt ? taskTemplatesSyncAt : <em>Sin sincronizar</em>}</p>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={handleServiceSync} disabled={!canSync()}>
						Habilitar Servicio Offline
					</Button>
				</ModalFooter>
			</Modal>
			{ showNewReviewModal && (
				<NewServiceReviewModal
					visible
					onClose={() => setShowNewReviewModal(false)}
					servicePlanId={service.service_inspector.service_plan.id}
					reload={() => {
						setShowNewReviewModal(false)
						setService(null)
					}}
				/>
			)}
			{ showNewListModal && (
				<NewVerificationListModal
					visible
					onClose={() => setShowNewListModal(false)}
					serviceId={service.id}
					reload={() => {
						setShowNewListModal(false)
						setService(null)
					}}
				/>
			)}
			{ showNewNCReportModal && (
				<NewNonCompliantReportModal
					visible
					onClose={() => setShowNewNCReportModal(false)}
					serviceReviewId={service.service_inspector.service_plan.service_review.id}
					reload={() => {
						setShowNewNCReportModal(false)
						setService(null)
					}}
				/>
			)}
		</>
	)
}