import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { message, Popconfirm, Tooltip } from 'antd'

import { offlineDeleteTask } from '../../Services/actions'
import { unqueueRequest } from '../actions'

export default function DeleteOfflineTaskAction({ task, serviceId, reloadTasks }) {
	const { queue } = useSelector(state => state.offline)
	const dispatch = useDispatch()

	const handleDelete = () => {
		const requests = queue.filter(req => (
			req.method === 'POST' && req.url === '/verification_tasks' 
			&& req.data.service_review_id === task.service_review_id
			&& req.data.verification_list_id === task.verification_list_id
			&& req.data.task_template_id === task.task_template_id
		))

		dispatch(offlineDeleteTask(task, serviceId))

		for (let i = 0; i < requests.length; i++) {
			const request = requests[i];
			dispatch(unqueueRequest(request.key))
		}

		reloadTasks()
		message.success('Tarea eliminada exitosamente')
	}

	return (
		<>
			<Tooltip title="Quitar Tarea">
				<Popconfirm
					title="Se quitara la solicitud de creación de esta tarea"
					okText="Confirmar"
					cancelText="Cancelar"
					onConfirm={handleDelete}
					okButtonProps={{ danger:true }}
				>
					<FontAwesomeIcon 
						icon={faTrashAlt}
						className='text-link'
					/>
				</Popconfirm>
			</Tooltip>
		</>
	)
}