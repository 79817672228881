import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import ServiceProfile from './ServiceProfile'
import ServicesList from './ServicesList'

export default function Services() {
	return (
		<>
			<Switch>
				<Route exact path="/services/inspeccion" render={props => <ServicesList {...props} businessScope='inspeccion' />} />
				<Route exact path="/services/pruebas" render={props => <ServicesList {...props} businessScope='pruebas' />} />
				<Route exact path="/services/:id" component={ServiceProfile} />

				<Redirect to="/404" />
			</Switch>
		</>
	)
}