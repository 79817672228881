import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import TaskTemplatesFiltersModal from '../../TaskTemplates/partials/TaskTemplatesFiltersModal'
import PendingTasksTable from '../../VerificationLists/partials/PendingTasksTable'

import ListTopBar from '../../../components/ListTopBar'

export default function PendingTasksListModalOffline({ 
	visible, 
	onClose, 
	verificationList, 
	instalationTypeId, 
	serviceReviewId, 
	reload, 
	editable,
	filters,
	setFilters,
	serviceId
}) {
	const taskTemplatesData = useSelector(state => state.task_templates.data)
	const [taskTemplates, setTaskTemplates] = useState(null)
	const [showFiltersModal, setShowFiltersModal] = useState(false)

	useEffect(() => {
		const usedSKUs = verificationList.verification_tasks.map(t => t.task_template.sku)
		
		const data = taskTemplatesData.filter(template => (
			!usedSKUs.includes(template.sku) && 
			template.instalation_types.filter(type => type.id === instalationTypeId).length > 0 &&
			(filters['periods'].length === 0 || filters['periods'].includes(template.instalation_period.trim())) &&
			(!filters['filter[task_type]'] || template.task_type.includes(filters['filter[task_type]'])) &&
			(!filters['scope'] || template.related_scopes.includes(`${instalationTypeId}:Todas`) || !template.related_scopes.includes(`${instalationTypeId}:${filters['scope']}`)) &&
			(!filters['filter[superficial_limit]'] || (template.superficial_limit && template.superficial_limit.includes(filters['filter[superficial_limit]'])))
		))
		
		!taskTemplates && setTaskTemplates(data)

	}, [ taskTemplates, filters, taskTemplatesData, visible, verificationList, instalationTypeId ])

	const handleFiltersSubmit = () => {
		const usedSKUs = verificationList.verification_tasks.map(t => t.task_template.sku)
		
		const data = taskTemplatesData.filter(template => (
			!usedSKUs.includes(template.sku) && 
			template.instalation_types.filter(type => type.id === instalationTypeId).length > 0 &&
			(filters['periods'].length === 0 || filters['periods'].includes(template.instalation_period.trim())) &&
			(!filters['filter[task_type]'] || template.task_type.includes(filters['filter[task_type]'])) &&
			(!filters['scope'] || template.related_scopes.includes(`${instalationTypeId}:Todas`) || !template.related_scopes.includes(`${instalationTypeId}:${filters['scope']}`)) &&
			(!filters['filter[superficial_limit]'] || (template.superficial_limit && template.superficial_limit.includes(filters['filter[superficial_limit]'])))
		))
		
		setTaskTemplates(data)
		setShowFiltersModal(false)
	}

	return (
		<>
			<Modal isOpen={visible} size="lg">
				<ModalHeader toggle={() => { onClose(); reload() }}>
					Tareas Pendientes de Lista de Verificación
				</ModalHeader>
				<ModalBody>
					<ListTopBar
						className="simple"
						options={[]}
						ctaText='Filtros de Tareas'
						handleCtaClick={() => setShowFiltersModal(true)}
					/>
					<PendingTasksTable 
						taskTemplates={taskTemplates}
						verificationList={verificationList}
						instalationTypeId={instalationTypeId}
						serviceReviewId={serviceReviewId}
						reload={() => setTaskTemplates(null)}
						editable={editable}
						serviceId={serviceId}
					/>
				</ModalBody>
			</Modal>
			{ showFiltersModal && (
				<TaskTemplatesFiltersModal 
					visible
					onClose={() => setShowFiltersModal(false)}
					filters={filters}
					setFilters={setFilters}
					instalationTypeId={instalationTypeId}
					onSubmit={handleFiltersSubmit}
				/>
			)}
		</>
	)
}