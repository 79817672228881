import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import CompaniesList from './CompaniesList'

export default function Companies() {
	return (
		<>
			<Switch>
				<Route exact path="/companies" component={CompaniesList} />

				<Redirect to="/404" />
			</Switch>
		</>
	)
}