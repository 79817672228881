import React from 'react'
import { Descriptions } from 'antd'

import { text } from '../../../helpers'

const { Item } = Descriptions

export default function BranchDetails({ branch, listVersion = false }) {
	
	if(listVersion){
		return (
			<Descriptions column={1} size='small' className='labelBold'>
				<Item label="Sede">{branch.name}</Item>
				<Item label="Bandera">{text(branch.flag)}</Item>
				<Item label="Tipo de Instalación">{branch.instalation_type_name}</Item>
				<Item label="Emails">{`${text(branch.email,' ')} ${text(branch.email_secundary,' ')} ${text(branch.email_terciary,' ')}`}</Item>
				<Item label="Celular">{text(branch.mobile)}</Item>
				<Item label="Teléfono">{text(branch.phone)}</Item>
				<Item label="Dirección">
					{branch.address_line}, {branch.address_state} {branch.address_city}
				</Item>
			</Descriptions>
		)
	}

	return (
		<>
			<Descriptions bordered size="small" className="mt-10 mb-10">
				<Item label="Nombre de la Instalación">
					{branch && branch.name}
				</Item>
				<Item label="Dirección">
					{branch && branch.address_line}{" "}
					{branch && branch.address_state}{" - "}
					{branch && text(branch.address_city)}
				</Item>
				<Item label="Correo Electrónico(s)">
					{branch && `${branch.email ? branch.email : ''} ${branch.email_secundary ? branch.email_secundary : ''} ${branch.email_terciary ? branch.email_terciary : ''}`}
				</Item>
				<Item label="Teléfono">
					{branch && text(branch.phone)}
				</Item>
				<Item label="Celular">
					{branch && branch.mobile}
				</Item>
				<Item label="Bandera">
					{branch && text(branch.flag)}
				</Item>
				<Item label="Código SICOM">
					{branch && text(branch.sicom_code)}
				</Item>
				<Item label="Nombre del Constructor">
					{branch && text(branch.constructor_name)}
				</Item>
			</Descriptions>
		</>
	)
}