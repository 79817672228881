import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'

import DecisionNoticeDocument from './partials/DecisionNoticeDocument'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getPublicDecisionNotice } from './services'
import { handleError } from '../../helpers'

export default function DecisionNoticePreview(props) {
	const id = props.id ? props.id : props.match.params.id
	const { isConnected } = useSelector(state => state.offline)
	const [decisionNotice, setDecisionNotice] = useState(null)

	useEffect(() => {
		!decisionNotice && isConnected && getPublicDecisionNotice(id, {
			include: 'serviceVeredict.serviceReport.serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company,serviceVeredict.certificate,formatVersion',
		})
			.then(res => setDecisionNotice(res.data.data))
			.catch(error => handleError(error))
	}, [ decisionNotice, id, isConnected ])

	if(!isConnected) return <InternetRequired />

	if(!decisionNotice) return <Spinner />
	
	return (
		<>
			<div className='px-10'>
				<DecisionNoticeDocument 
					decisionNotice={decisionNotice} 
				/>
			</div>
		</>
	)
}