import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { Descriptions, Divider, Empty } from 'antd'
import moment from 'moment'

import NewRetryNoticeModal from './partials/NewRetryNoticeModal'
import EditRetryNoticeModal from './partials/EditRetryNoticeModal'
import { currency } from '../../helpers'
import SendNotificationButton from '../../components/SendNotificationButton'
import { notifyRetryNotice } from './services'

const { Item } = Descriptions

export default function RetryNoticeTab({ service, reload }) {
	const [showNewModal, setShowNewModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)

	const retryNotice = service.service_inspector.service_plan.service_review.retry_notice

	return (
		<>
			<h6 className='mt-10 mb-0 text-center'>Comunicado Inspección de Cierre</h6>
			{ retryNotice ? (
				<>
					<Descriptions bordered size='small' className='mt-20 mb-20' column={2}>
						<Item label="Version">{retryNotice.format_version.sku}</Item>
						<Item label="Fecha Vigencia">{moment(retryNotice.format_version.expire_at).format('DD-MMM-YYYY').toUpperCase()}</Item>
						<Item label="Valor Servicio Inspección">${currency(retryNotice.service_value)} COP</Item>
						<Item label="Total Gastos de Viaje">${currency(retryNotice.trip_expenses_value)} COP</Item>
						<Item label="Términos del Precio">{retryNotice.price_terms}</Item>
					</Descriptions>
					<Link to={`/retry_notices/preview/${retryNotice.id}`}>
						<Button color="primary" outline>
							Ver Comunicado de Inspección de Cierre
						</Button>
					</Link>
					<Divider type="vertical" />
					<Button color="primary" outline onClick={() => setShowEditModal(true)}>
						Editar Comunicado
					</Button>
					<Divider type="vertical" />
					<SendNotificationButton notifyService={() => notifyRetryNotice(retryNotice.id)} />
				</>
			) : (
				<Empty className='pt-50' description="No se ha emitido el comunicado de inspección de cierre aún">
					<Button color='primary' onClick={() => setShowNewModal(true)}>
						Emitir Comunicado de Cierre de Inspección
					</Button>
				</Empty>
			)}
			{ showNewModal && (
				<NewRetryNoticeModal 
					visible
					onClose={() => setShowNewModal(false)}
					serviceReview={service.service_inspector.service_plan.service_review}
					reload={reload}
				/>
			)}
			{ showEditModal && (
				<EditRetryNoticeModal 
					visible
					onClose={() => setShowEditModal(false)}
					retryNotice={retryNotice}
					reload={reload}
				/>
			)}
		</>
	)
}