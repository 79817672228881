import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import TaskTemplatesFiltersModal from '../../TaskTemplates/partials/TaskTemplatesFiltersModal'
import PendingTasksTable from './PendingTasksTable'
import ExportTasksButton from './ExportTasksButton'

import ListTopBar from '../../../components/ListTopBar'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { getTaskTemplates } from '../../TaskTemplates/services'
import { can, handleError } from '../../../helpers'

export default function PendingTasksListModal({ 
	visible, 
	onClose, 
	verificationList, 
	instalationTypeId, 
	serviceReviewId, 
	reload, 
	editable,
	filters,
	setFilters
}) {
	const [taskTemplates, setTaskTemplates] = useState(null)
	const [filterType, setFilterType] = useState('description')
	const [filterValue, setFilterValue] = useState('')
	const [showFiltersModal, setShowFiltersModal] = useState(false)

	useEffect(() => {
		!taskTemplates && getTaskTemplates({
			[`filter[${filterType}]`]: filterValue,
			'filter[only_pendding_on_list]': `${verificationList.id}:${instalationTypeId}`,
			...filters,
			'filter[instalation_period]' : filters['periods'].join(','),
			sort: 'sku'
		})
			.then(res => setTaskTemplates(res.data.data))
			.catch(error => handleError(error))
	}, [ taskTemplates, verificationList, filterType, filterValue, instalationTypeId, filters ])


	if(!can('Actualizar tareas de verificación'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar listas de verificación" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible} size="lg">
				<ModalHeader toggle={() => { onClose(); reload() }}>
					Tareas Pendientes de Lista de Verificación
				</ModalHeader>
				<ModalBody>
					<ListTopBar 
						filterType={filterType}
						setFilterType={setFilterType}
						filterValue={filterValue}
						setFilterValue={setFilterValue}
						reload={() => setTaskTemplates(null)}
						options={[
							{ label: 'Buscar por descripción', value: 'legal_description' },
							{ label: 'Buscar por item', value: 'sku' },
						]}
						className="simple"
						ctaText={`Filtros de Tareas`}
						handleCtaClick={() => setShowFiltersModal(true)}
					/>
					<PendingTasksTable 
						taskTemplates={taskTemplates}
						verificationList={verificationList}
						instalationTypeId={instalationTypeId}
						serviceReviewId={serviceReviewId}
						reload={() => setTaskTemplates(null)}
						editable={editable}
					/>
					<ExportTasksButton 
						verificationList={verificationList} 
						taskTemplates={taskTemplates} 
						scopeFilter={filters['scope']}
					/>
				</ModalBody>
			</Modal>
			{ showFiltersModal && (
				<TaskTemplatesFiltersModal 
					visible
					onClose={() => setShowFiltersModal(false)}
					filters={filters}
					setFilters={setFilters}
					instalationTypeId={instalationTypeId}
					onSubmit={() => {
						setTaskTemplates(null)
						setShowFiltersModal(false)
					}}
				/>
			)}
		</>
	)
}