import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'

import AttachmentsTable from './partials/AttachmentsTable'
import NewAttachmentModal from './partials/NewAttachmentModal'
import PermissionIsRequired from '../../components/PermissionIsRequired'

import { getAttachments } from './services'
import { handleError } from '../../helpers'

export default function AttachmentsList({ 
    scope,
    attachableType, 
    attachableId,
    maxAttachments,
    defaultLabel = '',
    canView = true,
    canDelete = true,
    canUpload = true,
    canDownload = true
}) {
    const [showNewModal, setShowNewModal] = useState(false)
    const [attachments, setAttachments] = useState(null)

    useEffect(() => {
        !attachments && canView && getAttachments({
            'filter[attachable_type]': attachableType,
            'filter[attachable_id]': attachableId,
            'filter[scope]': scope
        })
            .then(res => setAttachments(res.data.data))
            .catch(error => handleError(error))
    }, [ attachments, attachableType, attachableId, scope ])

    const handleOpenNewModal = () => {
        if(maxAttachments && attachments.length >= maxAttachments) 
            return message.warning(`Solo se permite subir ${maxAttachments} archivos`)

        setShowNewModal(true)
    }

    if(!attachments)
        return <Spinner animation='border' size="sm" />

    if(!canView)
        return <PermissionIsRequired title="No tiene acceso a ver adjuntos" />

    return (
        <>
            <AttachmentsTable 
                attachments={attachments}
                reload={() => setAttachments(null)}
                canDownload={canDownload}
                canDelete={canDelete}
            />
            {(!maxAttachments || attachments.length < maxAttachments) && canUpload && (
                <div className='mt-10 text-right'>
                    <Button color="primary" onClick={handleOpenNewModal}>
                        Subir Adjunto Nuevo
                    </Button>
                </div>
            )}
            { showNewModal && (
                <NewAttachmentModal 
                    visible
                    onClose={() => setShowNewModal(false)}
                    scope={scope}
                    attachableType={attachableType}
                    attachableId={attachableId}
                    onSuccess={() => setAttachments(null)}
                    defaultLabel={defaultLabel}
                />
            )}
        </>
    )
}