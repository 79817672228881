import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import { Switch } from 'antd'

import { getInstalationTypes } from '../../InstalationTypes/services'
import { toggleInstalationType } from '../services'
import { handleError, handleSuccess } from '../../../helpers'

export default function EvidenceInstalationTypesModal({ visible, onClose, evidenceTemplate, reload }) {
	const [instalationTypes, setInstalationTypes] = useState(null)

	useEffect(() => {
		!instalationTypes && getInstalationTypes()
			.then(res => setInstalationTypes(res.data.data))
			.catch(error => handleError(error))
	}, [ instalationTypes ])

	const handleChange = typeId => {
		toggleInstalationType(evidenceTemplate.id, typeId)
			.then(res => handleSuccess(res.data.message, () => reload(res.data.data)))
			.catch(error => handleError(error))
	}

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Tipos de Instalación Asociados</ModalHeader>
				<ModalBody>
					{ instalationTypes ? (
						<>
							{ instalationTypes.map( type => (
								<div key={type.id} className='mb-10'>
									<Switch 
										checked={ evidenceTemplate.instalation_types.filter(t => t.id === type.id).length > 0 }
										onChange={() => handleChange(type.id)}
									/>{" "}
									{type.name}
								</div>
							)) }
						</>
					) : <Spinner />}
				</ModalBody>
			</Modal>
		</>
	)
}