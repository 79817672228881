import React, { useState } from 'react'
import { Empty, Table, Tooltip, Popconfirm, Divider } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EditUserEquipmentModal from './EditUserEquipmentModal'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { deleteUserEquipment } from '../services'
import { can, handleError, handleSuccess, text } from '../../../helpers'

export default function UserEquipmentsTable({ userEquipments, reload}) {
	const [showEditModal, setShowEditModal] = useState(null)

	const columns = [
		{
			title: 'Servicio',
			dataIndex: 'business_scope',
			render: t => t === 'inspeccion' ? 'Inspección' : 'Pruebas'
		},
		{
			title: 'Item',
			dataIndex: 'item'
		},
		{
			title: 'Equipo',
			dataIndex: 'name'
		},
		{
			title: 'Marca',
			dataIndex: 'brand'
		},
		{
			title: 'Serial',
			dataIndex: 'serial'
		},
		{
			title: 'Código Interno',
			dataIndex: 'code'
		},
		{
			title: 'Fecha de calibración / verificación',
			dataIndex: 'verified_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase()
		},
		{
			title: 'Predefinido',
			dataIndex: 'is_predifined',
			render: t => t ? 'Sí' : 'No'
		},
		{
			title: 'Acciones',
			render: (t,r) => (
				<>
					<Tooltip title="Editar">
						<FontAwesomeIcon 
							className='text-link text-primary'
							icon={faEdit}
							onClick={() => setShowEditModal(r)}
						/>
					</Tooltip>
					<Divider type='vertical' />
					{ can('Eliminar Equipo de medición utilizado por el usuario') && (
						<Tooltip title="Eliminar">
							<Popconfirm
								title="Esta acción no se puede revertir"
								okText="Eliminar"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={() => handleDelete(r.id)}
							>
								<FontAwesomeIcon className='text-link text-danger' icon={faTrash} />
							</Popconfirm>
						</Tooltip>
					)}
				</>
			)
		}
	]

	const handleDelete = id => {
		deleteUserEquipment(id)
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	if(!can('Ver listado de Equipo de medición utilizado por el usuario'))
		return <PermissionIsRequired title="No tiene permisos para ver los equipos de medición" />

	const expandedRowRender = record => (
		<>
			<p><b>Observaciones:</b> {text(record.observations)}</p>
			<p><b>Fecha de entrega a inspector:</b> {record.last_delivered_at ? moment(record.last_delivered_at).format('DD-MMM-YYYY').toUpperCase() : <em className='small text-muted'>Sin registro</em>}</p>
		</>
	)

	return (
		<>
			<Table 
				dataSource={userEquipments}
				columns={columns}
				loading={!userEquipments}
				locale={{ emptyText: (
					<Empty description="No tiene equipos asociados aún" imageStyle={{ height: '70px' }} /> 
				)}}
				rowKey={r => r.id}
				size="small"
				className='mb-10'
				expandable={{ expandedRowRender }}
			/>
			{ showEditModal && (
				<EditUserEquipmentModal 
					visible
					onClose={() => setShowEditModal(null)}
					userEquipment={showEditModal}
					reload={reload}
				/>
			)}
		</>
	)
}