import React from 'react'
import { Form } from 'react-bootstrap'

export default function EvidenceTemplateForm({ register, errors, onlyEditable }) {
	return (
		<>
			<Form.Group>
				<Form.Label>No. Evidencia</Form.Label>
				<Form.Control 
					{...register('item', { required: true })}
					placeholder="Ej: 1.0"
				/>
				{ errors.item && <Form.Text className='text-warning'>Este campo es requerido</Form.Text>}
			</Form.Group>
			<Form.Group>
				<Form.Label>Tipo de Evidencia</Form.Label>
				<Form.Control
					as="select"
					{...register('type')}
				>
					<option value="document">Documento</option>
					<option value="photo">Fotográfia</option>
				</Form.Control>
			</Form.Group>
			<Form.Group>
				<Form.Label>Descripción <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					as="textarea"
					{...register('name', { required:true })}
				/>
				{ errors.name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text>}
			</Form.Group>
		</>
	)
}