import React from 'react'
import { Descriptions } from 'antd'
import moment from 'moment'

const { Item } = Descriptions

export default function CertificateDetails({ certificate }) {
    return (
        <>
            <Descriptions bordered size="small" className='mt-20' column={2}>
                <Item label="Versión">
                    {certificate.format_version.sku}
                </Item>
                <Item label="Fecha de Expedición">
                    {moment(certificate.issued_at).format('DD-MMM-YYYY').toUpperCase()}
                </Item>
                <Item label="Razón Social" span={2}>
                    {certificate.company_name}
                </Item>
                <Item label="NIT">
                    {certificate.company_document}
                </Item>
                <Item label="Código SICOM">
                    {certificate.company_sicom}
                </Item>
                <Item label="Observaciones" span={2}>
                    {certificate.observations ? certificate.observations : <em>Sin observaciones</em>}
                </Item>
                <Item label="Adjunto" span={2}>
                    {certificate.attachment_url ? <a href={certificate.attachment_url} target="_blank" rel="noreferrer">{certificate.attachment_url}</a> : <em>Sin adjunto</em>}
                </Item>
                <Item label="Fecha de Publicación" span={2}>
                    {certificate.published_at ? moment(certificate.published_at).format('DD-MMM-YYYY').toUpperCase() : <em>Sin publicar aún</em>}
                </Item>
            </Descriptions>
        </>
    )
}