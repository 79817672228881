import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Form, Spinner } from 'react-bootstrap'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { Result } from 'antd'

import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { getTicketTypes } from '../../../TicketTypes/services'
import { storeTicket } from '../../../Tickets/services'
import { can, handleError, handleSuccess } from '../../../../helpers'

export default function NewInspectorRequestModal({ visible, onClose, service }) {
	const currentUser = useSelector(state => state.auth.user)
	const [ticketTypes, setTicketTypes] = useState(null)
	const [loading, setLoading] = useState(false)
	const [successModal, setSuccessModal] = useState(false)
	const { handleSubmit, register, formState: { errors } } = useForm({
		defaultValues: {
			user_id: currentUser.id,
			notify_email: currentUser.email,
			company_id: service.proposal.inquiry.branch_spec.branch.company.id,
			service_id: service.id,
		}
	})

	useEffect(() => {
		!ticketTypes && can('tickets.create') && getTicketTypes({
			'filter[category_slug]': 'inspector_request'
		})
			.then(res => setTicketTypes(res.data.data))
			.catch(error => handleError(error))
	})

	const onSubmit = values => {
		setLoading(true)
		storeTicket(values)
			.then(() => handleSuccess('Solicitud enviada exitosamente', () => setSuccessModal(true)))
			.catch(error => handleError(error, setLoading))
	}

	if(!can('tickets.create'))
		return <PermissionIsRequired type="modal" title="Requiere permiso para crear tickets" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Solicitar Cierre a Programación Dewar</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
							<Form.Label>Tipo de Solicitud <span className='text-danger'>*</span></Form.Label>
							<Form.Control
								as="select"
								{...register('ticket_type_id', { required: true })}
							>
								<option value="">:: Seleccione una opción ::</option>
								{ ticketTypes && (
									<>
										{ ticketTypes.map(type => (
											<option key={type.id} value={type.id}>
												{ type.name }
											</option>
										)) }
									</>
								)}
							</Form.Control>
							{ errors.ticket_type_id && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>Correo Electrónico para recibir respuesta <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								{...register('notify_email', { required:true })}
								placeholder='Escriba un correo'
								disabled
							/>
							{ errors.notify_email && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>Fecha Sugerida para Cierre <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								type="date"
								{...register('observations', { required: true })}
							/>
						</Form.Group>
						<Button color="primary" type="submit" disabled={loading}>
							Solicitar Cierre a Programación Dewar{" "}
							{ loading && <Spinner animation='border' size="sm" className='ml-5' /> }
						</Button>
					</Form>
				</ModalBody>
			</Modal>
			{ successModal && (
				<Modal isOpen>
					<ModalBody>
						<Result 
							status="success"
							title="Se ha enviado su solicitud"
							subTitle="Solicitud enviada a programacion@dewar.com.co"
							extra={[
								<Button key="success" color='primary' onClick={onClose}>
									Entendido
								</Button>
							]}
						/>
					</ModalBody>
				</Modal>
			)}
		</>
	)
}