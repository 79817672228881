import React from 'react'
import styled from 'styled-components'

export default function ListDocumentTask({ task }) {
	return (
		<>
			<Table className='table table-sm'>
				<thead>
					<tr>
						<th width="50px">ÍTEM</th>
						<th width="200px">Decreto/Art.</th>
						<th>REQUISITO</th>
						<th width="50px">NA</th>
						<th width="50px">C</th>
						<th width="50px">NC</th>
						<th width="50px">NCP</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td rowSpan={4}>{task.task_template.sku}</td>
						<td>{task.task_template.article}</td>
						<td>{task.task_template.legal_description}</td>
						<td>{task.status.includes('NA -') ? 'X' : ''}</td>
						<td>{task.status.includes('C - C') ? 'X' : ''}</td>
						<td>{task.status.includes('NC -') ? 'X' : ''}</td>
						<td>{task.status.includes('NCP -') ? 'X' : ''}</td>
					</tr>
					<tr>
						<td className='bold'>Caracteristica Lista Verificación MME</td>
						<td colSpan={5}>{task.task_template.minminas_criteria}</td>
					</tr>
					<tr>
						<td className='bold'>Evidencia:</td>
						<td>{task.task_template.general_criteria}</td>
						<td colSpan={4}>
							{ task.evidence_count > 0 ? `${task.evidence_count} adjuntos (${task.evidence_items})` : <em>Sin evidencia</em> }
						</td>
					</tr>
					<tr>
						<td className='bold'>Observaciones Inspector:</td>
						<td colSpan={5}>{task.observations_retry ? task.observations_retry : task.observations}</td>
					</tr>
				</tbody>
			</Table>
		</>
	)
}

const Table = styled.table`
	border: 1px solid #333;
	font-size: 13px;
	thead {
		background-color: #e8e8e8;
	}
	td {
		border: 1px solid #e8e8e8;
	}
`