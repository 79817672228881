import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from 'antd'

import CommentsDrawer from './CommentsDrawer'

export default function CommentsButton({ type, id }) {
    const [showCommentsDrawer, setShowCommentsDrawer] = useState(false)

    return (
        <>
            <Tooltip title='Comentarios'>
                <Button 
                    color='primary' 
                    outline 
                    className='ml-10' 
                    onClick={() => setShowCommentsDrawer(true)}
                >
                    <FontAwesomeIcon icon={faComments} />
                </Button>
            </Tooltip>
            { showCommentsDrawer && (
                <CommentsDrawer
                    visible
                    onClose={() => setShowCommentsDrawer(false)}
                    type={type}
                    id={id}
                />
            )}
        </>
    )
}
