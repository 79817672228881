import React from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

export function BottomBar({ 
	handleNextStep, 
	handleBackStep, 
	nextBtnText = 'Continuar', 
	backBtnText = 'Paso anterior'
}) {
	return (
		<>
			<div className='mt-15'>
				<div className='float-right'>
					<Button color='primary' onClick={handleNextStep} disabled={handleNextStep ? false : true}>
						{ nextBtnText }{" "}
						<FontAwesomeIcon icon={faArrowRight} size='sm' />
					</Button>
				</div>
				{ handleBackStep && (
					<Button color='primary' outline onClick={handleBackStep}>
						<FontAwesomeIcon icon={faArrowLeft} size="sm" />{" "}
						{ backBtnText }
					</Button>
				)}
			</div>
		</>
	)
}

export const SectionTitle = styled.div`
	font-weight: 400;
	font-size: 16px;
	background-color: #e8e8e8;
	padding: 5px 10px 0px;
`

export const SectionContent = styled.div`
	font-size: 14px;
	margin-bottom: 10px;
	padding: 5px 10px;
	border: solid 2px #e8e8e8;
`