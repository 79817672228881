import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form, Spinner } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import ServiceNotificationForm from './ServiceNotificationForm'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storeServiceNotification } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function NewServiceNotificationModal({ visible, onClose, service, reload }) {
	const user = useSelector(state => state.auth.user)
	const [loading, setLoading] = useState(false)
	const { register, handleSubmit, formState: { errors }, setValue } = useForm({
		sign_by: user.name
	})

	const onSubmit = values => {
		setLoading(true)
		storeServiceNotification({ ...values, service_inspector_id: service.service_inspector.id, })
			.then(res => handleSuccess(res.data.message, reload, onClose))
			.catch(error => handleError(error, setLoading))
	}

	if(!can('Crear notificaciones de servicios'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear notificaciones" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Notificar Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ServiceNotificationForm 
							register={register} 
							errors={errors}
							setValue={setValue}
							businessScope={service.business_scope}
						/>
						<Button color="primary" type='submit' className='mt-10' disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Notificar Inspección
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}