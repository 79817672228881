import React from 'react'
import styled from 'styled-components'
import { Empty } from 'antd'

import ProposalDocument from './ProposalDocument'

export default function ProposalFormPreview({ form, inquiry, isRecertification }) {

	const data = {
		...form,
		employee_name: inquiry.employee.name,
		employee_position: inquiry.employee.position,
		branch_name: inquiry.branch_spec.branch.name,
		branch_address: inquiry.branch_spec.branch.address_line,
		branch_state: inquiry.branch_spec.branch.address_state,
		branch_municipio: inquiry.branch_spec.branch.address_city,
		branch_type_name: inquiry.branch_spec.branch.instalation_type.name,
		branch_type_criteria: inquiry.branch_spec.branch.instalation_type.criteria,
		total: (parseInt(form.service_value)*parseInt(form.quantity)) + parseInt(form.trip_expenses_value),
		inquiry
	}

	if(!form.format_version_id) 
		return <Empty className='my-20' description="Para visualizar debe seleccionar primero una versión del formato" />

	return (
		<>
			<Container>
				<ProposalDocument 
					data={data} 
					isRecertification={isRecertification} 
				/>
			</Container>
		</>
	)
}

const Container = styled.div`
	max-height: 85vh;
	overflow-y: scroll;
	background-color: #f8f8f8;
	padding: 10px;
	overflow: -moz-scrollbars-vertical; 
`