import React, { useState } from 'react'
import { Empty, message, Table, Tooltip, Popconfirm } from 'antd'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlink } from '@fortawesome/free-solid-svg-icons'

import ServerSideSelect from '../../../components/ServerSideSelect'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { searchEvidenceTemplates } from '../../EvidenceTemplates/services'
import { toggleEvidenceTemplate } from '../services'
import { can, handleError } from '../../../helpers'

export default function TaskEvidenceTemplatesModal({ visible, onClose, taskTemplate, reload }) {
	const [evidenceTemplateSelected, setEvidenceTemplateSelected] = useState([])

	const handleChange = (evidenceTemplateId) => {
		toggleEvidenceTemplate(taskTemplate.id, evidenceTemplateId)
			.then(res => {
				message.success(res.data.message)
				reload(res.data.data)
				setEvidenceTemplateSelected([])
			})
			.catch(error => handleError(error))
	}

	if(!can('is_super_admin'))
		return <PermissionIsRequired type="modal" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Evidencia relacionada</ModalHeader>
				<ModalBody>
					<Table 
						dataSource={taskTemplate.evidence_templates}
						rowKey={r => r.id}
						size="small"
						locale={{ emptyText: <Empty description="No tiene evidencia relacionada" imageStyle={{ height: '60px' }} /> }}
						columns={[
							{
								title: 'Tipo',
								dataIndex: 'type_name',
							},
							{
								title: 'Descripción',
								dataIndex: 'name'
							},
							{
								title: 'Acciones',
								render: (t, r) => (
									<>
										<Tooltip title="Desasociar">
											<Popconfirm 
												title="¿Desea desasociar la evidencia?"
												okText="Desasociar"
												cancelText="Cancelar"
												onConfirm={() => handleChange(r.id)}
												okButtonProps={{ danger:true }}
											>
												<FontAwesomeIcon className="text-link" icon={faUnlink} />
											</Popconfirm>
										</Tooltip>
									</>
								)
							}
						]}
					/>
					<ServerSideSelect 
						value={evidenceTemplateSelected}
						placeholder="Seleccione una evidencia por número"
						fetchOptions={searchEvidenceTemplates}
						onChange={value => setEvidenceTemplateSelected(value)}
						className="full-width mt-20 mb-10"
						scopeId={taskTemplate.id}
					/>
					<Button color="primary" onClick={() => handleChange(evidenceTemplateSelected.value)}>
						Agregar Evidencia
					</Button>
				</ModalBody>
			</Modal>
		</>
	)
}