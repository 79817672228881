import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Row, Col, Spinner } from 'react-bootstrap'

import FormatVersionSelect from '../../FormatVersions/FormatVersionSelect'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storeRetryNotice } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function NewRetryNoticeModal({ visible, onClose, serviceReview, noCompliant, reload }) {
	const { register, handleSubmit, formState: { errors }, setValue } = useForm()
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		storeRetryNotice({ service_review_id: serviceReview.id, ...values })
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error, setLoading))
	}

	if(!can('Crear Comunicado Inspección de Cierre'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear comunicados de inspección de cierre" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Emitir Comunicado de Inspección de Cierre</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Form.Group as={Col}>
								<Form.Label>Versión <span className='text-danger'>*</span></Form.Label>
								<FormatVersionSelect 
									keyword="retryNotice"
									setValue={setValue}
								/>
								{ errors.format_version_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Fecha Vigencia <span className='text-danger'>*</span></Form.Label>
								<Form.Control {...register('expire_at')} disabled />
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col}>
								<Form.Label>Fecha Enviado <span className='text-danger'>*</span></Form.Label>
								<Form.Control 
									{...register('sent_at', { required: true })}
									type="date"
								/>
								{ errors.sent_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Es Solitud del Cliente <span className='text-danger'>*</span></Form.Label>
								<Form.Control
									as="select"
									{...register('is_warning')}
								>
									<option value="">:: Seleccione una opción ::</option>
									<option value="0">Sí, cliente solicita</option>
									<option value="1">No, Dewar envía</option>
								</Form.Control>
								{ errors.is_warning && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col}>
								<Form.Label>Valor Servicio Inspección <small>(opcional / si aplica)</small></Form.Label>
								<Form.Control 
									{...register('service_value')}
									type="number"
								/>
								{ errors.service_value && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Total Gastos de Viaje <small>(opcional / si aplica)</small></Form.Label>
								<Form.Control 
									{...register('trip_expenses_value')}
									type="number"
								/>
							</Form.Group>
						</Row>
						<Form.Group>
							<Form.Label>Términos del Precio <small>(opcional / si aplica)</small></Form.Label>
							<Form.Control 
								as="textarea"
								{...register('price_terms')}
								style={{ height: '70px' }}
								placeholder='Escriba aquí...'
								defaultValue={`El precio de gastos de viaje puede variar, en el caso que por conveniencia del cliente asume directamente el costo de tiquetes aéreos. 
								En caso de que el cliente asuma el costo de tiquetes se realizará la variación en el total del servicio en la correspondiente cuenta de cobro/factura.
								Los costos del tiquete están sujetos a variaciones de acuerdo con la programación que se realice.
								El precio total por cancelar es el Total del servicio de inspección más IVA`}
							/>
						</Form.Group>
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Emitir Comunicado
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}