import React, { useState } from 'react'
import { Divider, Table, Tag, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faLockOpen, faToolbox, faUserLock, faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import UserEquipmentsModal from '../../UserEquipments/UserEquipmentsModal'
import PermissionIsRequired from '../../../components/PermissionIsRequired'
import UserPasswordModal from './UserPasswordModal'
import EditUserModal from './EditUserModal'
import EditUserRolesModal from './EditUserRolesModal'

import { updateUser } from '../services'
import { can, handleError, handleSuccess, text } from '../../../helpers'
import AttachmentTableColumn from '../../Attachments/AttachmentTableColumn'

export default function UsersTable({ users, reload }) {
	const [editUser, setEditUser] = useState(null)
	const [userEquipments, setUserEquipments] = useState(null)
	const [userPasswordModal, setUserPasswordModal] = useState(null)
	const [userRoles, setUserRoles] = useState(null)

	const handleToggle = user => {
		updateUser(user.id, { 
			disabled_at: user.disabled_at ? null : moment().format('YYYY-MM-DD HH:mm:ss') 
		})
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Nombre Completo',
			dataIndex: 'name'
		},
		{
			title: 'Documento',
			dataIndex: 'document'
		},
		{
			title: 'Celular',
			dataIndex: 'mobile',
			render: t => text(t, <em className='small text-muted'>Sin registro</em>)
		},
		{
			title: 'Roles',
			dataIndex: 'roles',
			render: t => t.length > 0 ? t.join(', ') : <em className='small text-muted'>Sin roles</em>
		},
		{
			title: 'Estado',
			dataIndex: 'disabled_at',
			render: t => t ? <Tag color="red">Inactivo</Tag> : <Tag color="green">Activo</Tag>
		},
		{
			title: 'Acciones',
			width: '180px',
			render: (t, record) => (
				<>
					{ can('Actualizar usuarios') && (
						<Tooltip title="Editar Usuario">
							<div className='inline' onClick={()=>setEditUser(record)}>
								<FontAwesomeIcon className="text-link" icon={faEdit} />
							</div>
						</Tooltip>
					)}
					<Divider type="vertical" />
					<Tooltip title="Roles Asociados">
						<div className='inline' onClick={()=>setUserRoles(record)}>
							<FontAwesomeIcon className="text-link" icon={faUserLock} />
						</div>
					</Tooltip>
					<Divider type="vertical" />
					{ can('Cambiar contraseña') && (
						<Tooltip title="Cambiar Contraseña">
							<div className='inline' onClick={()=>setUserPasswordModal(record)}>
								<FontAwesomeIcon className="text-link" icon={faLockOpen} />
							</div>
						</Tooltip>
					)}
					<Divider type="vertical" />
					<Tooltip title="Equipo de Medición">
						<div className='inline' onClick={()=>setUserEquipments(record)}>
							<FontAwesomeIcon className="text-link" icon={faToolbox} />
						</div>
					</Tooltip>
					<Divider type="vertical" />
					{ can('Eliminar usuarios') && (
						<>
							{ !record.disabled_at ? (
								<Tooltip title="Deshabilitar Usuario">
									<div className='inline' onClick={() => handleToggle(record)}>
										<FontAwesomeIcon className="text-link" icon={faToggleOn} />
									</div>
								</Tooltip>
							) : (
								<Tooltip title="Habilitar Usuario">
									<div className='inline' onClick={() => handleToggle(record)}>
										<FontAwesomeIcon className="text-link" icon={faToggleOff} />
									</div>
								</Tooltip>
							)}
						</>
					)}
				</>
			)
		}
	]

	if(!can('Ver listado de usuarios'))
		return <PermissionIsRequired title="No tiene permiso para ver listado de usuarios" />

	const expandedRowRender = record => (
		<React.Fragment>
			<p>
				<strong>Planilla Vigente:</strong>{" "}
				<AttachmentTableColumn
					attachment={record.payroll_attachment}
					reload={reload} 
					scope="payroll"
					attachableType="user"
					attachableId={record.id}
					title={`Planilla Vigente`}
					defaultLabel={`Planilla Vigente`}
				/>
			</p>
			<p>
				<strong>Curso de Alturas Vigente:</strong>{" "}
				<AttachmentTableColumn
					attachment={record.height_course_attachment}
					reload={reload} 
					scope="height_course"
					attachableType="user"
					attachableId={record.id}
					title={`Curso de Alturas Vigente`}
					defaultLabel={`Curso de Alturas Vigente`}
				/>
			</p>
		</React.Fragment>
	)

	return (
		<>
			<Table 
				dataSource={users}
				columns={columns}
				rowKey={record => record.id}
				loading={!users}
				expandable={{ expandedRowRender }}
				size="small"
			/>
			{ editUser && (
				<EditUserModal 
					visible={true}
					onClose={() => setEditUser(null)}
					user={editUser}
					reload={reload}
				/>
			)}
			{ userEquipments && (
				<UserEquipmentsModal 
					visible
					onClose={() => setUserEquipments(null)}
					user={userEquipments}
				/>
			)}
			{ userPasswordModal && (
				<UserPasswordModal 
					visible
					onClose={() => setUserPasswordModal(null)}
					user={userPasswordModal}
				/>
			)}
			{ userRoles && (
				<EditUserRolesModal
					visible
					onClose={() => setUserRoles(null)}
					user={userRoles}
					reload={reload}
				/>
			)}
		</>
	)
}