
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo } from '@fortawesome/free-solid-svg-icons'

import InquiryTicketsTable from './InquiryTicketsTable'
import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { getTickets } from '../../../Tickets/services'
import { can, handleError } from '../../../../helpers'

export default function InquiryTicketsModal({ visible, onClose }) {
	const currentUser = useSelector(state => state.auth.user)
	const [tickets, setTickets] = useState(null)

	useEffect(() => {
		!tickets && can('tickets.viewAll') && getTickets({
			'filter[ticketType.category_slug]': 'preinquiry,recertification',
			include: 'ticketNotes,ticketType,user,company',
		})
			.then(res => setTickets(res.data.data))
			.catch(error => handleError(error))
	}, [ tickets, currentUser ])

	if(!can('tickets.viewAll'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para ver listado de tickets" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible} size='xl'>
				<ModalHeader toggle={onClose}>Pre-Solicitudes Recibidas</ModalHeader>
				<ModalBody>
					<InquiryTicketsTable 
						tickets={tickets}
						reload={() => setTickets(null)}
					/>
					<Button 
						color='primary' 
						className='mt-5'
						onClick={() => setTickets(null)}
					>
						<FontAwesomeIcon icon={faRedo} />
					</Button>
				</ModalBody>
			</Modal>
		</>
	)
}