import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { message, Spin } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons'

import { BottomBar } from '../../../../components/WizardComponents'
import ServerSideSelect from '../../../../components/ServerSideSelect'
import CompanyDetails from '../../../Companies/partials/CompanyDetails'
import NewCompanyModal from '../../../Companies/partials/NewCompanyModal'
import EditCompanyModal from '../../../Companies/partials/EditCompanyModal'

import { getCompany, searchCompanys } from '../../../Companies/services'
import { handleError } from '../../../../helpers'

export default function CompanyStep({ form, setForm, currentStep, setCurrentStep }) {
	const [company, setCompany] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)
	const [selectedCompany, setSelectedCompany] = useState( form.company_id ? { 
		value:form.company_id, label:form.company_label 
	} : [])

	useEffect(() => {
		selectedCompany.value && !company && getCompany(selectedCompany.value)	
			.then(res => setCompany(res.data.data))
			.catch(error => handleError(error))
	}, [ selectedCompany, company ])

	const handleBackStep = () => {
		setCurrentStep(currentStep-1)
	}

	const handleNextStep = () => {
		if(!selectedCompany.value) return message.warning('Debe seleccionar una empresa')
		setForm({ 
			...form, 
			company_id: selectedCompany.value, 
			company_label: selectedCompany.label,
			branch_id: null,
			employee_id: null,
		})
		setCurrentStep(currentStep+1)
	}

	return (
		<>
			<Form.Group>
				<Form.Label>Empresa que solicita el servicio</Form.Label>
				<ServerSideSelect 
					value={selectedCompany}
					placeholder="Buscar por nombre de empresa"
					fetchOptions={searchCompanys}
					onChange={value => {
						setSelectedCompany(value)
						setCompany(null)
					}}
					className="full-width mb-10"
				/>
				<Form.Text className="text-link" onClick={() => setShowNewModal(true)}>
					<FontAwesomeIcon icon={faPlus} />{" "}Agregar empresa nueva
				</Form.Text>
			</Form.Group>
			<Spin spinning={(!company && selectedCompany.value) ? true : false}>
				<CompanyDetails company={company} />
				{ company && ( 
					<Form.Text className="text-link" onClick={() => setShowEditModal(true)}>
						<FontAwesomeIcon icon={faEdit} />{" "}Actualizar datos de la empresa
					</Form.Text>
				)}
			</Spin>
			<BottomBar handleNextStep={handleNextStep} handleBackStep={handleBackStep} />
			{ showNewModal && (
				<NewCompanyModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => {
						setSelectedCompany([])
						setCompany(null)
					}}
				/>
			)}
			{ showEditModal && (
				<EditCompanyModal 
					visible
					onClose={() => setShowEditModal(false)}
					company={company}
					reload={() => setCompany(null)}
				/>
			)}
		</>
	)
} 