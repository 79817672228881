import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

export default function ReviewEquipmentForm({ register, errors }) {
	return (
		<>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Ítem <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('item', { required:true })}
						placeholder='Escriba aquí...'
						type="number"
					/>
					{ errors.item && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Equipo <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('name', { required:true })}
						placeholder='Escriba aquí...'
					/>
					{ errors.name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Marca <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('brand', { required:true })}
						placeholder='Escriba aquí...'
					/>
					{ errors.brand && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Serial <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('serial', { required:true })}
						placeholder='Escriba aquí...'
					/>
					{ errors.serial && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Código Interno <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('code', { required:true })}
					placeholder='Escriba aquí...'
				/>
				{ errors.code && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Fecha de calibración / verificación <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('verified_at', { required:true })}
					placeholder='Escriba aquí...'
					type="date"
				/>
				{ errors.verified_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</>
	)
}