import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'

import InstalationTypeForm from './InstalationTypeForm'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storeInstalationType } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function NewInstalationTypeForm({ reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const onSubmit = values => {
		storeInstalationType(values)
			.then(res => handleSuccess(res.data.message, reload, reset))
			.catch(error => handleError(error))
	}

	if(!can('Crear tipos de instalación'))
		return <PermissionIsRequired type="warning" title="No tiene permisos para crear tipos de instalaciones" />

	return (
		<>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<InstalationTypeForm 
					register={register} 
					errors={errors} 
				/>
				<Button type="submit" color="primary">Crear Tipo de Instalación</Button>
			</Form>
		</>
	)
}