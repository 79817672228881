import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap'
import { Popconfirm } from 'antd'
import moment from 'moment'

import { updateProposal } from '../../Proposals/services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function ReportProfileTopBar({ serviceReport, reload }) {
	const user = useSelector(state => state.auth.user)
	const service = serviceReport.service_review.service_plan.service_inspector.service
	const hasCertificate = serviceReport.service_veredict && serviceReport.service_veredict.certificate

	const handleToggleProposal = () => {
		updateProposal(service.proposal.id, { 
			fulfilled_at: service.proposal.fulfilled_at ? null : moment().format('YYYY-MM-DD hh:mm:ss')
		})
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	return (
		<>
			<Card>
				<CardBody>
					<div className='float-right pt-10'>
						{ can('Marcar propuesta realizada') && hasCertificate && (
							<>
								<Popconfirm
									title="¿Desea realizar de esta acción?"
									okText="Confirmar"
									cancelText="Cancelar"
									onConfirm={handleToggleProposal}
								>
									<Button color='primary' outline className='ml-10'>
										{ service.proposal.fulfilled_at ? 'Reabrir Propuesta' : 'Marcar Propuesta Realizada' }
									</Button>
								</Popconfirm>
							</>
						)}
						{ (user.is_super_admin || user.role.includes('inspector') || user.role.includes('director')) && (
							<Link to={`/services/${service.id}`}>
								<Button color='primary' outline className='ml-10'>
									Ver Servicio de Inspección
								</Button>
							</Link>
						)}
					</div>
					<CardTitle>Informe de Inspección {serviceReport.sku_formated}</CardTitle>
					<CardSubtitle>
						Propuesta {service.proposal.sku}{" | "}
						Servicio de Inspección #{service.id}
					</CardSubtitle>
				</CardBody>
			</Card>
		</>
	)
}