import React, { useState } from 'react'
import { Alert, Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Select } from 'antd'

import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storeEvidence } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

const { Option } = Select

export default function CloneFileModal({ 
	visible, onClose, reload, evidenceTemplate, evidence, evidenceTemplates, relatedService
}) {
	const [selectedTemplateId, setSelectedTemplateId] = useState(null)

	const onSubmit = () => {
		storeEvidence({ 
			...evidence, 
			verification_list_id: relatedService ? relatedService.verification_list.id : evidence.verification_list_id, 
			evidence_template_id: selectedTemplateId
		})
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	if(!can('Crear evidencias'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear evidencia" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Clonar archivo ya subido</ModalHeader>
				<ModalBody>
					{ relatedService && (
						<Alert className='p-5 mb-5' color='warning'>Esta evidencia se va a clonar al servicio #{relatedService.id}</Alert>
					)}
					<Alert className='p-5' color='info'>Al usar esta funcionalidad va a copiar la evidencia del item <strong>{evidenceTemplate.item}</strong> a otro item que seleccione a continuación:</Alert>
					<Select
						showSearch
						placeholder="Seleccione un item"
						optionFilterProp="children"
						onChange={value => setSelectedTemplateId(value)}
						value={selectedTemplateId}
						filterOption={(input, option) => option.toLowerCase().includes(input.toLowerCase())}
						style={{ width: '100%' }}
					>
						{ evidenceTemplates.map( template => (
							<Option key={template.id} value={template.id}>
								{template.item} {template.name.substring(0,50)}
							</Option>
						)) }
					</Select>
					<Button color="primary" className='mt-15' onClick={onSubmit}>
						Clonar Evidencia
					</Button>
				</ModalBody>
			</Modal>
		</>
	)
}