import React from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faClock, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Popconfirm } from 'antd'

import { deleteComment } from '../services'
import { handleSuccess, handleError } from '../../../helpers'

export default function CommentCard({ comment, reload }) {
    const user = useSelector(state => state.auth.user)
    const handleDelete = () => {
        deleteComment(comment.id)
            .then(res => handleSuccess(res.data.message, reload))
            .catch(error => handleError(error))
    }

    return (
        <Card>
            <CardBody>
                <div className='float-right'>
                    { comment.user_id === user.id && (
                        <Popconfirm
                            title='Esta acción no se puede deshacer'
                            okText='Eliminar'
                            cancelText='Cancelar'
                            okButtonProps={{ danger: true }}
                            onConfirm={handleDelete}
                        >
                            <Button color='danger' outline size='sm'>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </Popconfirm>
                    ) }
                </div>
                <div className='mb-10'>
                    {comment.body}
                </div>
                <div className='small text-muted'>
                    <div>
                        <FontAwesomeIcon icon={faUser} className='mr-5' />
                        {comment.user_name}
                    </div>
                </div>
                <div className='small text-muted'>
                    <div>
                        <FontAwesomeIcon icon={faClock} className='mr-5' />
                        {comment.created_at}
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}