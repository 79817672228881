import React from 'react'
import { Link } from 'react-router-dom'
import { Descriptions, Empty, Table } from 'antd'
import { Badge } from 'reactstrap'
import moment from 'moment'

import Pagination from '../../../components/Pagination'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { can } from '../../../helpers'

export default function EmailLogsTable({ emailLogs, reload, pagination, setPagination }) {

	const columns = [
		{
			title: 'Fecha de Envio',
			dataIndex: 'created_at',
			render: t => `${moment(t).format('DD-MMM-YYYY hh:mm A').toUpperCase()} (${moment(t).fromNow()})`
		},
		{
			title: 'Tipo de Correo',
			dataIndex: 'type'
		},
		{
			title: 'Correo Destinatario',
			dataIndex: 'to_address'
		},
		{
			title: 'Estado',
			dataIndex: 'status_name',
			render: (t,r) => <Badge color={r.status_color}>{t}</Badge>
		},
		{
			title: 'Empresa',
			dataIndex: 'service',
			render: t => t ? t.proposal.inquiry.branch_spec.branch.company.name : 'NA'
		},
		{
			title: 'Servicio',
			dataIndex: 'service',
			render: t => t ? <Link to={`/services/${t.id}`}>#{t.id}</Link> : <em>N/A</em>
		}
	]

	const expandedRowRender = (r) => (
		<>
			<Descriptions size='small' bordered column={2} className="bg-white">
				<Descriptions.Item label="Entregado">
					{r.delivered ? `${moment(r.delivered).format('DD-MMM-YYYY hh:mm A').toUpperCase()} (${moment(r.delivered).fromNow()})` : 'Sin Entregar'}
				</Descriptions.Item>
				<Descriptions.Item label="Rebotado">
					{r.bounced ? `${moment(r.bounced).format('DD-MMM-YYYY hh:mm A').toUpperCase()} (${moment(r.bounced).fromNow()})` : 'Sin Rebote'}
				</Descriptions.Item>
				<Descriptions.Item label="Abierto">
					{r.opened ? `${moment(r.opened).format('DD-MMM-YYYY hh:mm A').toUpperCase()} (${moment(r.opened).fromNow()})` : 'Sin Apertura'}
				</Descriptions.Item>
				<Descriptions.Item label="Reclamado">
					{r.complaint ? `${moment(r.complaint).format('DD-MMM-YYYY hh:mm A').toUpperCase()} (${moment(r.complaint).fromNow()})` : 'Sin Reclamo'}
				</Descriptions.Item>
			</Descriptions>
		</>
	)

	if(!can('Ver listado de registros de correo electrónico'))
		return <PermissionIsRequired title='No tiene permisos para ver listado de notificaciones' />
	
	return (
		<>
			<Table 
				dataSource={emailLogs}
				loading={!emailLogs}
				columns={columns}
				rowKey={r => r.id}
				size="small"
				pagination={false}
				className="mb-10"
				expandable={{ expandedRowRender }}
				locale={{ emptyText: <Empty description="No se encontraron registros" imageStyle={{ height: '50px' }} /> }}
			/>
			<Pagination 
				pagination={pagination}
				updatePagination={setPagination}
				reload={reload}
			/>
		</>
	)
}