import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import TicketsTable from './partials/TicketsTable'
import ListTopBar from '../../components/ListTopBar'

import { getTickets } from './services'
import { can, handleError } from '../../helpers'

export default function TicketsList() {
	const user = useSelector(state => state.auth.user)
	const [tickets, setTickets] = useState(null)
	const [filterType, setFilterType] = useState('company.name')
	const [filterValue, setFilterValue] = useState('')

	useEffect(() => {
		!tickets && can('tickets.viewAll') && getTickets({
			[`filter[${filterType}]`]: filterValue,
			include: 'ticketNotes,ticketType,user,company,service',
			'filter[service.serviceInspector.user.id]': !user.is_super_admin ? user.id : null,
			sort: '-created_at'
		})
			.then(res => setTickets(res.data.data))
			.catch(error => handleError(error))
	}, [ tickets, filterType, filterValue, user ])

	return (
		<>
			<ListTopBar
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title="Novedades Reportadas por Clientes"
				subtitle="Desde el Portal de Clientes Dewar"
				reload={() => setTickets(null)}
				options={[
					{ label: 'Buscar por nombre empresa', value: 'company.name' },
				]}
			/>
			<Card>
				<CardBody>
					<TicketsTable 
						tickets={tickets}
						reload={() => setTickets(null)}
					/>
				</CardBody>
			</Card>
		</>
	)
}