import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { message, Spin } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons'

import { BottomBar } from '../../../../components/WizardComponents'
import ServerSideSelect from '../../../../components/ServerSideSelect'

import EmployeeDetails from '../../../Employees/partials/EmployeeDetails'
import NewEmployeeModal from '../../../Employees/partials/NewEmployeeModal'
import EditEmployeeModal from '../../../Employees/partials/EditEmployeeModal'

import { getEmployee, searchEmployees } from '../../../Employees/services'
import { handleError } from '../../../../helpers'

export default function EmployeeStep({ form, setForm, currentStep, setCurrentStep }) {
	const [employee, setEmployee] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)
	const [selectedEmployee, setSelectedEmployee] = useState( form.employee_id ? {
		value: form.employee_id, label: form.employee_label
	} : [])

	useEffect(() => {
		selectedEmployee.value && !employee && getEmployee(selectedEmployee.value)	
			.then(res => setEmployee(res.data.data))
			.catch(error => handleError(error))
	}, [ selectedEmployee, employee ])

	const handleBackStep = () => {
		setCurrentStep(currentStep-1)
	}

	const handleNextStep = () => {
		if(!selectedEmployee.value) return message.warning('Debe seleccionar una persona a cargo')
		setForm({ ...form, employee_id: selectedEmployee.value, employee_label: selectedEmployee.label })
		
		setCurrentStep(currentStep+1)
	}

	return (
		<>
			<Form.Group>
				<Form.Label>PERSONA RESPONSABLE DE LA INSPECCIÓN EN LA INSTALACIÓN</Form.Label>
				<ServerSideSelect 
					value={selectedEmployee}
					placeholder="Buscar por nombre"
					fetchOptions={searchEmployees}
					onChange={value => {
						setSelectedEmployee(value)
						setEmployee(null)
					}}
					className="full-width mb-10"
					scopeId={form.company_id}
				/>
				<Form.Text className="text-link" onClick={() => setShowNewModal(true)}>
					<FontAwesomeIcon icon={faPlus} />{" "}Agregar contacto nuevo
				</Form.Text>
			</Form.Group>
			<Spin spinning={(!employee && selectedEmployee.value) ? true : false}>
				<EmployeeDetails employee={employee} />
				{ employee && ( 
					<Form.Text className="text-link" onClick={() => setShowEditModal(true)}>
						<FontAwesomeIcon icon={faEdit} />{" "}Actualizar datos del contacto
					</Form.Text>
				)}
			</Spin>
			<BottomBar handleNextStep={handleNextStep} handleBackStep={handleBackStep} />
			{ showNewModal && (
				<NewEmployeeModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => {
						setSelectedEmployee([])
						setEmployee(null)
					}}
					companyId={form.company_id}
				/>
			)}
			{ showEditModal && (
				<EditEmployeeModal 
					visible
					onClose={() => setShowEditModal(false)}
					employee={employee}
					reload={() => setEmployee(null)}
				/>
			)}
		</>
	)
} 