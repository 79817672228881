import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

export default function EmployeeForm({ employee, register, errors }) {
	return (
		<>
			<Form.Group>
				<Form.Label>Nombre Completo <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('name', {required:true})} defaultValue={employee && employee.name} />
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Cargo <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('position', {required:true})} defaultValue={employee && employee.position} />
				{ errors.position && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Correo Electrónico <small>(opcional)</small></Form.Label>
				<Form.Control {...register('email', {})} defaultValue={employee && employee.email} />
				{ errors.email && <Form.Text className="text-warning">Debe ingresar un correo válido.</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Celular</Form.Label>
					<Form.Control {...register('mobile', {})} defaultValue={employee && employee.mobile} />
					{ errors.mobile && <Form.Text className="text-warning">Ingrese únicamente digitos.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Teléfono Fijo</Form.Label>
					<Form.Control {...register('phone', {})} defaultValue={employee && employee.phone} />
					{ errors.phone && <Form.Text className="text-warning">Ingrese únicamente digitos.</Form.Text> }
				</Form.Group>
			</Row>
		</>
	)
}