import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from 'antd'

import CommentsDrawer from './CommentsDrawer'

export default function CommentsIcon({ type, id }) {
    const [showCommentsDrawer, setShowCommentsDrawer] = useState(false)

    return (
        <>
            <Tooltip title='Comentarios'>
                <span
                    className='ml-10 text-link' 
                    onClick={() => setShowCommentsDrawer(true)}
                >
                    <FontAwesomeIcon icon={faComments} />
                </span>
            </Tooltip>
            { showCommentsDrawer && (
                <CommentsDrawer
                    visible
                    onClose={() => setShowCommentsDrawer(false)}
                    type={type}
                    id={id}
                />
            )}
        </>
    )
}
